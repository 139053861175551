import React, { Component } from "react";
import { connect } from "react-redux";
import { Controlled as CodeMirror } from "react-codemirror2";
import SystemDataItemSelector from "../../../../components/SystemDataItemSelector";
import { slotIdPathToSlotNamePath } from "../../../../util";
import { IconButton } from "@fluentui/react";

require("codemirror/mode/htmlmixed/htmlmixed");

class TextBoxForm extends Component {
	state = { ...this.props.ui.modal.tile.properties };

	modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }], // custom dropdown
			["bold", "italic", "underline"],
			["link", "image"],
			//[{ 'list': 'ordered'}, { 'list': 'bullet' }],
			//[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
			[{ color: [] }, { background: [] }], // dropdown with defaults from theme
			[{ align: [] }],
			["clean"],
		],
	};
	componentDidMount() {
		const { currentData } = this.props;
	}

	handleChange = (e) => {
		const { addData } = this.props;
		this.setState({ text: e.htmlValue });
	};

	addText = (string) => {
		const { text } = this.state;
		this.setState({ text: text + string });
	};
	renderSubs() {
		var x = [];
		for (var i in this.state.substitutions) {
			const varname = `{{${i}}}`;
			x.push(
				<tr>
					<td
						onClick={() => {
							if (this.editor)
								this.editor.doc.replaceSelection(varname);
						}}
					>
						{varname}
					</td>
					<td>
						<IconButton
							onClick={() => {
								var sub = { ...this.state.substitutions };
								delete sub[i];
								this.setState({ substitutions: sub }, () => {
									this.props.addData({ ...this.state });
								});
							}}
							iconProps={{ iconName: "Trash" }}
						></IconButton>
					</td>
				</tr>
			);
		}
		return (
			<table>
				<tr>
					<th>Variable name</th>
				</tr>
				{x}
			</table>
		);
	}
	render() {
		const { text } = this.state;
		const { addData } = this.props;
		return (
			<section className="component-form">
				<SystemDataItemSelector
					label="Add a variable"
					onDataItemSelected={(val) => {
						var slotNamePath = slotIdPathToSlotNamePath(
							val.slotPath
						);
						slotNamePath.push(val.dataItem.label);
						var pathString = `{{${slotNamePath.join(".")}}}`;
						var substitutions =
							{ ...this.state.substitutions } ?? {};
						substitutions[slotNamePath.join(".")] = val;
						for (var x in substitutions) {
							delete substitutions[x].definition;
						}
						this.setState(
							{
								substitutions: { ...substitutions },
							},
							() => {
								addData({ ...this.state });
							}
						);
					}}
				></SystemDataItemSelector>
				{this.renderSubs()}
				<CodeMirror
					value={text}
					options={{
						mode: "text/html",
						theme: "material",
						lineNumbers: true,
					}}
					onBeforeChange={(editor, data, value) => {
						this.setState({ text: value }, () => {
							addData({ ...this.state });
						});
					}}
					// onCursor={(editor, pos) => {
					// 	this.editor = editor;
					// }}
					onChange={(editor, data, value) => {}}
				/>
			</section>
		);
	}
}

function mapStateToProps({ ui }) {
	return { ui };
}
export default connect(mapStateToProps)(TextBoxForm);
