import { Checkbox, Dropdown, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";

const tableOptions = [
	{ text: "DataItems", key: "dataItems" },
	{ text: "Files", key: "files" },
	{ text: "Alarms", key: "alarms" },
	{ text: "Events", key: "events" },
];

class TableForm extends Component {
	state = {
		option: "",
		search: "",
		showHeader: true,
	};

	componentDidMount() {
		const { tile } = this.props.ui.modal;
		if (tile) {
			const { option, search } = tile.properties;
			this.setState({
				option,
				search,
			});
		}
	}

	handleChange = (e) => {
		e.preventDefault();
		const { addData } = this.props;
		this.setState({ [e.target.name]: e.target.value }, () => {
			addData(this.state);
		});
	};
	handleChange2 = (newState) => {
		const { addData } = this.props;
		this.setState(Object.assign({ ...this.state }, newState), () => {
			addData(this.state);
		});
	};

	render() {
		const { option, search, showHeader } = this.state;
		return (
			<section className="component-form">
				<Dropdown
					selectedKey={option}
					options={tableOptions}
					onChange={(e, val) => {
						this.handleChange2({ option: val.key });
					}}
					label="Table type"
				></Dropdown>

				<TextField
					value={search}
					type="text"
					onChange={(e, val) => {
						this.handleChange2({ search: val });
					}}
				></TextField>

				<Checkbox
					label="Show header"
					checked={showHeader}
					onChange={(e, val) => {
						this.handleChange2({ showHeader: val });
					}}
				></Checkbox>
			</section>
		);
	}
}

function mapStateToProps({ ui }) {
	return { ui };
}

export default connect(mapStateToProps)(TableForm);
