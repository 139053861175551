import React from "react";
import {
	Separator,
	ActionButton,
	DefaultButton,
	Stack,
	Panel,
	PanelType,
	TextField,
	IIconProps,
	Spinner,
} from "@fluentui/react";
import defaultAssetImage from "./defaultAssetImage.png";
import AttributeRow from "./attributeRow";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../actions/index";

//Asset Editor panel is a popout panels that can be called from anywhere. It uses the global redux store to show and hide.
//It is initialized with the "showAssetPanel" and passed an asset Id.
//You can optionally provide an "asset" to initalize its state with
//On open, it will load the attributes for its given assetId
//There is a button to save the alias and description.
//Attributes are automatically saved as they are edited.
//It is hidden with "hideAssetPanel"
class AssetEditorPanel extends React.Component {
	state = {
		asset: this.props.assets.find(
			(el) => el.assetId == this.props.ui.assetEditPanel.assetId
		),
	};

	componentWillReceiveProps(nextProps) {
		this.setState({
			asset: nextProps.assets.find(
				(el) => el.assetId == nextProps.ui.assetEditPanel.assetId
			),
		});
	}
	saveAttribute(att) {
		fetch(`/api/assets/${att.assetId}/attributes`, {
			method: "put",
			body: JSON.stringify(att),
			headers: { "content-type": "application/json" },
		})
			.then((res) => res.text())
			.then(() => {
				this.loadAsset();
			});
	}
	deleteAttribute(attributeName) {
		fetch(
			`/api/assets/${this.props.ui.assetEditPanel.assetId}/attributes/${attributeName}`,
			{ method: "delete" }
		)
			.then((res) => res.text())
			.then(() => {
				this.loadAsset();
			});
	}
	updateAttribute(attributeName, attributeValue) {
		if (this.state.asset) {
			var x = { ...this.state.asset };
			var att = x.attributes.filter((el) => {
				return (
					el.attributeName.toLowerCase() ==
					attributeName.toLowerCase()
				);
			});
			if (x.length > 0) {
				att[0].attributeValue = attributeValue;
			} else {
				x.attributes.push({
					assetId: this.props.ui.assetEditPanel.assetId,
					attributeName,
					attributeValue,
				});
			}
			this.saveAttribute({
				assetId: this.props.ui.assetEditPanel.assetId,
				attributeName,
				attributeValue,
			});
		}
	}
	getAttributeRow(att) {
		return (
			<AttributeRow
				key={btoa(att.attributeValue + Math.random())}
				onChange={this.saveAttribute.bind(this)}
				onDelete={() => {
					this.loadAsset();
				}}
				assetId={att.assetId}
				readOnly={att.readOnly}
				attributeName={att.attributeName}
				attributeValue={att.attributeValue}
			></AttributeRow>
		);
	}
	loadAsset() {
		if (this.props.ui.assetEditPanel.show) {
			this.props
				.loadAsset(this.props.ui.assetEditPanel.assetId)
				.then((asset) => {
					this.setState({ asset }, () => {
						this.setUpImageAreas();
					});
				});
		}
	}
	getAttributeValue(name) {
		if (this.state.asset) {
			return this.state.asset.attributes?.filter((att) => {
				return att.attributeName
					?.toLowerCase()
					?.includes(name.toLowerCase());
			})[0]?.attributeValue;
		}
	}
	componentDidMount() {
		if (this.props.ui.assetEditPanel.assetId) this.loadAsset();
	}
	setUpImageAreas() {
		const img = new Image();
		img.src = this.getAttributeValue("thumbnail");
		img.onload = () => {
			const width = 150;
			const scaleFactor = width / img.width;
			const elem = document.getElementById("assetImageCanvas");
			if(elem){
				elem.width = width;
				elem.height = img.height * scaleFactor;
				const ctx = elem.getContext("2d");
				// img.width and img.height will contain the original dimensions
				ctx.drawImage(img, 0, 0, width, elem.height);
			}
			
		};

		const img2 = new Image();

		img2.src = this.getAttributeValue("marker");
		img2.onload = () => {
			const width = 30;
			const scaleFactor = width / img2.width;
			const elem = document.getElementById("assetMarkerCanvas");
			elem.width = width;
			elem.height = img2.height * scaleFactor;
			const ctx = elem.getContext("2d");
			// img.width and img.height will contain the original dimensions
			ctx.drawImage(img2, 0, 0, width, elem.height);
		};
	}
	setAssetState(newval) {
		var asset = { ...this.state.asset };
		Object.assign(asset, newval);
		this.setState({ asset });
	}
	renderPanel() {
		return (
			<Panel
				//isOpen={this.props.isOpen}
				isOpen={this.props.ui.assetEditPanel.show}
				isLightDismiss
				onOpen={() => {
					this.loadAsset();
				}}
				onDismiss={() => {
					this.props.hideAssetEditPanel();
				}}
				type={PanelType.medium}
				onRenderHeader={() => {
					return (
						<Stack horizontal>
							<h3 style={{ fontFamily: "Proxima Nova Semibold" }}>
								{this.state.asset?.assetDefinition.assetDefinitionName.toUpperCase()}
							</h3>
							<pre> </pre>
							<h3 style={{ fontFamily: "Proxima Nova Thin" }}>
								{this.state.asset?.serialNumber.toUpperCase()}
							</h3>
						</Stack>
					);
				}}
			>
				<Separator>Asset Details</Separator>
				<Stack tokens={{ childrenGap: 10 }}>
					<TextField
						label="Alias"
						defaultValue={this.state.asset?.assetAlias}
						value={this.state.asset.assetAlias}
						onChange={(e, val) => {
							this.setAssetState({ assetAlias: val });
						}}
					></TextField>
					<TextField
						label="Description"
						defaultValue={this.state.asset?.description}
						value={this.state.description}
						onChange={(e, val) => {
							this.setAssetState({ description: val });
						}}
					></TextField>
					<TextField
						label="Mark offline after:"
						type="number"
						placeholder="120000"
						suffix="milliseconds"
					></TextField>
					<p>{`(${this.state.asset.currentCoordinates?.latitude},${this.state.asset.currentCoordinates?.longitude})`}</p>
					<TextField
						label="Dashboard link"
						placeholder={`/assets/${this.state.asset.assetId}/dashboard`}
					></TextField>
					<Stack horizontal tokens={{ childrenGap: 15 }}>
						<DefaultButton
							onClick={() => {
								this.setState({ aliasApplying: true });
								fetch(
									`/api/assets/${this.props.ui.assetEditPanel.assetId}`,
									{
										headers: {
											"Content-Type": "application/json",
										},
										method: "PUT",
										body: JSON.stringify(this.state.asset),
									}
								).then(() => {
									if (this.props.assetUpdate) {
										this.props.updateAsset();
									}
									this.setState({ aliasApplying: false });
								});
							}}
							text="Update"
						></DefaultButton>
						{this.state.aliasApplying && <Spinner></Spinner>}
					</Stack>
				</Stack>

				<Separator>Asset Image</Separator>
				<div
					style={{
						objectFit: "contain",
						border: "none",
						width: "300px",
						height: "200px",
					}}
					src={
						this.getAttributeValue("thumbnail") ?? defaultAssetImage
					}
					onDragOver={(e) => {
						e.preventDefault();
					}}
					onDrop={(e) => {
						e.preventDefault();
						var items = e.dataTransfer.items;
						for (var i = 0; i < items.length; i++) {
							console.log(items[i]);
							if (items[i].type.indexOf("image") !== -1) {
								//image
								var blob = items[i].getAsFile();
								const reader = new FileReader();
								reader.readAsDataURL(blob);
								reader.onload = () => {
									console.log(reader.result);
									const img = new Image();
									img.src = reader.result;
									img.onload = () => {
										const width = 150;
										const scaleFactor = width / img.width;
										const elem =
											document.getElementById(
												"assetImageCanvas"
											);
										elem.width = width;
										elem.height = img.height * scaleFactor;
										const ctx = elem.getContext("2d");
										// img.width and img.height will contain the original dimensions
										ctx.drawImage(
											img,
											0,
											0,
											width,
											elem.height
										);
										ctx.canvas.toBlob(
											(blob) => {
												var reader2 = new FileReader();
												reader2.readAsDataURL(blob);
												reader2.onload = () => {
													console.log(
														reader2.result.length
													);
													this.updateAttribute(
														"thumbnail",
														reader2.result
													);
												};
											},
											"image/jpeg",
											0.1
										);
										reader.onerror = (error) =>
											console.log(error);
									};
									//
								};
							} else if (items[i].type == "text/plain") {
								items[i].getAsString((str) => {
									console.log(str);
									const img = new Image();
									img.src = str;
									img.setAttribute(
										"crossorigin",
										"anonymous"
									);
									img.onerror = (e) =>
										alert(
											"There was a problem grabbing that image. Try downloading it and dragging the image file from your computer."
										);
									img.onload = () => {
										const width = 200;
										const scaleFactor = width / img.width;
										const elem =
											document.getElementById(
												"assetImageCanvas"
											);
										elem.width = width;
										elem.height = img.height * scaleFactor;
										const ctx = elem.getContext("2d");
										// img.width and img.height will contain the original dimensions
										ctx.drawImage(
											img,
											0,
											0,
											width,
											elem.height
										);
										ctx.canvas.toBlob(
											(blob) => {
												var reader2 = new FileReader();
												reader2.readAsDataURL(blob);
												reader2.onload = () => {
													console.log(
														reader2.result.length
													);
													this.updateAttribute(
														"thumbnail",
														reader2.result
													);
												};
											},
											"image/jpeg",
											0.1
										);
									};
								});
							}
						}
					}}
				>
					<canvas id="assetImageCanvas"></canvas>
				</div>
				<Separator>Map Marker</Separator>
				<div
					style={{
						objectFit: "contain",
						border: "none",
						width: "100px",
						height: "100px",
					}}
					src={this.getAttributeValue("marker") ?? defaultAssetImage}
					onDragOver={(e) => {
						e.preventDefault();
					}}
					onDrop={(e) => {
						e.preventDefault();
						var items = e.dataTransfer.items;
						for (var i = 0; i < items.length; i++) {
							console.log(items[i]);
							if (items[i].type.indexOf("image") !== -1) {
								//image
								var blob = items[i].getAsFile();
								const reader = new FileReader();
								reader.readAsDataURL(blob);
								reader.onload = () => {
									console.log(reader.result);
									const img = new Image();
									img.src = reader.result;
									img.onload = () => {
										const width = 30;
										const scaleFactor = width / img.width;
										const elem =
											document.getElementById(
												"assetMarkerCanvas"
											);
										elem.width = width;
										elem.height = img.height * scaleFactor;
										const ctx = elem.getContext("2d");
										// img.width and img.height will contain the original dimensions
										ctx.drawImage(
											img,
											0,
											0,
											width,
											elem.height
										);
										ctx.canvas.toBlob(
											(blob) => {
												var reader2 = new FileReader();
												reader2.readAsDataURL(blob);
												reader2.onload = () => {
													console.log(
														reader2.result.length
													);
													this.updateAttribute(
														"marker",
														reader2.result
													);
												};
											},
											"image/png",
											0.9
										);
										reader.onerror = (error) =>
											console.log(error);
									};
									//
								};
							} else if (items[i].type == "text/plain") {
								items[i].getAsString((str) => {
									console.log(str);
									const img = new Image();
									img.src = str;
									img.setAttribute(
										"crossorigin",
										"anonymous"
									);
									img.onerror = (e) =>
										alert(
											"There was a problem grabbing that image. Try downloading it and dragging the image file from your computer."
										);
									img.onload = () => {
										const width = 200;
										const scaleFactor = width / img.width;
										const elem =
											document.getElementById(
												"assetMarkerCanvas"
											);
										elem.width = width;
										elem.height = img.height * scaleFactor;
										const ctx = elem.getContext("2d");
										// img.width and img.height will contain the original dimensions
										ctx.drawImage(
											img,
											0,
											0,
											width,
											elem.height
										);
										ctx.canvas.toBlob(
											(blob) => {
												var reader2 = new FileReader();
												reader2.readAsDataURL(blob);
												reader2.onload = () => {
													console.log(
														reader2.result.length
													);
													this.updateAttribute(
														"marker",
														reader2.result
													);
												};
											},
											"image/jpeg",
											0.1
										);
									};
								});
							}
						}
					}}
				>
					<canvas id="assetMarkerCanvas"></canvas>
				</div>
				<Separator>All Attributes</Separator>
				<table style={{ borderCollapse: "collapse" }}>
					<thead>
						<tr>
							<th style={{ textAlign: "left", width: "200px" }}>
								Attribute
							</th>
							<th style={{ textAlign: "left", width: "200px" }}>
								Value
							</th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{[...(this.state.asset.attributes ?? [])]
							?.sort()
							.map((att) => this.getAttributeRow(att))}
					</tbody>
				</table>
				<Separator>Create new attribute</Separator>
				<ActionButton
					iconProps={{ iconName: "AddToShoppingList" }}
					allowDisabledFocus
					checked={this.state.showNewAttribute}
					onClick={(e, val) => {
						var shown = this.state.showNewAttribute;
						this.setState({ showNewAttribute: !shown });
					}}
				>
					Create attribute
				</ActionButton>
				<div
					style={{
						display: this.state.showNewAttribute ? "block" : "none",
					}}
				>
					<TextField
						label="Name"
						value={this.state.newAttributeName}
						onChange={(el, val) => {
							this.setState({ newAttributeName: val });
						}}
					></TextField>
					<TextField
						label="Value"
						value={this.state.newAttributeValue}
						onChange={(el, val) => {
							this.setState({ newAttributeValue: val });
						}}
					></TextField>
					<DefaultButton
						iconProps={{ iconName: "AddToShoppingList" }}
						label="Add Attribute"
						style={{ marginTop: "10px" }}
						allowDisabledFocus
						onClick={async () => {
							fetch(
								`/api/assets/${this.props.ui.assetEditPanel.assetId}/attributes`,
								{
									method: "put",
									body: JSON.stringify({
										assetId:
											this.props.ui.assetEditPanel
												.assetId,
										attributeName:
											this.state.newAttributeName,
										attributeValue:
											this.state.newAttributeValue,
									}),
									headers: {
										"content-type": "application/json",
									},
								}
							).then(() => this.loadAsset());
						}}
					>
						Create
					</DefaultButton>
				</div>
			</Panel>
		);
	}
	render() {
		return <>{this.state.asset && this.renderPanel()}</>;
	}
}
function mapStateToProps({ ui, assets }) {
	return { ui, assets };
}
export default connect(mapStateToProps, actions)(AssetEditorPanel);
