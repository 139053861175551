// React imports
import React from "react";
import ReactDOM from "react-dom";
// Redux imports
import { Provider } from "react-redux";
import configureStore from "./configureStore";
// my imports
//import App from './components/App';
import PortalRouter from "./Router";
import * as serviceWorker from "./serviceWorker";

export const enhancedStore = configureStore();

ReactDOM.render(
	<Provider store={enhancedStore}>
		<PortalRouter></PortalRouter>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
