import {
	ComboBox,
	TextField,
	DetailsList,
	Panel,
	DefaultButton,
	Stack,
	Icon,
	DefaultEffects,
	PanelType,
	PrimaryButton,
	mergeStyles,
	mergeStyleSets,
	Separator,
} from "@fluentui/react/";
import React from "react";
import csv2json from "csvjson-csv2json";
const iconClass = mergeStyles({
	fontSize: "1rem",
	// height: 50,
	// width: 50,
	// margin: '0 25px',
});
class DataItemDefinitionsPanel extends React.Component {
	state = {};
	componentDidMount() {
		this.loadDataItemDefinitionsMaster();
		if (this.props.assetDefinition) {
			this.loadDataItemDefinitions();
		}
	}
	loadDataItemDefinitions() {
		fetch(
			`/api/assetDefinitions/${this.props.assetDefinition.assetDefinitionId}/dataitemdefinitions`
		)
			.then((data) => data.json())
			.then((data) => this.setState({ dataItemDefinitions: data }))
			.catch((err) => console.log(err));
	}

	loadDataItemDefinitionsMaster() {
		fetch(`/api/dataitemdefinitions`)
			.then((data) => data.json())
			.then((data) => this.setState({ dataItemDefinitionsMaster: data }))
			.catch((err) => console.log(err));
	}
	detectDataItemDefinitions() {
		fetch(
			`/api/assetdefinitions/${this.props.assetDefinition.assetDefinitionId}/detectdataitemdefinitions`
		)
			.then((data) => data.json())
			.then((data) => this.setState({ dataItemDefinitions: data }));
	}
	addDataItemDefinition() {
		var did = {
			modelId: this.props.assetDefinition.assetDefinitionId,
			label: this.state.newLabel,
			unit: this.state.newUnit,
			defaultValue: this.state.newDefaultValue ?? 0,
		};
		fetch(
			`/api/assetdefinitions/${this.props.assetDefinition.assetDefinitionId}/dataitemdefinitions`,
			{
				method: "post",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify(did),
			}
		).then(() => {
			this.loadDataItemDefinitions();
		});
	}
	removeDataItemDefinition(id) {
		fetch(
			`/api/assetDefinitions/${this.props.assetDefinition.assetDefinitionId}/DataItemDefinitions/${id}`,
			{
				headers: {
					"content-type": "application/json",
				},
				method: "DELETE",
			}
		).then(() => {
			this.loadDataItemDefinitions();
		});
	}

	dataItemDefinitionRow(di) {
		return (
			<tr
				key={di.dataItemId}
				style={{ boxShadow: DefaultEffects.elevation8 }}
			>
				<td>{di.label}</td>
				<td>{di.unit}</td>
				<td>{new Date(di.created).toLocaleString()}</td>
				<td>
					<Stack horizontal>
						<Icon
							className={iconClass}
							iconName="Delete"
							onClick={() => {
								this.removeDataItemDefinition(di.dataItemId);
							}}
						></Icon>
					</Stack>
				</td>
			</tr>
		);
	}
	toggleCSV() {
		try {
			this.setState({ csv: !this.state.csv });
		} catch (e) {}
	}
	checkCSV() {
		try {
			var x = csv2json(this.state.csvText);
			this.setState({ parsedDataItems: x });
		} catch (e) {
			this.setState({ parsedDataItems: [] });
		}
	}
	setFromCSV() {
		fetch(
			`/api/ef/assetdefinitions/${this.props.assetDefinition.assetDefinitionId}/dataitemdefinitions`,
			{
				method: "post",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(
					this.state.parsedDataItems.map((did) => {
						return {
							label: did.label,
							unit: did.unit,
							defaultValue: 0,
						};
					})
				),
			}
		).then(() => {
			this.loadDataItemDefinitions();
		});
	}
	deleteAllDataItems() {
		fetch(
			`/api/ef/assetdefinitions/${this.props.assetDefinition.assetDefinitionId}/dataitemdefinitions`,
			{
				method: "post",
				headers: { "Content-Type": "application/json" },
				body: "[]",
			}
		).then(() => {
			this.loadDataItemDefinitions();
		});
	}
	render() {
		return (
			<Panel
				isOpen={this.props.isOpen}
				isLightDismiss={true}
				onDismiss={this.props.onDismiss}
				onOpen={() => {
					this.loadDataItemDefinitions();
				}}
				type={PanelType.medium}
				onRenderHeader={() => {
					return (
						<Stack horizontal>
							<h3
								style={{
									fontFamily: "Proxima Nova Bold",
									fontSize: "2rem",
								}}
							>
								{this.props.assetDefinition?.assetDefinitionName.toUpperCase()}
							</h3>
							<pre> </pre>
							<h3
								style={{
									fontFamily: "Proxima Nova Thin",
									fontSize: "2rem",
								}}
							>
								DATAITEMS
							</h3>
						</Stack>
					);
				}}
			>
				<Stack tokens={{ childrenGap: 10 }}>
					{!this.state.csv && this.state.dataItemDefinitionsMaster && (
						<>
							<ComboBox
								label="Select or create a label"
								allowFreeform
								persistMenu={true}
								scrollSelectedToTop={true}
								autoComplete={"on"}
								selectedKey={this.state.selectedDataItem}
								onPendingValueChanged={(o, i, val) => {
									if (val != undefined && o == undefined) {
										this.setState({
											selectedDataItem: -1,
											newLabel: val,
										});
									}
								}}
								onChange={(e, val) => {
									if (val != undefined) {
										this.setState({
											selectedDataItem: val.key,
											newLabel: val.text,
										});
									}
								}}
								options={[
									{
										key: -1,
										text:
											this.state.newLabel ??
											"<new label>",
									},
								].concat(
									this.state.dataItemDefinitionsMaster?.map(
										(el) => {
											return {
												key: el.dataItemId,
												text: el.label,
											};
										}
									)
								)}
							></ComboBox>
							<ComboBox
								label="Choose a unit of measure"
								//allowFreeform={true}
								//autoComplete={"on"}
								//selectedKey={this.state.selectedUnit}
								// onPendingValueChanged={(o, i, val) => {
								// 	if (val != undefined && o == undefined) {
								// 		this.setState({
								// 			selectedUnit: -1,
								// 			newUnit: val,
								// 		});
								// 	}
								// }}
								// onChange={(e, val) => {
								// 	console.log(val);
								// 	if (val) {
								// 		this.setState({
								// 			selectedUnit: val.key,
								// 			newUnit: val.text,
								// 		});
								// 	}
								// }}
								options={[
									{
										key: -1,
										text:
											this.state.newUnit ?? "<new unit>",
									},
								].concat(
									Array.from(
										new Set(
											this.state.dataItemDefinitionsMaster?.map(
												(el) => el.unit
											)
										)
									).map((el) => {
										return { key: el, text: el };
									})
								)}
							></ComboBox>
						</>
					)}
					<Stack horizontal tokens={{ childrenGap: 15 }}>
						{!this.state.csv && (
							<>
								<PrimaryButton
									text="Add data item"
									onClick={() => {
										this.addDataItemDefinition();
									}}
								></PrimaryButton>
								<DefaultButton
									text="Add all detected"
									onClick={() => {
										this.detectDataItemDefinitions();
									}}
								></DefaultButton>
								<DefaultButton
									text="Remove All"
									onClick={() => {
										this.deleteAllDataItems();
									}}
								></DefaultButton>
							</>
						)}
						<DefaultButton
							text={
								this.state.csv
									? "Select data items"
									: "Paste CSV"
							}
							onClick={() => {
								this.toggleCSV();
							}}
						></DefaultButton>
					</Stack>
				</Stack>

				<Separator>Data items</Separator>
				{this.state.csv ? (
					<div>
						<Stack horizontal tokens={{ childrenGap: 15 }}>
							<PrimaryButton
								text="Add data item"
								onClick={() => {
									this.setFromCSV();
								}}
							></PrimaryButton>
							<DefaultButton
								text="Download template"
								onClick={() => {
									let csvContent =
										"data:text/csv;charset=utf-8,label,unit\n";
									var encodedUri = encodeURI(csvContent);
									var link = document.createElement("a");
									link.setAttribute("href", encodedUri);
									link.setAttribute(
										"download",
										"dataitemdefinition_template.csv"
									);
									document.body.appendChild(link); // Required for FF
									link.click();
								}}
							></DefaultButton>
						</Stack>
						<TextField
							label="Enter a CSV with the headers 'label' and 'unit'"
							value={this.state.csvText}
							onChange={(e, val) => {
								this.setState({ csvText: val }, () => {
									this.checkCSV();
								});
							}}
							multiline
						></TextField>
						<DetailsList
							items={this.state.parsedDataItems ?? []}
						></DetailsList>
					</div>
				) : (
					<div>
						<table style={{ width: "100%" }}>
							<thead>
								<tr>
									<th>Label</th>
									<th>Unit</th>
									<th>Created</th>
								</tr>
							</thead>
							<tbody>
								{this.state.dataItemDefinitions?.map((di) => {
									return this.dataItemDefinitionRow(di);
								})}
							</tbody>
						</table>
					</div>
				)}
			</Panel>
		);
	}
}
export default DataItemDefinitionsPanel;
