import { Button } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../actions/index";
import { format, getAssetIdFromSystem } from "../../../../util";
class AlarmTextBox extends Component {
	static defaultProps = {
		data: undefined, //{}
		decimals: "2",
		fontSize: "14",
	};
	state = { alarms: [] };
	substitutions = (text) => {
		const { currentData, data } = this.props;
		var alarm = this.state.alarms
			.filter((a) => {
				return this.props.properties.alarm == a.alarmDefinitionId;
			})
			.sort((a, b) => {
				return new Date(a.raised) - new Date(b.raised);
			})[0];
		if (!text) return "I am a Text Box";
		try {
			return format(text, { currentData, data, alarm });
			//return eval('`'+text+'`');
		} catch (e) {
			return text;
		}
	};
	closeButton() {
		return (
			<Button
				onClick={() => {
					fetch(
						`/api/assets/${getAssetIdFromSystem()}/alarms/${
							this.props.properties.alarm
						}/close`
					).then((res) => res.text());
					//.then(this.props.loadAssetDashboard(this.props.data.asset.assetId))
				}}
			></Button>
		);
	}
	render() {
		const { properties, fontSize } = this.props;
		var alarm = this.props.data.asset.alarms2
			.filter((a) => {
				return this.props.properties.alarm == a.alarmDefinitionId;
			})
			.sort((a, b) => {
				return new Date(a.raised) - new Date(b.raised);
			})[0];
		var status = 0;
		if (alarm != null) {
			status = alarm.alarmStatus;
		}
		var text = "";
		switch (status) {
			case 1:
				text = this.props.properties["openText"];
				break;
			case 2:
				text = this.props.properties["acknowledgedText"];
				break;
			case 3:
				text = this.props.properties["escalatedText"];
				break;
			default:
				text = this.props.properties["defaultText"];
				break;
		}
		return (
			<section
				style={{
					width: "100%",
					height: "100%",
					fontSize: `${fontSize}px`,
					position: "relative",
				}}
			>
				<div
					dangerouslySetInnerHTML={{
						__html: this.substitutions(text),
					}}
				></div>
				{this.closeButton()}
			</section>
		);
	}
}

function mapStateToProps({ data, currentData }) {
	return { data, currentData };
}

export default connect(mapStateToProps, actions)(AlarmTextBox);
