const auth = (state = { valid: null, message: "Not logged in" }, action) => {
	switch (action.type) {
		case "VALID_LOGIN": {
			state = { ...action.payload, message: "You are logged in" };
			return state;
		}
		case "INVALID_LOGIN": {
			state = { valid: false, message: action.payload };
			return state;
		}
		default:
			return state;
	}
};
export default auth;
