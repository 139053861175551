import { ActionButton, ChoiceGroup, ConstrainMode, DefaultButton, getStartDateOfWeek, getTheme, IconButton, Modal, PrimaryButton, Slider, Spinner, Stack, TextField } from "@fluentui/react";
import * as Papa from "papaparse";
import React from "react";
import { connect,useDispatch,useSelector } from "react-redux";
import {
	getAssetById,
	getAssetFromSystem,
	getAssetFromSystemByAssetId,
	getAssetIdFromSystem,
	getAttributeValueFromSystem,
	getFormattedLabel,
	getParameterByName,
	setParameterByName,
} from "../../../../../util";
import { axisHide } from "./Plugins/AxisHide";
import SteppedLine from "./Plugins/StepLinePlugin";
import { TooltipTypes } from "./Plugins/TooltipPlugin";
import UPlotComponent from "./uPlot";
import uPlot from "uplot";
import wheelZoomPlugin, { wheelScrollPlugin } from "./Plugins/Wheel";
import * as strftime from 'strftime'
import { merge2DArraysWithFirstArrayKey, sort2DarrayByFirstArray } from "./util";
import touchZoomPlugin from "./Plugins/TouchZoom";
import { LimePanel } from "../../../../../components/LimePanel";
import PopoutColorPicker from "../../../../../components/PopoutColorPicker";
import DateTimePicker from "../../forms/DateTimePicker";
import { useQuery, useQueryClient } from "react-query";
import { useHistory ,useParams} from "react-router-dom";
import Annotations from "../Annotations/Annotations";
import { HelpBubble } from "../../../containers/HelpBubble";


let maxPoints= getParameterByName("maxPoints")
const loadTime = new Date().getTime()
export default function FastGraph({properties}){

	const {currentData,
		definition,
		playback,
		ui,
		system,auth,
		assets} = useSelector((state)=>state)
		const { isLoading, error, data } = useQuery([`assets/events`,system.assetId], () =>
			fetch(`/api/events?assetId=${system.assetId}`).then(res =>
				res.json()
			),
			{
				enabled: !!system?.assetId,
			}
		)
		const queryClient = useQueryClient()
		let dispatch = useDispatch();
		let history = useHistory()
		let params = useParams()
		if(definition && assets && currentData){
				return(<FastGraphClass 
					params = {params} 
					history={history}
					queryClient={queryClient}
					events={data} 
					dispatch={dispatch}
					auth={auth}
					definition = {definition}
					ui={ui} assets={assets}
					playback={playback} 
					currentData = {currentData}
					system={system}
					properties={properties}></FastGraphClass>)
		}

	else{
		return null
	}
}
class FastGraphClass extends React.Component {
	state = {
		previousQueries:[],
		annotate:false
	};
	controller = new AbortController();
	fetchTimeout;
	
	cleanColor(color) {
		if (color?.length == 6 || color?.length == 3) {
			return "#" + color;
		} else {
			return color;
		}
	}

	componentWillReceiveProps(nextProps) {
		let currentSeries = nextProps?.properties?.series
		if (
			this.props.playback.startDate != nextProps.playback.startDate &&
			nextProps.playback.startDate != null
		) {
			
			var di = currentSeries?.map(
				(di) =>
					getAssetIdFromSystem(null, di.slotPath) +
					"-" +
					di.dataItem.dataItemId
			)
			if(nextProps.useInd){
				di.unshift(getAssetIdFromSystem(null, this.props.properties.indSlot) +
				"-" +
				this.props.properties.indDataItem.dataItemId)
			}
			var url = `/api/historicaldata?FIRSTLOAD&columnar=true&dataItems=${di}&`;
			//const lastLoad = (new Date(new Date() - (this.props.properties.fixedStart??.5)*3600*1000)).toJSON()
			const lastLoad =
				nextProps.playback.startDate ??
				new Date(
					new Date() -
						(this.props.properties.fixedStart ?? 0.5) * 3600 * 1000
				);
			clearTimeout(this.fetchTimeout);
			this.setState({ url, lastLoad ,series:currentSeries }, () => {
				this.firstDataLoad();
			});
		}
		if(this.props.properties.refreshData && this.props.properties.refreshInterval < 2){

			var x = currentSeries?.map(
				(di) => this.props?.currentData?.[getAssetIdFromSystem(null, di.slotPath)]?.[
					di.dataItem.dataItemId
				]
			)
			
			var t = new Date(x[0]?.timestamp).getTime()/1000
			
			
			if(t> new Date('2020-01-01').getTime()/1000 && this.state.data){
				var newData = [[t],...x.map(d=>[d?.value])]
				newData = this.applyTransforms(newData)
				var data = [...this.state.data]
				for(var i = 0 ; i < data.length; i++){
					for(var j = 0 ; j < newData[i].length; j++){
						data[i].push(newData[i][j])
					}
				}
				//data = sort2DarrayByFirstArray(data)
				maxPoints = parseFloat(maxPoints)
				if(maxPoints > 0 && maxPoints && !isNaN(maxPoints) && data?.[0]?.length ){
					var offset = data[0].length - maxPoints
					if(offset > 0){
						data=data.map(d=>{return d.splice(offset)})
					}
					
				}
		
				this.setState({
					data
				})
			}
		}
	}
	async loadTransforms(){
		let currentSeries = this.getSeries()
		let transforms = []
		for(var i = 0 ; i < currentSeries.length; i++){
			let s = currentSeries[i]
			let lut =undefined
			if(s.transform == "lut"){
				try{
					lut=JSON.parse(s.lut)
				}
				catch(e){
					try{
						lut = await fetch(s.lut).then(r=>r.json()).catch(()=>{})
					}
					catch(e){}
				}
				
			}
			else if(s.transform == "attLut"){
				try{
					lut = JSON.parse(getAttributeValueFromSystem(undefined,s.slotPath,s.attLut,undefined))
				}
				catch(e){
				}
				
			}
			transforms.push(lut)
		}
	
		return transforms
	}
	async componentDidMount() {
		let currentSeries = this.getSeries()
		try {
			var transforms = await this.loadTransforms()
			var palette= getTheme().palette
			let fontColor = palette.black;
			let bgColor = palette.neutralLight;
			if (this.props.properties) {

				var endTime = this.props.ui.showPlayback
					? this.props.playback.endDate
					: new Date(new Date().getTime()+1000*5*60);
				var startTime = this.props.playback.startDate ??
					new Date(
						new Date() -
							(this.props.properties.fixedStart ?? 0.5) * 3600 * 1000
					)
				var scales = {
					x: {
						dir: this.props.properties.invert ? -1 : 1,
						ori: this.props.properties.invert ? 1 : 0,
						// min:startTime.getTime()/1000 ,
						// max:endTime.getTime()/1000
						// min:this.state.zoom?.[0],
						// max:this.state.zoom?.[1]
					},
				};
				for (var i in this.props.properties.axes) {
					var axis = this.props.properties.axes[i];
					var min = parseFloat(axis.min);
					var max = parseFloat(axis.max);
					scales[axis.label] = {
						dir: this.props.properties.invert ? 1 : 1,
						ori: this.props.properties.invert ? 0 : 1,
						auto: isNaN(min) || isNaN(max),
						range:
							isNaN(min) || isNaN(max) ? undefined : [min, max],
					};
				}
				const defaultGridStroke = "#ddd2";
				const opts = {
					invert: this.props.properties.invert,
					padding: [0, 0, 10, 0],
					
					axes: [
						{
							font: "12px Proxima Nova",
							labelFont: "12px Proxima Nova",
							stroke: fontColor,
							size:this.props.properties.hideAxis ? 0:60,
							side: this.props.properties.invert ? 3 : 2,
							grid:{stroke:this.props.properties.gridStroke??defaultGridStroke,width:1}
						},
					].concat(
						this.props.properties?.axes?.map((ser, i) => {
							return {
								stroke: fontColor,
								scale: ser.label,
								font: "12px Proxima Nova",
								labelFont: "12px Proxima Nova",
								label: ser.label,
								show:!(ser.hide ?? false),
								side: this.props.properties.invert
									? i % 2 == 0
										? 2
										: 0
									: i % 2 == 0
									? 3
									: 1,
								grid: { show: ser.grid ?? false,stroke:this.props.properties.gridStroke??defaultGridStroke,width:1 },
							};
						})
					),
					legend: {
						show: this.props.properties.legend,
						font: "12px Proxima Nova",
					},
					// 	: undefined,
					cursor: {
						bind: {
							mousedown: (u, targ, handler) => {
								return e => {
									if (e.button == 0) {
										handler(e);
	
										if (this.state.annotate) {
											u._ann_start =  new Date(1000* u.posToVal(this.props.properties.invert ? e.offsetY : e.offsetX, 'x'))
											u.root.querySelector(".u-select").classList.add("u-annotate");
										}
									}
									if (e.ctrlKey && e.altKey) {
										let tag =  new Date(1000* u.posToVal( e.offsetX, 'x'))
										console.log(tag)
										if(window.confirm("Would you like to set the job START time to "+tag.toJSON())){
		
											fetch(
												`/api/assets/${getParameterByName("assetId")}/attributes`,
												{
													method: "POST",
													body: JSON.stringify(
														[{attributeName:"Job start time",attributeValue:tag.toJSON()}]
													),
												}
											).then(()=> window.location.href = ""+window.location.href)
										}
										else if(window.confirm("Would you like to set the job END time to "+tag.toJSON())){
		
											fetch(
												`/api/assets/${getParameterByName("assetId")}/attributes`,
												{
													method: "POST",
													body: JSON.stringify(
														[{attributeName:"Job end time",attributeValue:tag.toJSON()}]
													),
												}
											).then(()=> window.location.href = ""+window.location.href)
										}
									   
									 }
								}
							},
							mouseup: (u, targ, handler) => {
								return e => {
									if (e.button == 0) {
										if (this.state.annotate) {
											// tmp disable drag.setScale
											u._ann_stop =  new Date(1000* u.posToVal(this.props.properties.invert ? e.offsetY : e.offsetX, 'x'))
											
											var setScales=[]
											if(u.cursor.sync.key){
												
												var plots = uPlot.sync(u.cursor.sync.key).plots
												
												plots.forEach((p,i)=>{
													const _setScale = p.cursor.drag.setScale;
													setScales.push(_setScale)
													p.cursor.drag.setScale = false
												})
											}

											// fire original handler
											handler(e);
	
											// revert drag.setScale
											if(u.cursor.sync.key){
												var plots = uPlot.sync(u.cursor.sync.key).plots
												
												plots.forEach((p,i)=>{
													p.cursor.drag.setScale =  setScales[i];
												})
											}
										}
										else
											handler(e);
									}
								};
							}
						},
						fill: bgColor,
						drag: { x: true, y: true, uni: 50 },
						sync: this.props.properties.syncCursor ?  {
						    key: "moo",
							filters:[(type, client, x, y, w, h, i)=>{
								return true
							}],
							setSeries: false,
						    scales: ["x"]
						} : null,
					},

					plugins: [
						TooltipTypes[this.props.properties.tooltipType ?? "BlockAuto"](),
						...this.props.properties.panning ? [touchZoomPlugin({invert:this.props.properties.invert})] : [],
						...this.props.properties.panning ? [wheelScrollPlugin({invert:this.props.properties.invert,ctrlScrollLock:this.props.properties.ctrlScrollLock})] : [],
					],
					time: false,
					rotated: true,
					title: this.props.properties.title,
					scales: scales,

					hooks: {
						draw:[(u)=>{

							this.props.events?.forEach(event=>{
								if(event.eventDefinition.name == "Graph annotation"){
									var start = new Date(event.metaExpanded.start)/1000
									var end =new Date(event.metaExpanded.end)/1000
									var startPx = u.valToPos(start,"x",true)
									var endPx = u.valToPos(end,"x",true)
								
									u.ctx.save();
									if(this.props.properties.invert){
										u.ctx.beginPath();
										
										u.ctx.lineWidth = 2
										u.ctx.setLineDash([5, 5]);
										u.ctx.strokeStyle="white"
										u.ctx.fillStyle=event.metaExpanded.highlight
										u.ctx.rect(	1, startPx, 1e99 , endPx-startPx);
										u.ctx.globalAlpha=.6
										u.ctx.stroke(); // draw stroke of the shape
										
										u.ctx.fill(); 
										
								

										u.ctx.globalAlpha=1
										if(event.cause){
											u.ctx.translate(15, (endPx+startPx)/2);
											u.ctx.rotate(Math.PI/2);
											u.ctx.textAlign = "center";
											
											u.ctx.fillStyle="white"
											u.ctx.fillText(event.cause,0 ,0 );

											
										}
									}
									else{
										u.ctx.beginPath();
										u.ctx.rect(startPx, 1, endPx-startPx, u.over.clientHeight -2); 
										u.ctx.lineWidth = 2
										u.ctx.globalAlpha=0.1
										u.ctx.setLineDash([3, 3]);
										u.ctx.strokeStyle="white"
										u.ctx.fillStyle=event.metaExpanded.highlight
										u.ctx.globalAlpha=.6
										u.ctx.stroke(); // draw stroke of the shape
										
										u.ctx.fill();   // fill the shape
										u.ctx.globalAlpha=1
										
										if(event.cause){
											u.ctx.fillStyle="white"
											u.ctx.textAlign = "center";
											var px = (Math.min(endPx,u.ctx.canvas.clientWidth)+Math.max(0,startPx))/2
											u.ctx.fillText(event.cause, px, 15);
										}
									}
									u.ctx.restore();

									
								}
							})
							
						}],
						setSelect: [
							u => {
								
								if (this.state.annotate) {
									this.setState({
										createAnnotation:true,
										annotationStart:u._ann_start,
										annotationEnd: u._ann_stop
									})

									//let note = prompt("Annotation for "+u._ann_start+" to " +u._ann_stop);
								}
							}
						],
						setScale:[ (u, scale) => {
							if (scale == 'x' && !this.state.createAnnotation) {
								
								var zoom = [u.scales["x"].min,u.scales["x"].max]
								setParameterByName("startDate",zoom[0]*1000)
								setParameterByName("endDate",zoom[1]*1000)
								let { min, max } = u.scales.x;
								let xData = u.data[0];

								let isZoomedOut = Math.abs(min - xData[0] ?? 0) + Math.abs(max -xData[xData.length - 1]) < 10
								
								if (min == xData[0] && max == xData[xData.length - 1] && !this.props.properties.autoFetch) {
									this.setState({ zoom })
								}
								else{
									if(this.props.properties.autoFetch && zoom[0] && !isZoomedOut){
										this.extend(500,undefined,new Date(zoom[0]*1000),new Date(zoom[1]*1000))
										this.setState({ zoom });
									}
									else{
										this.setState({ zoom })
									}
									
								}
							}
						  },
						  
						  ]
					},
					series: [{}].concat(
						currentSeries?.map((ser) => {
							var assetId = getAssetIdFromSystem(
								null,
								ser.slotPath
							);
							var asset = getAssetById(
								this.props.assets,
								assetId
							);
							//var assetId = asset.assetId;

							ser.spanGaps = true;
							Object.assign(
								ser.dataItem,
								this.props?.currentData?.[assetId]?.[
									ser.dataItem.dataItemId
								]
							);
							var labelPrefix =
								ser.slotPath?.length > 0
									? (asset?.assetAlias ??
											asset?.serialNumber) + " "
									: "";
							return {
								stroke: this.cleanColor(ser.color),
								fill: this.cleanColor(ser.fill),
								scale: ser.axis.label,
								dash: ser.line == "dash" ? [5, 5] : undefined,
								spanGaps: true,
								smooth: ser.smooth,

								label: ser.alias || getFormattedLabel(
									this.props.definition,
									ser.dataItem.dataItemId,
									ser.slotPath,
									ser.dataItem.label
								),
								fillTo: -999999,
								// fill:(uplot,seriesIdx)=>{
								//     //return "blue"
								// },
								paths:
									ser.line == "step"
										? SteppedLine
										: undefined,
							};
						})
					),
				};
				
			
				var url = `/api/historicaldata?columnar=true&dataItems=${currentSeries?.map(
					(di) =>
						getAssetIdFromSystem(null, di.slotPath) +
						"-" +
						di.dataItem.dataItemId
				)}`;
				
				this.setState({series:this.props.properties.series, url, opts, decimation:this.props.properties.decimation,transforms,previousQueries:[[startTime.getTime()/1000,endTime.getTime()/1000]]}, () => {
					this.firstDataLoad()
				});
			}
		} catch (error) {
		}
	}
	deepCopyObject = (obj) => {
		let tempObj = {};
		for (let [key, value] of Object.entries(obj)) {
			if (Array.isArray(value)) {
				tempObj[key] = this.deepCopy(value);
			} else {
				if (typeof value === "object") {
					tempObj[key] = this.deepCopyObject(value);
				} else {
					tempObj[key] = value;
				}
			}
		}
		return tempObj;
	};
	deepCopy = (arr) => {
		let copy = [];
		arr.forEach((elem) => {
			if (Array.isArray(elem)) {
				copy.push(this.deepCopy(elem));
			} else {
				if (typeof elem === "object") {
					copy.push(this.deepCopyObject(elem));
				} else {
					copy.push(elem);
				}
			}
		});
		return copy;
	};

	componentWillUnmount() {
		clearTimeout(this.fetchTimeout);
	}
	fetchFromApi(startTime,endTime,thresh,reason){
		let isAdmin = this.props.auth?.permissions?.find(p=>p.permissionId==1) != null
		if(!isAdmin){
			endTime = new Date(
				Math.min(
					endTime,
					new Date(getAttributeValueFromSystem(null,null,"Job End Time",endTime))
				)
			)
			startTime = new Date(
				Math.max(
					startTime,
					new Date(getAttributeValueFromSystem(null,null,"Job Start Time",startTime))
				)
			)
		}
		
		
		return fetch(
			this.state.url +
				`&${reason}&threshold=${thresh}&startTime=${startTime.toJSON()}&endTime=${endTime.toJSON()}`
			
		)
			.then((res) => res.json())
			.then(data=>{
				if(this.props.properties.useInd) data.splice(0,1)
				return this.applyTransforms(data)
			})
			.catch(() => {return [[[]]]});
	}
	firstDataLoad(){
		
		//In this case, we will be starting fresh from the tile defaults
		clearTimeout(this.fetchTimeout)
		this.setState({loading:true})
		var endTime = this.props.playback.endDate ?? new Date(new Date().getTime()+1000*5*60);
		var startTime = this.props.playback.startDate ??
			new Date(
				new Date() -
					(this.props.properties.fixedStart ?? 0.5) * 3600 * 1000
			)


		var thresh = (this.state.decimation ?? 20) < 100
		? (this.state.decimation ?? 20) * 30
		: -1;
		//Fetch data, replace whole buffer, then start the timeout to load data as we go
		this.fetchFromApi(startTime,endTime,thresh,"FIRSTDATA")
			
			.then((data) => {
				this.setState({
					data,
					previousQueries:[],
					loading:false,
					lastLoad:new Date(1000*data[0][data[0].length-1])
				},()=>{
					if(this.props.properties.refreshInterval >= 2){
						this.liveDataLoad()
					}
					
				})
			})
		
	}
	getSeries(){
		//return this.state.series
		let currentSeries = [...this.props.properties?.series ?? []]
		currentSeries?.forEach(
			s=>{
			s.label = s.alias || getFormattedLabel(
				this.props.definition,
				s.dataItem.dataItemId,
				s.slotPath,
				s.dataItem.label
			)
			
		});
		return currentSeries
	}
	applyTransforms(data){
		let currentSeries = this.getSeries()
		currentSeries?.forEach(
			(s,i)=>{
			if(s.transform && this.state.transforms[i]?.[0] && this.state.transforms[i]?.[1]){
				data[i+1] = data[i+1].map(d=>indexMatch(d,this.state.transforms[i][0],this.state.transforms[i][1]))
			}
			s.label = s.alias || getFormattedLabel(
				this.props.definition,
				s.dataItem.dataItemId,
				s.slotPath,
				s.dataItem.label
			)
		})
		return data
	}

	liveDataLoad(){
		//In this case, we will load whatever data is new, append to data buffer, and update the window we are querying for.
		
		var startTime = this.state.lastLoad;
		var endTime = new Date(new Date +3600000);
		
		const nextDataCall = () => {
			clearTimeout(this.fetchTimeout)
			if(this.props.properties.refreshData && this.props.properties.refreshInterval >= 2){
				this.fetchTimeout = setTimeout(()=>this.liveDataLoad(),1000*(this.props.properties.refreshInterval ?? 10 ))
			}
		}
		this.fetchFromApi(startTime,endTime,100,"LIVEDATA").then((newPoints)=>{
			//Only need to take action if data came in
			if(newPoints[0].length > 0 && newPoints[0][0] > 946684800){
				var lastLoad = new Date(1000 * newPoints[0][newPoints[0].length-1])
				var data = [...this.state.data]
				for(var i = 0 ; i < data.length; i++){
					data[i] = data[i].concat(newPoints[i])
				}
				data = sort2DarrayByFirstArray(data)
				this.setState({
					//previousQueries:[[startTime.getTime()/1000,endTime.getTime()/1000]],
					data,lastLoad
				},nextDataCall)
			}
			else{
				nextDataCall()
			}

			
		})
	}
	enhance(decimation,startTime,endTime){

		//We will be loading some extra data, and then appending. No fetch loop.
		if(!decimation){
			decimation = this.state.decimation ?? 20
		}

		if(startTime && endTime){

			var thresh = decimation < 100
				? decimation * 30
				: -1;
			
			
			let enhanceAction = ()=>{
				var newQueries = [[startTime.getTime()/1000,endTime.getTime()/1000]]
				
				
				
				Promise.all(
					newQueries.map(q=>this.fetchFromApi(new Date(q[0]*1000),new Date(q[1] * 1000),thresh,"ENHANCE"))
				).then(responses=>{
					var data = [...this.state.data]
					for(var i in responses){
						var newPoints = responses[i]
						
						if(newPoints[0].length > 0){
							var data = merge2DArraysWithFirstArrayKey(data,newPoints)
						}
						data = sort2DarrayByFirstArray(data)
					}
					this.setState({
						data
					})
				})
			}
			
			enhanceAction();
		}
	}
	extend(debounce,thresh,startTime,endTime){

		//We will be loading some extra data, and then appending. No fetch loop.
		
		if(this.state.zoom?.length == 2){
			if(!startTime){ startTime = new Date(this.state.zoom[0]*1000);}
			if(!endTime){ endTime = new Date(this.state.zoom[1]*1000);}
			if(!thresh){
				thresh = (this.state.decimation ?? 20) < 100
				? (this.state.decimation ?? 20) * 30
				: -1;
			} 
			
			let extendTimeout = ()=>{
				var newQueries = getNeededQueries(this.state.zoom,this.state.previousQueries)
				
				
				
				Promise.all(
					newQueries.map(q=>this.fetchFromApi(new Date(q[0]*1000),new Date(q[1] * 1000),thresh,"EXTEND"))
				).then(responses=>{
					var data = [...this.state.data]
					var fulfilledQueries = [...this.state.previousQueries]
					for(var i in responses){
						var newPoints = responses[i]
						
						if(newPoints[0].length > 0){
							var data = merge2DArraysWithFirstArrayKey(data,newPoints)
						}
						data = sort2DarrayByFirstArray(data)
						fulfilledQueries.push(newQueries[i])
					}
					var fulfilledQueries = reduceIntervals(fulfilledQueries)
					this.setState({
						data,
						previousQueries:fulfilledQueries
					})
				})
			}
			clearTimeout(this.extendTimeout)
			this.extendTimeout = setTimeout(extendTimeout,debounce)
		
		}
	}



	// shouldComponentUpdate(nextProps) {
	// 	return !this.state.userZoom;
	// }
	emptyArr(length) {
		var arr = [];
		for (var i = 0; i < length; i++) {
			arr.push([]);
		}
		return arr;
	}
	render() {
		const currentSeries = this.getSeries()
		try {
			
			if (this.state.opts && this.state.data) {
				return (
					<div
						style={{
							display: "flex",
							height: "100%",
							flexDirection: "column",
						}}
					>
						<LimePanel isOpen={this.state.editAnnotations} onDismiss={()=>this.setState({editAnnotations:false})}>
							<Annotations assetId={this.props.system.assetId}></Annotations>
						</LimePanel>
						
						{this.state.createAnnotation && <Modal onDismiss={()=>this.setState({createAnnotation:false})} isOpen={true}>
						{/* <DateTimePicker datetime={this.state.annotationStart}></DateTimePicker>
						<DateTimePicker datetime={this.state.annotationEnd}></DateTimePicker> */}
						<div style={{padding:15}}>
							<TextField 
							value={this.state.createAnnotationNote} 
							onChange={(e,val)=>this.setState({createAnnotationNote:val})} 
							label="Note text"></TextField>
							{/* <ChoiceGroup selectedKey={this.state.annotationType} options={[
								{
									key:"tile",
									text:"This tile only"
								},
								{
									key:"dashboard",
									text:"Every tile"
								}
							]} onChange={(e,val)=>this.setState({annotationType:val.key})} label="Where would you like to show this annotation?" /> */}
							<div style={{display:"flex"}}>
							{["#dddddd","#ff0000","#00ff00","#0000ff","#ffff00","#00ffff","#ff00ff"].map(c=>{
								var color = c+"33"
								return (<span onClick={()=>this.setState({createAnnotationColor:color})} style={{height:30,width:30,margin:3,border:"1px dashed white",backgroundColor:color}}> {color == this.state.createAnnotationColor && "✓" } </span>)
							})}
							<PopoutColorPicker
							color={this.state.createAnnotationColor} onChange={(e,val)=>{this.setState({createAnnotationColor:val.str})}}
							></PopoutColorPicker>
							</div>
							
						
							
							<Stack horizontal>
								<PrimaryButton text="Submit" onClick={()=>{
									var annotationEd={
										category:1,
										assetDefinitionId:this.props.system.assetDefinitionId,
										name:"Graph annotation"
									}
									fetch("/api/events/definitions",{
										method:"put",
										headers:{"Content-Type":"application/json"},
										body:JSON.stringify(annotationEd)
									}).then(ed=>ed.json()).then(
										ed=>{
											var event={
												assetId:this.props.system.assetId,
												eventDefinitionId:ed.eventDefinitionId,
												timestamp:this.state.annotationEnd,
												cause:this.state.createAnnotationNote,
												meta:JSON.stringify({
													start:this.state.annotationStart,
													end:this.state.annotationEnd,
													highlight:this.state.createAnnotationColor,
													type:this.state.annotationType,
													tileOrigin:this.props.properties.id
												})
											}
											fetch("/api/events/",{
												method:"put",
												headers:{"Content-Type":"application/json"},
												body:JSON.stringify([event])
											}).then(()=>{
												this.setState({annotate:false,createAnnotation:false},()=>{
													this.props.queryClient.invalidateQueries("assets/events")
												})
											})
										}
									)


								}}></PrimaryButton>
								<DefaultButton text="Cancel" onClick={()=>this.setState({annotate:false,createAnnotation:false})}></DefaultButton>
							</Stack>
							</div>
							</Modal>}
						{this.state.loading && <Spinner></Spinner>}
						{this.props.ui.viewOnly && (
							<div
								id="graphTools"
								style={{
									position: "absolute",
									top: "0px",
									right: "0px",
								}}
							>
								
								<IconButton
									title="Annotate"
									checked={this.state.annotate}
									styles={{
										root: { height: 18 },
										menuIcon: { fontSize: 10 },
										icon: { fontSize: 10 },
									}}
									style={{ zIndex: 10 }}
									iconProps={{ iconName: "CommentAdd" }}
									menuProps={{
										shouldFocusOnMount: true,
										items: [
											{ key: 'add annotation', 
											text:"Add note",
											onClick:()=>{this.setState({annotate:!this.state.annotate})},
										 },
										 { key: 'edit annotation', text:"Edit notes",
										   	onClick:()=>{this.setState({editAnnotations:!this.state.editAnnotations})},
											}

										],
									  }}
								
								/>
								

								
								<IconButton
									title="Enhance"
									styles={{
										root: { height: 18 },
										menuIcon: { fontSize: 10 },
										icon: { fontSize: 10 },
									}}
									
									menuProps={{
										shouldFocusOnMount: true,
										items: [
										   { key: 'enh', onRender:()=><ActionButton text="Enhance"  onClick={()=>{this.enhance(null,new Date(this.state.zoom[0]*1000),new Date(this.state.zoom[1]*1000))} }iconProps={{iconName:"LightningBolt"}} ></ActionButton>},
										   { key: 'res', onRender:()=><Slider defaultValue={this.state.decimation} min={1} max={100} onChanged={(e,val)=>this.setState({decimation:val},()=>this.enhance(null,new Date(this.state.zoom[0]*1000),new Date(this.state.zoom[1]*1000)))}></Slider>},

										],
									  }}
									style={{ zIndex: 10 }}
									iconProps={{ iconName: "LightningBolt" }}
								
								
								/>
								<IconButton
									title="Refresh Graph"
									styles={{
										root: { height: 18 },
										menuIcon: { fontSize: 10 },
										icon: { fontSize: 10 },
									}}
									style={{ zIndex: 10 }}
									iconProps={{ iconName: "refresh" }}
									onClick={() => {
										clearTimeout(this.fetchTimeout);
										this.firstDataLoad()
									}}
								/>
								<IconButton
									title="Export raw data"
									styles={{
										root: { height: 18 },
										menuIcon: { fontSize: 10 },
										icon: { fontSize: 10 },
									}}
									style={{ zIndex: 10 }}
									iconProps={{ iconName: "download" }}
									onClick={() => {
										let csvContent =
											"data:text/csv;charset=utf-8,";
										var headers = [
											"timestamp",
											...currentSeries.map(
												(el) => {
													return el.dataItem.label
												}
												
											),
										];
										var csvData = [];

										for(var j = 0; j< this.state.data[0].length; j++){
											let newRow = []
											
											if(this.state.zoom == null || (this.state.data[0][j] > this.state.zoom[0] && this.state.data[0][j] < this.state.zoom[1])){
												var d = new Date(1000*this.state.data[0][j])
												newRow[0] = strftime("%F %H:%M:%S",d)
												for(var i = 1; i <this.state.data.length; i++){
													newRow[i] = this.state.data[i][j]
												}
												csvData.push(newRow)
											}
											
										}
										
										csvContent += headers.join(",");
										csvContent += "\n";
										csvContent += Papa.unparse(csvData);
										var encodedUri = encodeURI(csvContent);
										var link = document.createElement("a");
										link.setAttribute("href", encodedUri);
										link.setAttribute(
											"download",
											"export.csv"
										);
										document.body.appendChild(link); // Required for FF
										link.click();
									}}
								/>
								
									
							</div>
							
						)}
						{
						<div style={{zIndex:99999999,position:"absolute",right:25,bottom:25,backgroundColor:"#4444"}} >
							<IconButton iconName="LightningBolt"  onClick={()=>{this.enhance(null,new Date(this.state.zoom[0]*1000),new Date(this.state.zoom[1]*1000))} } iconProps={{iconName:"LightningBolt"}} ></IconButton>
					   </div>
						}
						<div
							style={{
								height: "100%",
								width: "100%",
								cursor:this.state.annotate ? "crosshair" : "",
								visibility: this.state.loading
									? "hidden"
									: "inherit",
							}}
						>
							<UPlotComponent
								opts={this.state.opts}
								data={
									this.state.data.length ==
									currentSeries.length + 1
										? this.state.data
										: this.emptyArr(
												currentSeries
													.length + 1
										  )
								}
							/>
						
						</div>
						{/* {this.getSeries().map(s=>{
							return (
							<Stack horizontal>
								<TextField style={{width:80}} value = {s.axis?.min}></TextField>
								<Stack.Item grow={1}>
									<SystemDataItemSelector slotPath = {s.slotPath} dataItem = {s.dataItem} onDataItemChanged={()=>{

									}}  ></SystemDataItemSelector>
								</Stack.Item>
								<TextField style={{width:80}}  value = {s.axis?.max}></TextField>
							</Stack>)
						})} */}
					</div>
				);
			} else {
				return <></>;
			}
		} catch (error) {
			console.log("ERROR RENDERING CHART", error);
			return <>{error.toString()}</>;
		}
	}
}
function mapStateToProps({ currentData, playback, ui, assets, definition }) {
	return {
		currentData,
		definition,
		playback,
		ui,
		assets,
	};
}

function getNeededQueries([start,end],previousRanges){
	
	//A point of interest is a moment in time when our interest in querying data changes
	//We become interested at the start of the range, and disinterested in the end of the range

	//ndr=need data right, hdr = has data right, ndl = needs data left, hdl = has data left
	let poi = [
		start,
		end
	]
	//We will now add in the previously queried ranges. For these, we are oppositely interested.
	//If the range starts, we do no need data anymore. If the range ends, we will need data from there.

	for(var i = 0 ; i < previousRanges.length; i++){
		let [rangeStart,rangeEnd] = previousRanges[i]
		//All we know about these POI's is that the have data loaded on one side or the other
		poi.push(rangeStart)
		poi.push(rangeEnd)

	}
	//Sort chronologically.
	poi = poi.sort((p1,p2)=>p1-p2)
	let ranges=[]
	for(var i = 1 ; i < poi.length; i++){
		var testT = (poi[i-1] + poi[i] )/2
		//is this range inside of our request range?
		var inRange = testT > start && testT < end
		//Is this range included inside any of the already existing ranges?
		var loaded = previousRanges.findIndex(r=>testT < r[1] && testT > r[0]) >= 0 
		if(inRange && !loaded){
			ranges.push([poi[i-1],poi[i]])
		}
		
	}
	ranges = reduceIntervals(ranges)
	return (ranges)
}

function reduceIntervals(intervals){
	var isReduced = false;
  let iter =0
	while(!isReduced && iter < 30){
  iter++
		var isReduced = true
		forEachInterval: for(var i = 0; i < intervals.length; i++){
			let [thisStart, thisEnd]= intervals[i]
			for(var j = 0; j < intervals.length; j++){
				if (i==j) continue;
				let [matchStart, matchEnd] = intervals[j]

				//Intervals intersect. Splice, combine, push, break
				if((thisStart >= matchStart && thisStart <=matchEnd) || (thisEnd >= matchStart && thisEnd <=matchEnd )){
        var i1 = intervals[i]
					var i2 = intervals[j]
         var combined = [Math.min(i1[0],i2[0]), Math.max(i1[1],i2[1])]
         
          intervals = intervals.filter((el,ind)=> ind !=i && ind != j)
					intervals.push(combined)
					isReduced = false;
					break forEachInterval;
				}
				
			}
		
		}
	}
	return intervals
}
function indexMatch(x,xVals,yVals){
	if(typeof x ==  typeof 0){
		var firstGreaterIndex = xVals.findIndex(xVal=>xVal > x)
		if(firstGreaterIndex > 0){
			var nextX = xVals[firstGreaterIndex]
			var lastX = xVals[firstGreaterIndex -1]
			var fractionalIndex = 0
		
			if(lastX >=0){
				fractionalIndex = (x - lastX) / (nextX - lastX)
		
			}
			
			var nextY = yVals[firstGreaterIndex]
			var lastY = yVals[firstGreaterIndex -1]
		
			return lastY + (nextY - lastY) * fractionalIndex
		}
		else{
			return yVals[0]
		}
	}
	else {
		return x
	}
   

}