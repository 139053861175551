import { basicSetup, EditorView } from "@codemirror/basic-setup";
import { EditorState, Compartment } from "@codemirror/state";
import { useRef, useEffect } from "react";
import { javascript } from "@codemirror/lang-javascript";

export default function CodeMirror(props) {
	var divRef = useRef();
	var viewRef = useRef();
	var stateRef = useRef();

	useEffect(() => {
		let myTheme = EditorView.theme(
			{
				"&": {
					color: ivory,
					backgroundColor: background,
				},

				".cm-content": {
					caretColor: cursor,
				},

				"&.cm-focused .cm-cursor": { borderLeftColor: cursor },
				"&.cm-focused .cm-selectionBackground, .cm-selectionBackground, .cm-content ::selection":
					{ backgroundColor: selection },

				".cm-panels": { backgroundColor: darkBackground, color: ivory },
				".cm-panels.cm-panels-top": { borderBottom: "2px solid black" },
				".cm-panels.cm-panels-bottom": { borderTop: "2px solid black" },

				".cm-searchMatch": {
					backgroundColor: "#72a1ff59",
					outline: "1px solid #457dff",
				},
				".cm-searchMatch.cm-searchMatch-selected": {
					backgroundColor: "#6199ff2f",
				},

				".cm-activeLine": { backgroundColor: highlightBackground },
				".cm-selectionMatch": { backgroundColor: "#aafe661a" },

				"&.cm-focused .cm-matchingBracket, &.cm-focused .cm-nonmatchingBracket":
					{
						backgroundColor: "#bad0f847",
						outline: "1px solid #515a6b",
					},

				".cm-gutters": {
					backgroundColor: background,
					color: stone,
					border: "none",
				},

				".cm-activeLineGutter": {
					backgroundColor: highlightBackground,
				},

				".cm-foldPlaceholder": {
					backgroundColor: "transparent",
					border: "none",
					color: "#ddd",
				},

				".cm-tooltip": {
					border: "none",
					backgroundColor: tooltipBackground,
				},
				".cm-tooltip .cm-tooltip-arrow:before": {
					borderTopColor: "transparent",
					borderBottomColor: "transparent",
				},
				".cm-tooltip .cm-tooltip-arrow:after": {
					borderTopColor: tooltipBackground,
					borderBottomColor: tooltipBackground,
				},
				".cm-tooltip-autocomplete": {
					"& > ul > li[aria-selected]": {
						backgroundColor: highlightBackground,
						color: ivory,
					},
				},
			},
			{ dark: true }
		);
		let language = new Compartment(),
			tabSize = new Compartment();

		stateRef.current = EditorState.create({
			doc: "hello",
			extensions: [basicSetup, language.of(javascript()), myTheme],
		});

		viewRef.current = new EditorView({
			state: stateRef.current,
			parent: divRef.current,
		});
		return () => {
			viewRef.current.destroy();
		};
	}, [viewRef.current, divRef.current, stateRef.current]);

	return <div ref={divRef}></div>;
}

const chalky = "#e5c07b",
	coral = "#e06c75",
	cyan = "#56b6c2",
	invalid = "#ffffff",
	ivory = "#abb2bf",
	stone = "#7d8799", // Brightened compared to original to increase contrast
	malibu = "#61afef",
	sage = "#98c379",
	whiskey = "#d19a66",
	violet = "#c678dd",
	darkBackground = "#21252b",
	highlightBackground = "#2c313a",
	background = "#282c34",
	tooltipBackground = "#353a42",
	selection = "#3E4451",
	cursor = "#528bff";
