import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import { applyMiddleware, createStore } from "redux";
import { batchedSubscribe } from "redux-batched-subscribe";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";
import createRootReducer from "./reducers";

export default function configureStore(preloadedState) {
	const store = createStore(
		createRootReducer(), // root reducer with router state
		preloadedState,
		composeWithDevTools(
			applyMiddleware(ReduxThunk),
			batchedSubscribe(batchedUpdates)
		)
	);

	return store;
}
