export default function SteppedLine(u, sidx, i0, i1) {
	const s = u.series[sidx];
	const xdata = u.data[0];
	const ydata = u.data[sidx];
	const scaleX = "x";
	const scaleY = s.scale;

	const stroke = new Path2D();
	stroke.moveTo(
		Math.round(u.valToPos(xdata[0], scaleX, true)),
		Math.round(u.valToPos(ydata[0], scaleY, true))
	);

	for (let i = i0; i <= i1 - 1; i++) {
		let x0 = Math.round(u.valToPos(xdata[i], scaleX, true));
		let y0 = Math.round(u.valToPos(ydata[i], scaleY, true));
		let x1 = Math.round(u.valToPos(xdata[i + 1], scaleX, true));
		let y1 = Math.round(u.valToPos(ydata[i + 1], scaleY, true));

		stroke.lineTo(x0, y0);
		stroke.lineTo(x1, y0);

		if (i == i1 - 1) stroke.lineTo(x1, y1);
	}

	const fill = new Path2D(stroke);

	let minY = Math.round(u.valToPos(u.scales[scaleY].min, scaleY, true));
	let minX = Math.round(u.valToPos(u.scales[scaleX].min, scaleX, true));
	let maxX = Math.round(u.valToPos(u.scales[scaleX].max, scaleX, true));

	fill.lineTo(maxX, minY);
	fill.lineTo(minX, minY);

	return {
		stroke,
		fill,
	};
}
