const currentData = (state = {}, action) => {
	switch (action?.type) {
		// case "CURRENT_DATA": {
		// 	if (action.payload.status === 200) {
		// 		for (var i = 0; i < action.payload.data.length; i++) {
		// 			var cdi = action.payload.data[i];
		// 			if (!state[cdi.assetId]) state[cdi.assetId] = {};
		// 			if (!state[cdi.assetId][cdi.dataItemId]) {
		// 				state[cdi.assetId][cdi.dataItemId] = cdi;
		// 			}
		// 			Object.assign(state[cdi.assetId][cdi.dataItemId], cdi);
		// 		}
		// 	}
		// 	return { ...state };
		// }
		case "DEFINITION": {
			if (!state.definitions) state.definitions = {};
			for (var x in action.payload.dataItemDefinitions) {
				state.definitions[
					action.payload.dataItemDefinitions[x].dataItemId
				] = action.payload.dataItemDefinitions[x];
			}
			return { ...state };
		}
		case "UPDATE_SINGLE": {
			var cdi = action.payload;
			if (!state[cdi.assetId]) state[cdi.assetId] = {};
			if (!state[cdi.assetId][cdi.dataItemId])
				state[cdi.assetId][cdi.dataItemId] = cdi;
			Object.assign(state[cdi.assetId][cdi.dataItemId], cdi);
			return { ...state };
		}
		case "UPDATE_MULTIPLE": {
			let newState = {...state}
			for(var i = 0 ; i  < action.payload.length; i++){
			const di = action.payload[i]
				//create asset object id not exist

				if(action.payload.length < 5){
					//console.log(action.payload)
				}
				const {assetId,dataItemId} = di
				if (!newState[assetId]) {
					//console.log(`registered asset  ${a}`)
					newState[assetId] = {};
				}

				//create dataItem from definition, if not exists
				if (!newState[assetId][dataItemId]) {
					//console.log(`registered dataItem ${d}`)
					newState[assetId][dataItemId] = newState.definitions?.[dataItemId] ?? {};
				}
				//Remove the label if it existed?
				// if (state[cdi.assetId][cdi.dataItemId].label) {
				// 	delete cdi.label;
				// }
				//update the state with the new object
				//newState[assetId][dataItemId].value = (assetId+ di.assetId)/2
				if(newState[assetId][dataItemId].assetId == di.assetId || null == newState[assetId][dataItemId].assetId){
					Object.assign(newState[assetId][dataItemId],di)
				}
			
				

			}
			//console.log(state)
			return newState ;
		}
		case "SYSTEM_UPDATED": {
			let assetId = action.payload.assetId;
			if (!state[assetId]) state[assetId] = {};
			let aliasMap = action.payload.attributes.find(
				(att) => att.attributeName.toLowerCase() == "aliasmap"
			)?.attributeValue;
			if (aliasMap && state[assetId]) {
				aliasMap = JSON.parse(aliasMap);
				for (var dataItemId in aliasMap) {
					if (state[assetId][dataItemId]) {
						state[assetId][dataItemId].label = aliasMap[dataItemId];
					} else {
						state[assetId][dataItemId] = {
							label: aliasMap[dataItemId],
						};
					}
				}
			}
			return { ...state };
		}
		default:
			return { ...state };
	}
};

export default currentData;
