import React, { Component } from "react";
import { connect } from "react-redux";
import {
	format,
	getAssetIdFromSystem,
	runEvals,
	substitute,
} from "../../../../util";

class CustomHTML extends Component {
	static defaultProps = {
		data: undefined, //{}
		decimals: "2",
		fontSize: "14",
	};

	render() {
		const { properties, fontSize } = this.props;
		var { text, substitutions, decimals } = { ...properties };
		var currentDataPaths = {};
		var subObject = { ...this.props };
		for (var sub in substitutions) {
			var assetId = getAssetIdFromSystem(
				null,
				substitutions[sub].slotPath
			);
			if (substitutions[sub]["dataItem"]) {
				currentDataPaths[sub] = [
					"currentData",
					assetId,
					substitutions[sub].dataItem.dataItemId,
					"value",
				];

				// subObject["currentData"][assetId][
				// 	substitutions[sub].dataItem.dataItemId
				// ]["value"] = subObject["currentData"][assetId][
				// 	substitutions[sub].dataItem.dataItemId
				// ]["value"]?.toFixed(decimals);
			}
		}
		text = substitute(text, currentDataPaths);
		if(this.props.system.assetId){
		subObject.attributes = Object.fromEntries(
			this.props.system.attributes.map((att) => {
				return [att.attributeName, att.attributeValue];
			})
		);
		}
		else if(this.props.system.systemId){
			// subObject.attributes = Object.fromEntries(
			// 	this.props.system.slotAssignments.map((att) => {
			// 		return [att.attributeName, att.attributeValue];
			// 	})
			// );
			

		}
		text = format(text, subObject, decimals);
		text = runEvals(text);
		return (
			<div
				style={{
					width: "100%",
					height: "100%",
					fontSize: `${fontSize}px`,
					position: "absolute",
				}}
				dangerouslySetInnerHTML={{ __html: text }}
			></div>
		);
	}
}
function attributeEntries(slotAssignment){
	if(slotAssignment.assetId){
		return [slotAssignment.slotName,]
	}
	
}
function mapStateToProps({ system, currentData,assets }) {
	return { system, currentData,assets };
}

export default connect(mapStateToProps)(CustomHTML);
