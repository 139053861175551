const tiles = (state = [[]], action) => {
	switch (action.type) {
		case "ADD_TILE": {
			const { activeTab, id, tile } = action;
			const addTiles = state;
			const addedTile = [
				...state[activeTab.index],
				{
					id: id,
					properties: tile.properties,
					dataGrid: tile.dataGrid,
				},
			];

			addTiles.splice(activeTab.index, 1, addedTile);
			return [...addTiles];
		}
		case "CLONE_TILE": {
			const { activeTab, id, tile } = action;

			var clone = {
				...state[activeTab.index].find((el) => el.id == tile.id),
			};
			clone.id = id;
			const addedTile = [...state[activeTab.index], clone];

			state.splice(activeTab.index, 1, addedTile);
			return state;
		}
		case "DELETE_TILE": {
			console.log(state);
			const { activeTab, id } = action;
			const newTiles = state[activeTab.index].filter(
				(tile) => tile.id !== id
			);
			const tiles = state.slice(0);
			tiles.splice(activeTab.index, 1, newTiles);
			console.log(state);
			return [...tiles];
		}
		case "EDIT_TILE": {
			console.log(action);
			const { index, newTile } = action;
			const newTiles = state[index].filter(
				(tile) => tile.id !== newTile.id
			);
			newTiles.push(newTile);
			const tiles = state.slice(0);
			tiles.splice(index, 1, newTiles);

			return [...tiles];
		}
		// case 'ENLARGE_TILE':{
		//   console.log(state)
		//   var tiles = []
		//   for(var x in state){
		//     for(var y in state[x]){
		//       tiles.push(state[x][y])
		//     }
		//   }
		//   console.log(tiles)
		//   const { id } = action;
		//   const thetile = tiles.filter((tile) => tile.id == id);
		//   thetile.originalGrid=thetile.dataGrid
		//   thetile.dataGrid = {"x":0,"y":0,"w":96,"h":10}
		//   return {...state,enlargedTile:action.tile}
		// }
		case "ADD_TAB":
			return [...state, []];
		case "DELETE_TAB":
			// filter state with index of tab
			var tiles = [...state];
			tiles.splice(action.index, 1);
			return tiles;
		case "SWITCH_TABS": {
			let a = state.slice();
			let { from, to } = action.payload;
			[a[from], a[to]] = [a[to], a[from]];
			return a;
		}
		case "LOAD_DASHBOARD":
			if (action.payload.tiles) {
				var tiles = [];
				for (var page in action.payload.tiles) {
					tiles.push(action.payload.tiles[page]);
				}
				return tiles;
			} else {
				return state;
			}
		default:
			return state;
	}
};

export default tiles;
