const layouts = (state = [{}], action) => {
	switch (action.type) {
		case "SAVE_LAYOUT":
			const { layouts, index } = action;
			const newLayouts = state.slice(0);
			newLayouts.splice(index, 1, layouts);
			return newLayouts;
		case "ADD_TAB":
			return [...state, {}];
		case "SWITCH_TABS": {
			let a = state.slice();
			let { from, to } = action.payload;
			[a[from], a[to]] = [a[to], a[from]];
			return a;
		}
		case "CLONE_TILE":
			const { activeTab, id, tile } = action;
			var layoutCopy = state.slice(0);
			for (var layout in layoutCopy[activeTab.index]) {
				var ogLayout = layoutCopy[activeTab.index][layout].find(
					(ly) => ly.i == tile.id
				);
				var tileLayout = {
					w: ogLayout?.w ?? 30,
					h: ogLayout?.h ?? 40,
					x: ogLayout?.x ?? 0,
					y: ogLayout?.y ?? 0,
					i: id,
					moved: false,
					static: false,
				};
				layoutCopy[activeTab.index][layout].push(tileLayout);
			}
			return layoutCopy;
		case "DELETE_TAB":
			// filter state with index of tab
			return state.slice().filter((layouts, i) => i !== action.index);
		case "SAVE_DASHBOARD":
			return state;
		case "LOAD_DASHBOARD":
			if (action.payload.layouts) {
				return action.payload.layouts;
			} else {
				return state;
			}
		default:
			return state;
	}
};

export default layouts;
