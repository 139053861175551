import {
	Dropdown,
	getTheme,
	IconButton,
	PrimaryButton,
	Stack,
} from "@fluentui/react";
import React from "react";
import { connect } from "react-redux";
import uPlot from "uplot";
import * as actions from "../../../actions/index.js";
import DateTimePicker from "../components/forms/DateTimePicker";

class PlaybackBar extends React.Component {
	state = {
		customRange: false,
		newStartDate: this.props.playback.startDate,
		newEndDate: this.props.playback.endDate,
	};
	componentDidMount() {
		//this.makeRanger()
	}
	makeRanger() {
		var loop = 100;

		let data = [Array(loop), Array(loop)];

		for (var i = 0; i < loop; i++) {
			let x = (2 * Math.PI * i) / loop;
			let y = Math.sin(x);

			data[0][i] = x;
			data[1][i] = y;
		}

		let initXmin = 1;
		let initXmax = 4.5;

		//----------------

		const doc = document;

		function debounce(fn) {
			let raf;

			return (...args) => {
				if (raf) return;

				raf = requestAnimationFrame(() => {
					fn(...args);
					raf = null;
				});
			};
		}

		function placeDiv(par, cls) {
			let el = doc.createElement("div");
			el.classList.add(cls);
			par.appendChild(el);
			return el;
		}

		function on(ev, el, fn) {
			el.addEventListener(ev, fn);
		}

		function off(ev, el, fn) {
			el.removeEventListener(ev, fn);
		}

		//----------------

		let x0;
		let lft0;
		let wid0;

		const lftWid = { left: null, width: null };
		const minMax = { min: null, max: null };

		function update(newLft, newWid) {
			select(newLft, newWid);
			zoom(newLft, newWid);
		}

		function select(newLft, newWid) {
			lftWid.left = newLft;
			lftWid.width = newWid;
			uRanger.setSelect(lftWid, false);
		}

		function zoom(newLft, newWid) {
			minMax.min = uRanger.posToVal(newLft, "x");
			minMax.max = uRanger.posToVal(newLft + newWid, "x");
			//uZoomed.setScale('x', minMax);
		}

		function bindMove(e, onMove) {
			x0 = e.clientX;
			lft0 = uRanger.select.left;
			wid0 = uRanger.select.width;
			const _onMove = debounce(onMove);

			on("mousemove", doc, _onMove);
			on("mouseup", doc, (e) => {
				off("mousemove", doc, _onMove);
				//viaGrip = false;
			});
			e.stopPropagation();
		}

		//----------------

		const rangerOpts = {
			width: 900,
			height: 100,
			cursor: {
				x: false,
				y: false,
				points: {
					show: false,
				},
				drag: {
					setScale: false,
					setSelect: true,
					x: true,
					y: false,
				},
			},
			legend: {
				show: false,
			},
			scales: {
				x: {
					time: false,
				},
			},
			series: [
				{},
				{
					stroke: "red",
				},
			],
			hooks: {
				ready: [
					(uRanger) => {
						let left = Math.round(uRanger.valToPos(initXmin, "x"));
						let width =
							Math.round(uRanger.valToPos(initXmax, "x")) - left;
						let height = uRanger.bbox.height / devicePixelRatio;
						uRanger.setSelect({ left, width, height }, false);

						const sel = uRanger.root.querySelector(".u-select");

						on("mousedown", sel, (e) => {
							bindMove(e, (e) =>
								update(lft0 + (e.clientX - x0), wid0)
							);
						});

						on("mousedown", placeDiv(sel, "u-grip-l"), (e) => {
							bindMove(e, (e) =>
								update(
									lft0 + (e.clientX - x0),
									wid0 - (e.clientX - x0)
								)
							);
						});

						on("mousedown", placeDiv(sel, "u-grip-r"), (e) => {
							bindMove(e, (e) =>
								update(lft0, wid0 + (e.clientX - x0))
							);
						});
					},
				],
				setSelect: [
					(uRanger) => {
						zoom(uRanger.select.left, uRanger.select.width);
					},
				],
			},
		};
		let uRanger;
		fetch(
			`/api/historicaldata?h=h&columnar=true&threshold=1500&dataItems=${this.props.system.assetId}-19&startTime=2021-02-22T18:03:05.809Z&endTime=2021-02-25T19:03:10.972Z`
		)
			.then((r) => r.json())
			.then((data) => {
				uRanger = new uPlot(
					rangerOpts,
					data,
					document.getElementById("playbackChart")
				);
			});
	}

	render() {
		var theme = getTheme();
		var round = {
			textAlign: "center",
			lineHeight: "40px",
			backgroundColor: theme.palette.themePrimary,
			width: "40px",
			height: "40px",
			borderRadius: "100px",
		};
		return (
			<div
				hidden={!this.props.ui.showPlayback}
				style={{
					backgroundColor: "#333333BB",
					zIndex: "100",
					borderTop: "1px solid #eeea",
					position: "fixed",
					bottom: "0px",
					width: "100%",
				}}
			>
				<Stack>
					<div style={{ position: "absolute", right: 0, top: 0 }}>
						<IconButton
							iconProps={{ iconName: "Cancel" }}
							onClick={() => {
								this.props.togglePlaybackBar();
							}}
						></IconButton>
					</div>

					<Stack
						horizontal
						tokens={{ childrenGap: 3 }}
						horizontalAlign={"center"}
						wrap={true}
					>
						<div
							style={round}
							onClick={() => {
								this.setState({
									customRange: !this.state.customRange,
								});
							}}
						>
							Custom
						</div>
						<div
							style={round}
							onClick={() => {
								this.props.setStartDate(
									new Date(
										this.state.newEndDate.getTime() -
											7 * 24 * 3600 * 1000
									)
								);
							}}
						>
							7D
						</div>
						<div
							style={round}
							onClick={() => {
								this.props.setStartDate(
									new Date(
										this.state.newEndDate.getTime() -
											3 * 24 * 3600 * 1000
									)
								);
							}}
						>
							3D
						</div>
						<div
							style={round}
							onClick={() => {
								this.props.setStartDate(
									new Date(
										this.state.newEndDate.getTime() -
											24 * 3600 * 1000
									)
								);
							}}
						>
							24H
						</div>
						<div
							style={round}
							onClick={() => {
								this.props.setStartDate(
									new Date(
										this.state.newEndDate.getTime() -
											6 * 3600 * 1000
									)
								);
							}}
						>
							6H
						</div>
						<div
							style={round}
							onClick={() => {
								this.props.setStartDate(
									new Date(
										this.state.newEndDate.getTime() -
											3600 * 1000
									)
								);
							}}
						>
							1H
						</div>
					</Stack>
					{this.state.customRange && (
						<Stack
							verticalAlign="center"
							horizontal
							horizontalAlign="space-between"
						>
							<DateTimePicker
								datetime={this.state.newStartDate}
								defaultDatetime={
									new Date(
										this.props.playback.endDate.getTime() -
											3600 * 1000
									)
								}
								onDateChanged={(newStartDate) => {
									this.setState({ newStartDate });
								}}
							></DateTimePicker>
							<Stack.Item grow="1"></Stack.Item>

							<DateTimePicker
								datetime={this.state.newEndDate}
								onDateChanged={(newEndDate) => {
									this.setState({ newEndDate });
								}}
							></DateTimePicker>
							{this.props.playback.startDate !=
								this.state.newStartDate ||
								this.props.playback.endDate !=
									this.state.newEndDate}
							<PrimaryButton
								text="Load Data"
								onClick={() => {
									this.props.setEndDate(
										this.state.newEndDate
									);
									this.props.setStartDate(
										this.state.newStartDate
									);
								}}
							></PrimaryButton>
						</Stack>
					)}

					<Stack.Item grow>
						<Stack>
							{/* <div style={{height:"60px"}} id="playbackChart"></div> */}
							{/* <Slider
                showValue={false}
                value={this.state.value}
                onChange={
                    (val)=>{
                        var interval = this.state.endDate - this.state.startDate
                        var offset = (this.state.value/1000000)*interval
                        var dateOnSlider = new Date(this.state.startDate.getTime() + offset)
                        this.setState({
                            value:val,dateOnSlider:dateOnSlider
                        })
                    }
                }
                
                min={0}
                max={1000000}
            /> */}
							{/* <Stack horizontal horizontalAlign="space-between">
								<Stack horizontal verticalAlign="center">
									<IconButton
										iconProps={{ iconName: "Rewind" }}
									></IconButton>
									<IconButton
										iconProps={{ iconName: "Play" }}
									></IconButton>
									<IconButton
										iconProps={{ iconName: "FastForward" }}
									></IconButton>
									<Text>
										{this.props.playback.startDate.toLocaleString()}{" "}
										{" - "}{" "}
										{this.props.playback.endDate.toLocaleString()}
									</Text>
								</Stack>
								<div style={{ minWidth: "200px" }}></div>
							</Stack> */}
						</Stack>
					</Stack.Item>
				</Stack>
			</div>
		);
	}
}
function mapStateToProps({ playback, ui, system }) {
	return { playback, ui, system };
}
export default connect(mapStateToProps, actions)(PlaybackBar);
