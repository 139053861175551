import { DefaultButton, Dropdown, Icon, IContextualMenuListProps, IRenderFunction, ISearchBoxStyles, SearchBox } from "@fluentui/react"
import  { useCallback, useMemo, useState } from "react"
import { useQuery } from "react-query"

export type Well  = {
    wellId?:                string;
    systemId?:              number;
    assetId?:               number;
    wellName?:              string;
    startTime?:             Date;
    endTime?:               Date;
    totalDepth?:            number;
    killDepth?:             number;
    productionTubingDepth?: number;
    apiNumber?:             string;
    bopCount?:              number;
    rigOverTime?:           number;
    rigDownTime?:           number;
    highPressure?:          boolean;
    formationType?:         string;
    jobDescription?:        string;
    fracPlugType?:          string;
    motor?:                 string;
    bit?:                   string;
}
type MakeWellProps = {
    Well?:Well
}
export function MakeWellForm(props:MakeWellProps){
    const { isLoading, error, data:jobs } = useQuery('jobs', () =>
        fetch('/api/ef/dws/jobs').then(res =>
        res.json()
        )
    )
  

    return(
        <div>
            <WellSelector></WellSelector>
            { jobs &&
            <Dropdown options={jobs.map(j=>({key:j.systemId,text:j.name}))}></Dropdown>}
        </div>
       
    )
   

}

function WellSelector() {
    const { data:wells } = useQuery('wells', () =>
        fetch('/node-red/api/wells').then(res =>
        res.json()
    ))

    let menuItems = wells.map(well=>({
        key:well.wellId,
        text:well.operator+well.pad + well.well
    }))
    const [items, setItems] = useState(menuItems);

    const onAbort = useCallback(() => {
      setItems(menuItems);
    }, []);
  
    const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
      const filteredItems = menuItems.filter(
        item => item.text && item.text.toLowerCase().indexOf(newValue.toLowerCase()) !== -1,
      );
  
      if (!filteredItems || !filteredItems.length) {
        filteredItems.push({
          key: 'no_results',
          onRender: (item, dismissMenu) => (
            <div key="no_results" style={filteredItemsStyle}>
              <Icon iconName="SearchIssue" title="No actions found" />
              <span>No actions found</span>
            </div>
          ),
        });
      }
  
      setItems(filteredItems);
    }, []);
  
    const renderMenuList = useCallback(
      (menuListProps: IContextualMenuListProps, defaultRender: IRenderFunction<IContextualMenuListProps>) => {
        return (
          <div>
            <div style={{ borderBottom: '1px solid #ccc' }}>
              <SearchBox
                ariaLabel="Filter actions by text"
                placeholder="Filter actions"
                onAbort={onAbort}
                onChange={onChange}
                styles={searchBoxStyles}
              />
            </div>
            {defaultRender(menuListProps)}
          </div>
        );
      },
      [onAbort, onChange],
    );
  
    const menuProps = useMemo(
        () => ({
          onRenderMenuList: renderMenuList,
          title: 'Actions',
          shouldFocusOnMount: true,
          items,
        }),
        [items, renderMenuList],
      );
    
     return ( <DefaultButton text="Click for ContextualMenu" menuProps={menuProps} />)
}

const filteredItemsStyle: React.CSSProperties = {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const searchBoxStyles: ISearchBoxStyles = {
    root: { margin: '8px' },
  };