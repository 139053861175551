import React from "react";
import {
	ActionButton,
	DetailsList,
	Separator,
	TextField,
	Checkbox,
	IconButton,
	PrimaryButton,
	DefaultButton,
	Stack,
	SelectionMode,
	Spinner,
} from "@fluentui/react/";
import SearchableDropdown from "../../../components/SearchableDropdown";

export default class SystemDefinitionConfiguration extends React.Component {
	state = { slots: [] };

	deleteSystemDefinition(id) {
		fetch(`/api/systemdefinitions/${id}`, { method: "delete" })
			.then((data) => data.json())
			.then((data) => {
				this.props.onSystemDefinitionUpdated();
			})
			.catch((err) => console.log(err));
	}
	deleteSlot(id) {
		fetch("/api/slots/" + id, {
			method: "DELETE",
		})
			.then((res) => res.json())
			.then((d) =>
				this.loadSystemDefinition(
					this.state.selectedSystemDefinition.systemDefinitionId
				)
			);
	}
	loadSystemDefinition(id) {
		if (id) {
			this.setState({ loadingSlots: true });
			fetch(`/api/ef/systemdefinitions/${id}`)
				.then((res) => res.json())
				.then((systemDefinition) => {
					console.log(systemDefinition);
					if (this.props.onSystemDefinitionUpdated) {
						this.props.onSystemDefinitionUpdated(systemDefinition);
					}
					this.setState({
						loadingSlots: false,
						...systemDefinition,
						changed: false,
						initialSlots: JSON.parse(
							JSON.stringify(systemDefinition.slots)
						),
					});
				});
		}
	}
	saveSlots() {
		fetch("/api/slots", {
			method: "POST",
			body: JSON.stringify(this.state.slots),
			headers: { "Content-Type": "application/json" },
		})
			.then((res) => res.json())
			.then((d) => {
				this.loadSystemDefinition(
					this.state.selectedSystemDefinition.systemDefinitionId
				);
			});
	}
	setSlotState(id, state) {
		var slots = [...this.state.slots];
		var slot = slots.find((s) => s.slotId == id);
		Object.assign(slot, state);
		this.setState({ slots });
	}
	saveSystemDefinition() {
		this.setState({ systemDefForm: false });
		fetch(`/api/ef/systemdefinitions`, {
			method: "post",
			body: JSON.stringify(this.state),
			headers: {
				"Content-Type": "application/json",
			},
		}).then(() => this.saveSlots());
	}
	render() {
		var slotColumns = [
			{
				key: "name",
				name: "Name",
				onRender: (item, i) => {
					return (
						<TextField
							value={this.state.slots[i].name}
							placeholder="Name"
							onChange={(e, val) => {
								this.setSlotState(item.slotId, { name: val });
							}}
						></TextField>
					);
				},
			},
			{
				key: "systemDefinition",
				name: "System Definition",
				onRender: (item, i) => {
					return (
						<SearchableDropdown
							options={
								this.props.systemDefinitions?.map((sd) => {
									return {
										key: sd.systemDefinitionId,
										text: sd.name,
									};
								}) ?? []
							}
							selectedKey={
								this.state.slots[i]?.systemDefinitionId ?? null
							}
							onChange={(e, val) => {
								console.log(e, val);
								this.setSlotState(item.slotId, {
									systemDefinitionId: val.key,
									assetDefinitionId: null,
								});
							}}
						></SearchableDropdown>
					);
				},
			},
			{
				key: "assetDefinition",
				name: "Asset Definition",
				onRender: (item, i) => {
					return (
						<SearchableDropdown
							options={this.props.assetDefinitions.map((ad) => {
								return {
									key: ad.assetDefinitionId,
									text: ad.assetDefinitionName,
								};
							})}
							selectedKey={this.state.slots[i].assetDefinitionId}
							onChange={(e, val) => {
								this.setSlotState(item.slotId, {
									assetDefinitionId: val.key,
									systemDefinitionId: null,
								});
							}}
						></SearchableDropdown>
					);
				},
			},
			{
				key: "critical",
				name: "Critical?",
				onRender: (item, i) => {
					return (
						<Checkbox
							value={this.state.slots[i].critical ?? false}
							onChange={(e, val) => {
								this.setSlotState(item.slotId, {
									critical: val,
								});
							}}
						></Checkbox>
					);
				},
			},
			{
				key: "exclusive",
				name: "Exclusive?",
				onRender: (item, i) => {
					return (
						<Checkbox
							value={this.state.slots[i].exclusive ?? false}
							onChange={(e, val) => {
								this.setSlotState(item.slotId, {
									exclusive: val,
								});
							}}
						></Checkbox>
					);
				},
			},
			{
				key: "delete",
				name: "Delete",
				onRender: (item, i) => {
					return (
						<IconButton
							iconProps={{ iconName: "Delete" }}
							onClick={(e, val) => {
								this.deleteSlot(item.slotId);
							}}
						></IconButton>
					);
				},
			},
		];
		return (
			<>
				<DetailsList
					compact
					selectionMode={SelectionMode.none}
					items={this.props.filteredSystemDefinitions}
					columns={[
						{
							fieldName: "name",
							name: "Name",
							key: "name",
						},
						{
							key: "edit",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "Edit",
										}}
										onClick={() => {
											this.loadSystemDefinition(
												item.systemDefinitionId
											);
											this.setState({
												systemDefForm: true,
												showNewSD: false,
												selectedSystemDefinition: item,
											});
										}}
									>
										Edit slots
									</ActionButton>
								);
							},
						},
						{
							key: "delete",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "Delete",
										}}
										onClick={() => {
											this.deleteSystemDefinition(
												item.systemDefinitionId
											);
										}}
									>
										Delete
									</ActionButton>
								);
							},
						},
					]}
				></DetailsList>
				<Separator></Separator>
				{this.state.showNewSD ? (
					<Stack
						horizontal
						tokens={{ childrenGap: 10 }}
						verticalAlign="end"
					>
						<TextField
							label="System Definition Name"
							value={this.state.newSystemDefName}
							onChange={(e, val) => {
								this.setState({
									newSystemDefName: val,
								});
							}}
						></TextField>
						<PrimaryButton
							iconProps={{
								iconName: "Plus",
							}}
							onClick={() => {
								fetch("/api/ef/systemdefinitions", {
									method: "post",
									headers: {
										"Content-Type": "application/json",
									},
									body: JSON.stringify({
										name: this.state.newSystemDefName,
										alias: this.state.newSystemDefName,
									}),
								})
									.then((res) => res.json())
									.then((sys) => {
										if (
											this.props.onSystemDefinitionUpdated
										) {
											this.props.onSystemDefinitionUpdated();
										}
									});
							}}
						>
							Add
						</PrimaryButton>
						<DefaultButton
							onClick={() => {
								this.setState({
									showNewSD: false,
								});
							}}
						>
							Cancel
						</DefaultButton>
					</Stack>
				) : (
					<Stack horizontal horizontalAlign="end">
						<ActionButton
							text="Add new system definition"
							iconProps={{
								iconName: "Add",
							}}
							onClick={() => {
								this.setState({
									systemDefForm: false,
									showNewSD: true,
								});
							}}
						></ActionButton>
					</Stack>
				)}
				{this.state.systemDefForm && (
					<Stack>
						<Stack verticalAlign="space-between">
							<TextField
								label="Name"
								value={this.state.name}
								onChange={(e, val) => {
									this.setState({ name: val, changed: true });
								}}
							></TextField>
							<TextField
								label="Alias"
								value={this.state.alias}
								onChange={(e, val) => {
									this.setState({
										alias: val,
										changed: true,
									});
								}}
							></TextField>
							<TextField
								label="Description"
								value={this.state.description}
								onChange={(e, val) => {
									this.setState({
										description: val,
										changed: true,
									});
								}}
							></TextField>
							<h3>Slots</h3>
							{this.state.loadingSlots ? (
								<Spinner></Spinner>
							) : (
								<DetailsList
									title="Slots"
									columns={slotColumns}
									items={this.state.slots}
								></DetailsList>
							)}
						</Stack>
						<Stack horizontal horizontalAlign="end">
							<ActionButton
								text="Add slot"
								iconProps={{ iconName: "Add" }}
								onClick={() => {
									this.setState(
										{
											slots: [
												...this.state.slots,
												{
													name: "New Slot",
													parentSystemDefinitionId: this
														.state
														.selectedSystemDefinition
														.systemDefinitionId,
												},
											],
										},
										() => {
											this.saveSlots();
										}
									);
								}}
							></ActionButton>
						</Stack>
						<Stack horizontal horizontalAlign="end">
							{(this.state.changed == true ||
								JSON.stringify(this.state.slots) !=
									JSON.stringify(
										this.state.initialSlots
									)) && (
								<PrimaryButton
									text="Save"
									onClick={() => {
										this.saveSystemDefinition();
									}}
								></PrimaryButton>
							)}
							<DefaultButton
								text="Cancel"
								onClick={() => {
									this.setState({ systemDefForm: false });
								}}
							></DefaultButton>
						</Stack>
					</Stack>
				)}
			</>
		);
	}
}
