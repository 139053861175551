import { getParameterByName } from "../util";
const defaultEndDate = getParameterByName("endDate")
	? new Date(parseFloat(getParameterByName("endDate")))
	: new Date();
const defaultStartDate = getParameterByName("startDate")
	? new Date(parseFloat(getParameterByName("startDate")))
	: null;
const playback = (
	state = {
		speed: 1,
		pause: false,
		startDate: defaultStartDate,
		endDate: defaultEndDate,
	},
	action
) => {
	switch (action?.type) {
		case "SET_START_DATE": {
			return { ...state, startDate: action.payload };
		}
		case "SET_END_DATE": {
			return { ...state, endDate: action.payload };
		}
		case "TOGGLE_PLAY_PAUSE":
			return {
				...state,
				playback: !state.pause,
			};

		default:
			return state;
	}
};
export default playback;
