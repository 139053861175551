import { Target } from "@fluentui/react-hooks";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
type HelpBubbleProps = {
    target:Target,
    headline:string,
    content:string
}
export function HelpBubble(props:HelpBubbleProps){

    let showHelpUi = useSelector((state:any)=>state.ui.showHelp ?? false)
    let [showHelp,setShowHelp] = useState<boolean>(showHelpUi)

    useEffect(()=>setShowHelp(showHelpUi),[showHelpUi])
    
    return (showHelp ? <TeachingBubble  
                hasCloseButton={true}
                target={props.target}
                onDismiss={()=>setShowHelp(false)}
                headline={props.headline}
                >
                {props.content}
    </TeachingBubble> : <></>)
}