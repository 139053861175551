import React from "react";
import { TextField } from "@fluentui/react";
class AlarmExpressionEditor extends React.Component {
	state = {
		search: "",
		expression: JSON.parse(this.props.expression ?? "{}").expression ?? " ",
		timeout: null,
	};
	componentDidMount() {
		this.validateExpression();
	}
	componentDidUpdate() {
		//this.setState({expression:JSON.parse(this.props.value??"{}").expression ?? "1=2 or 3=4"})
	}
	validateExpression() {
		var variables = [];

		for (var i = 0; i < this.props.dataItems.length; i++) {
			var di = this.props.dataItems[i];
			if (
				this.state.expression.includes(
					"_" + di.label.replace(/[\W_]+/g, "_")
				)
			) {
				variables.push({
					DataItemId: di.dataItemId,
					Variable: "_" + di.label.replace(/[\W_]+/g, "_"),
				});
			}
		}
		var expression = {
			variables: variables,
			expression: this.state.expression,
		};
		var requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(expression),
		};

		fetch(`/api/ef/validatefle`, requestOptions).then((response) => {
			this.setState({ validExpression: response.ok });
			this.props.onValidated(expression);
		});
	}
	thinkAboutValidating() {
		clearTimeout(this.state.timeout);
		this.setState({
			timeout: setTimeout(() => {
				this.validateExpression();
			}, 500),
		});
	}
	render() {
		return (
			<div>
				<TextField
					label="Filter"
					value={this.state.search}
					onChange={(e, val) => this.setState({ search: val })}
				/>
				<div style={{ overflowY: "auto", maxHeight: "300px" }}>
					{this.props.dataItems
						.filter((el) =>
							el.label
								.toLowerCase()
								.includes(this.state.search.toLowerCase())
						)
						.map((di) => {
							return (
								<p
									style={{
										borderRadius: 8,
										display: "inline-block",
										backgroundColor: "#444",
										padding: 5,

										margin: 2,
									}}
									key={di.dataItemId}
									draggable="true"
									onDragStart={(ev) => {
										ev.dataTransfer.setData(
											"text",
											"(_" +
												di.label.replace(
													/[\W_]+/g,
													"_"
												) +
												")"
										);
									}}
								>
									{di.label}
								</p>
							);
						})}
				</div>
				<p>Expression</p>
				<TextField
					defaultValue={this.props.expression}
					value={this.state.expression}
					multiline
					onDrop={(a, b, c) => {}}
					onChange={(e, val) => {
						if (val.length == 0) val = " ";
						this.setState({ expression: val, edited: true }, () => {
							this.thinkAboutValidating();
						});
					}}
					//onDragOver={(ev)=>{ev.preventDefault()}}
					style={{
						width: "100%",
						backgroundColor: this.state.validExpression
							? "#00FF000F"
							: "#FF00000F",
						color: "white",
					}}
					// onDrop={this.drop}
				></TextField>
			</div>
		);
	}
}
export default AlarmExpressionEditor;
