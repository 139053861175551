import {
	DefaultButton,
	PrimaryButton,
	Stack,
	Text,
	TextField,
} from "@fluentui/react";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import React from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../../actions";
import { getParameterByName } from "../../util";

class RegisterPage extends React.Component {
	state = {};
	componentDidMount() {
		if (this.props.auth !== false) {
			// this.props.history.push('/assets');
		}
	}
	redirectToHome = () => {
		const { history } = this.props;
		if (history) history.push("/login");
	};
	register() {
		var registration = {
			username: this.state.username,
			password: this.state.password,
			attributes: {
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				altEmail: this.state.altEmail,
				phone: this.state.phone,
			},
		};
		fetch("/api/ef/auth/register", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(registration),
		})
			.then((res) => {
				if (res.status == 409) {
					this.setState({ showAccountExistsMessage: true });
					return "User already exists";
				} else if (res.status == 200) {
					this.props.login(this.state.username, this.state.password);
					return "Cool it worked. did you get the email and text?";
				} else {
					return res.text();
				}
			})
			.then((text) => {
				console.log(text);
			});
	}

	render() {
		const dialogContentProps = {
			type: DialogType.normal,
			title: "Login Error",
			closeButtonAriaLabel: "Close",
			subText: this.state.loginErrorMessage,
		};
		return (
			<Stack
				style={{ padding: "30px" }}
				vertical
				tokens={{ childrenGap: 15 }}
			>
				<h1>Register for the portal</h1>
				<h2>
					You will still need to be assigned permissions and access
				</h2>
				<TextField
					required
					label="Email (this will be your username)"
					value={this.state.username}
					onChange={(e, val) => {
						this.setState({ username: val });
					}}
				></TextField>

				<TextField
					placeholder="First Name"
					label="First Name"
					value={this.state.firstName}
					onChange={(e, val) => {
						this.setState({ firstName: val });
					}}
				></TextField>
				<TextField
					placeholder="Last Name"
					label="Last Name"
					value={this.state.lastName}
					onChange={(e, val) => {
						this.setState({ lastName: val });
					}}
				></TextField>

				<TextField
					placeholder="Phone"
					label="Phone"
					value={this.state.phone}
					onChange={(e, val) => {
						this.setState({ phone: val });
					}}
				></TextField>
				<TextField
					valid
					label="Password"
					type="password"
					value={this.state.password}
					onChange={(e, val) => {
						var match = val == this.state.confirm;
						this.setState({ password: val, match: match });
					}}
				></TextField>
				<TextField
					color={"white"}
					label="Confirm Password"
					type="password"
					errorMessage={
						!this.state.match ? "Passwords do not match" : null
					}
					value={this.state.confirm}
					onChange={(e, val) => {
						var match = val == this.state.password;
						this.setState({ confirm: val, match: match });
					}}
				></TextField>
				{this.state.showAccountExistsMessage && (
					<Stack vertical>
						<Text color="red">
							This Account already exists. Would you like a
							password reset email?
						</Text>
						<Stack horizontal>
							<DefaultButton
								text="Reset Password"
								onClick={(e) => {
									fetch(
										`/api/auth/request/${this.state.username}`
									).then(() => {
										this.setState({ resetDialog: true });
									});
								}}
							/>
						</Stack>
					</Stack>
				)}
				<Stack horizontal>
					<PrimaryButton onClick={this.register.bind(this)}>
						Sign Up
					</PrimaryButton>
				</Stack>
				{this.state.resetDialog && (
					<Dialog
						hidden={!this.state.resetDialog}
						onDismiss={() => {
							this.setState({ resetDialog: false });
						}}
					>
						<p>
							A password reset link has been sent to you email or
							phone. Please check your quarantines and spam
							filters as well. You can request a new link from the
							login page with your email address.
						</p>
						<DialogFooter>
							<NavLink to={"/login"}>
								<PrimaryButton text="Return to login" />
							</NavLink>
						</DialogFooter>
					</Dialog>
				)}
				{(this.props.auth?.login == "valid" ||
					this.props.auth.valid) && (
					<Redirect to="/assets/"></Redirect>
				)}
			</Stack>
		);
	}
	resetPassword() {
		this.props.resetPassword(
			this.state.username,
			this.state.password,
			getParameterByName("changeId")
		);
		this.setState({ resetDialog: true });
	}
}
function mapStateToProps({ auth }) {
	return {
		auth: auth,
	};
}

export default compose(
	withRouter,
	connect(mapStateToProps, actions)
)(RegisterPage);
