import React from "react";

function TileType(props) {
	return (
		<div>
			<p style={{ textAlign: "center", margin: 0 }}>
				Either no type was selected or this tile type is deprecated,
				please delete and recreate this tile
			</p>
		</div>
	);
}

export default TileType;
