import { Checkbox, TextField } from "@fluentui/react";
import { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

export default function DataItemTableForm(props) {
	let tileProps = props.properties;
	console.log(
		tileProps?.showLabel,
		tileProps?.showUnit,
		tileProps?.showValue
	);
	return (
		<div>
			<Checkbox
				label="Show Label"
				checked={tileProps?.showLabel}
				onChange={(e, check) => {
					console.log(check);
					props.addData({ showLabel: check });
				}}
			></Checkbox>
			<Checkbox
				label="Show Unit"
				checked={tileProps?.showUnit}
				onChange={(e, check) => props.addData({ showUnit: check })}
			></Checkbox>
			<Checkbox
				label="Show Value"
				checked={tileProps?.showValue}
				onChange={(e, check) => props.addData({ showValue: check })}
			></Checkbox>
			<Checkbox
				label="Show page size"
				checked={tileProps?.showPageSize}
				onChange={(e, check) => props.addData({ showPageSize: check })}
			></Checkbox>
			<Checkbox
				label="Show search field"
				checked={tileProps?.showSearch}
				onChange={(e, check) => props.addData({ showSearch: check })}
			></Checkbox>
			<TextField
				label="Title"
				value={tileProps?.title}
				onChange={(e, val) => props.addData({ title: val })}
			></TextField>
			<TextField
				label="Search/filter"
				value={tileProps?.search}
				onChange={(e, val) => props.addData({ search: val })}
			></TextField>

			<TextField
				label="Page size"
				type="number"
				value={tileProps?.pageSize}
				onChange={(e, val) =>
					props.addData({ pageSize: parseFloat(val) })
				}
			></TextField>
		</div>
	);
}
