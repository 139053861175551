import {
	ActionButton,
	DetailsList,
	Panel,
	DefaultButton,
	PrimaryButton,
	Stack,
	Text,
	TextField,
} from "@fluentui/react";
import React, { Component } from "react";
import { getAssetIdFromSystem } from "../../../../../util";

class AISettings extends Component {
	state = {
		channels: channels.filter((item) => {
			return item.Variant[0]?.["_AI-Parms_"];
		}),
	};
	sendCommand(commands) {
		var fullCmds = commands.map((el) => {
			return {
				assetId: getAssetIdFromSystem(),
				commandDefinitionId: 0,
				command: el,
				expiration: 900,
			};
		});
		fetch(`/api/commands`, {
			method: "post",
			body: JSON.stringify(fullCmds),
			headers: { "Content-Type": "application/json" },
		});
	}
	setScaling(type, val) {
		var selectedChannel = { ...this.state.selectedChannel };
		selectedChannel.Variant[0]["_AI-Parms_"][type] = val;
		this.setState({ selectedChannel });
	}
	render() {
		let scaling = this.state.selectedChannel?.Variant[0]["_AI-Parms_"];
		return (
			<div>
				<DetailsList
					items={this.state.channels}
					compact
					columns={[
						{
							key: "channel",
							fieldName: "channel",
							name: "Channel",
						},
						{
							key: "value",
							fieldName: "Value",
							name: "Value",
						},
						{
							key: "alias",
							name: "Alias",
							onRender: (item) => {
								return (
									<TextField
										value={item.Variant[0].Alias}
									></TextField>
								);
							},
						},
						{
							key: "unit",
							name: "Unit",
							onRender: (item) => {
								return (
									<TextField
										value={item.Variant[0].Unit}
									></TextField>
								);
							},
						},
						{
							key: "zero",
							onRender: (item) => {
								return (
									<DefaultButton
										onClick={() => {
											var command = {
												module: "DAQRX",
												command: "Zero",
												parameter: {
													channel: item.channel,
												},
											};
											this.sendCommand([
												JSON.stringify(command),
											]);
										}}
									>
										<Stack
											horizontalAlign="space-between"
											tokens={{ childrenGap: 10 }}
											verticalAlign={"center"}
											horizontal
										>
											<Stack>
												<Text
													style={{ fontSize: "8px" }}
												>
													4mA
												</Text>
												<Text
													style={{ fontSize: "8px" }}
												>
													0V
												</Text>
											</Stack>
											<Text>Zero</Text>
										</Stack>
									</DefaultButton>
								);
							},
						},
						{
							key: "span",
							onRender: (item) => {
								return (
									<DefaultButton
										onClick={() => {
											var command = {
												module: "DAQRX",
												command: "Span",
												parameter: {
													channel: item.channel,
												},
											};
											this.sendCommand([
												JSON.stringify(command),
											]);
										}}
									>
										<Stack
											horizontalAlign="space-between"
											tokens={{ childrenGap: 10 }}
											verticalAlign={"center"}
											horizontal
										>
											<Stack>
												<Text
													style={{ fontSize: "8px" }}
												>
													20mA
												</Text>
												<Text
													style={{ fontSize: "8px" }}
												>
													5V
												</Text>
											</Stack>
											<Text>Span</Text>
										</Stack>
									</DefaultButton>
								);
							},
						},
						{
							key: "scaling",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{ iconName: "More" }}
										onClick={() => {
											this.setState({
												selectedChannel: item,
											});
										}}
									>
										Scaling
									</ActionButton>
								);
							},
						},
					]}
				></DetailsList>
				{scaling && (
					<Panel
						isLightDismiss={true}
						isOpen={scaling}
						onDismiss={() => {
							this.setState({ selectedChannel: null });
						}}
					>
						<TextField
							label="Unscaled Min"
							value={scaling["Unscaled_Min"]}
							onChange={(e, val) => {
								this.setScaling("Unscaled_Min", val);
							}}
						></TextField>
						<TextField
							label="Unscaled Max"
							value={scaling["Unscaled_Max"]}
							onChange={(e, val) => {
								this.setScaling("Unscaled_Max", val);
							}}
						></TextField>
						<TextField
							label="Scaled Min"
							value={scaling["Scaled_Min"]}
							onChange={(e, val) => {
								this.setScaling("Scaled_Min", val);
							}}
						></TextField>
						<TextField
							label="Scaled Max"
							value={scaling["Scaled_Max"]}
							onChange={(e, val) => {
								this.setScaling("Scaled_Max", val);
							}}
						></TextField>
						<TextField
							label="Offset"
							value={scaling["Offset"]}
							onChange={(e, val) => {
								this.setScaling("Offset", val);
							}}
						></TextField>
						<TextField
							label="Samples to average"
							value={scaling["Avg_Samples"]}
							onChange={(e, val) => {
								this.setScaling("Avg_Samples", val);
							}}
						></TextField>
						<Stack horizontal>
							<PrimaryButton
								onClick={() => {
									var commands = Object.keys(scaling).map(
										(el) => {
											return JSON.stringify({
												module: "DAQRX",
												command: el,
												parameter: {
													channel:
														this.state
															.selectedChannel
															.channel,
													value: scaling[el],
												},
											});
										}
									);
									this.sendCommand(commands);
								}}
							>
								Apply
							</PrimaryButton>
						</Stack>
					</Panel>
				)}
			</div>
		);
	}
}

const channels = [
	{
		channel: "raw_AI01",
		Value: 8.94546508789062e-4,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI01", Type: "AI", Unit: "V" }],
	},
	{
		channel: "raw_AI02",
		Value: -6.67572021484375e-5,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI02", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI03",
		Value: -6.67572021484375e-5,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI03", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI04",
		Value: -3.87191772460938e-4,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI04", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI05",
		Value: -3.87191772460938e-4,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI05", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI06",
		Value: -3.87191772460938e-4,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI06", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI07",
		Value: -0.354948043823242,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI07", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI08",
		Value: 0.292037963867188,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI08", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI09",
		Value: 0.308372497558594,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI09", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI10",
		Value: 0.302606582641602,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI10", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI11",
		Value: 0.32887077331543,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI11", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI12",
		Value: 0.316379547119141,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI12", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI13",
		Value: 0.333354949951172,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI13", Type: "AI", Unit: "V" }],
	},
	{
		channel: "raw_AI14",
		Value: 0.32887077331543,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI14", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "raw_AI15",
		Value: 0.347127914428711,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [{ Alias: "raw_AI15", Type: "AI", Unit: "ma" }],
	},
	{
		channel: "scaled_AI01",
		Value: 56,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Suct PSI3342323",
				Type: "AI",
				Unit: "PSI",
				"_AI-Parms_": {
					Unscaled_Min: 76,
					Unscaled_Max: 2,
					Scaled_Min: 0,
					Scaled_Max: 56,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI02",
		Value: 0,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Water Tank 1 Level",
				Type: "AI",
				Unit: "FT",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI03",
		Value: 0,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Water Tank 2 Level",
				Type: "AI",
				Unit: "FT",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI04",
		Value: 0,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Discharge PSI",
				Type: "AI",
				Unit: "PSI",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI05",
		Value: 0,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Chem 1 Level",
				Type: "AI",
				Unit: "FT",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI06",
		Value: 0,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Fuel Level",
				Type: "AI",
				Unit: "Ga",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI07",
		Value: 0,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Remote 1 PSI",
				Type: "AI",
				Unit: "PSI",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI08",
		Value: 0.29150390625,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Remote 2 PSI",
				Type: "AI",
				Unit: "PSI",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI09",
		Value: 0.3076171875,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Remote 3 PSI",
				Type: "AI",
				Unit: "PSI",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI10",
		Value: 0.302734375,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Remote 4 PSI",
				Type: "AI",
				Unit: "PSI",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI11",
		Value: 0.32861328125,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Hydraulic Temp",
				Type: "AI",
				Unit: "F",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI12",
		Value: 0.31591796875,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Lube Temp",
				Type: "AI",
				Unit: "F",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI13",
		Value: 0.3330078125,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Lube PSI",
				Type: "AI",
				Unit: "PSI",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI14",
		Value: 0.32861328125,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "H2S Level",
				Type: "AI",
				Unit: "FT",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
	{
		channel: "scaled_AI15",
		Value: 0.3466796875,
		"Time Stamp": "2020-12-02T20:17:34.044Z",
		Variant: [
			{
				Alias: "Chem 2 Level",
				Type: "AI",
				Unit: "FT",
				"_AI-Parms_": {
					Unscaled_Min: 0,
					Unscaled_Max: 5,
					Scaled_Min: 0,
					Scaled_Max: 5,
					Offset: 0,
					Avg_Samples: 1,
				},
			},
		],
	},
];

export default AISettings;
