import * as actions from "../../actions/index";
import {
	ActionButton,
	Breadcrumb,
	DefaultButton,
	Dropdown,
	Icon,
	Modal,
	PrimaryButton,
	Separator,
	Stack,
	Text,
	TextField,
} from "@fluentui/react";
import * as Papa from "papaparse";
import * as React from "react";
import { connect } from "react-redux";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import JsonForm from "../../components/JsonForm/JsonForm";
import { Counties } from "./Counties";
import UPlotComponent from "../dashboard/components/visuals/uPlot/uPlot";
import { projection } from "./Projections";
import { equipmentForm, jobInfo, statesApi, technicalForm } from "./MakeWellConsts";
class JobProvisioner extends React.Component {
	componentDidMount() {
		if (this.props.assets.length == 0) {
			this.props.loadAssets(true);
		}
		this.setState({ assets: this.props.assets }, () =>
			this.setSelectedAsset()
		);
		fetch("/config/customers.json")
			.then((r) => r.json())
			.then((customers) => this.setState({ customers }));
	}
	componentWillReceiveProps(nextProps) {
		this.setState({ assets: nextProps.assets }, () =>
			this.setSelectedAsset()
		);
	}
	state = {
		step: 2,
	};

	renderStep(step) {
		switch (step) {
			case 1:
				return <></>;
			case 2:
				return (
					<Stack>
						<JsonForm
							onFormUpdated={(val) =>
								this.setState({ jobDetails: val })
							}
							values={this.state.jobDetails}
							schema={this.customerForm()}
						></JsonForm>
						<Stack horizontal>
							{/* <DefaultButton onClick={()=>{this.setState({step:1})}}>Back</DefaultButton> */}
							<PrimaryButton
								onClick={() => {
									this.setState({ step: 3 });
								}}
							>
								Continue
							</PrimaryButton>
						</Stack>
					</Stack>
				);
			case 3:
				return (
					<Stack>
						<JsonForm
							onFormUpdated={(val) =>
								this.setState({ jobDetails: val })
							}
							values={this.state.jobDetails}
							schema={jobInfo}
						></JsonForm>
						<Stack horizontal>
							<DefaultButton
								onClick={() => {
									this.setState({ step: 2 });
								}}
							>
								Back
							</DefaultButton>
							<PrimaryButton
								onClick={() => {
									this.setState({ step: 4 });
								}}
							>
								Continue
							</PrimaryButton>
						</Stack>
					</Stack>
				);
			case 4:
				return (
					<Stack>
						<JsonForm
							onFormUpdated={(val) =>
								this.setState({ jobDetails: val })
							}
							values={this.state.jobDetails}
							schema={technicalForm}
						></JsonForm>
						<TextField
							label="Projection CSV"
							type="file"
							onChange={(e, val) => {
								const reader = new FileReader();
								reader.onload = (evt) => {
									var csv = Papa.parse(evt.target.result, {
										skipEmptyLines: true,
									});
									console.log(csv);
									var labels = csv.data[0].slice(1);
									var projections = csv.data[0]
										.slice(1)
										.map((label) => {
											var labelIndex =
												csv.data[0].indexOf(label);
											return {
												label,
												data: [
													csv.data
														.slice(1)
														.map((row, rowi) =>
															parseFloat(
																csv.data[
																	rowi + 1
																][0]
															)
														),
													csv.data
														.slice(1)
														.map((row, rowi) =>
															parseFloat(
																csv.data[
																	rowi + 1
																][labelIndex]
															)
														),
												],
											};
										});
									this.setState({ projections });
									console.log(projections);
								};
								reader.onerror = function (evt) {
									console.log("error reading file");
								};
								reader.readAsText(e.target.files[0]);
							}}
						></TextField>
						<ActionButton
							text="Download projection template"
							iconProps={{ iconName: "download" }}
							onClick={() => {
								const element = document.createElement("a");
								const file = new Blob([projection], {
									type: "text/csv",
								});
								element.href = URL.createObjectURL(file);
								element.download = "projection.csv";
								document.body.appendChild(element); // Required for this to work in FireFox
								element.click();
							}}
						></ActionButton>
						{this.state.projections && (
							<div
								style={{
									position: "relative",
									width: "100%",
									height: 400,
								}}
							>
								<Stack horizontal>
									{this.state.projections?.map((el) => {
										return (
											<div
												style={{
													position: "relative",
													width:
														100 /
															this.state
																.projections
																.length +
														"%",
													height: 300,
												}}
											>
												<UPlotComponent
													data={el.data}
													opts={{
														title: el.label,
														class: "spark",
														pxAlign: false,
														cursor: {
															show: false,
														},
														select: {
															show: false,
														},
														legend: {
															show: false,
														},
														scales: {
															x: {
																time: false,
																dir: -1,
																ori: 1,
															},
															y: {
																dir: 1,
																ori: 0,
															},
														},
														cursor: {
															lock: true,
															drag: {
																x: true,
																y: true,
															},
															sync: {
																key: 1,
																scales: [
																	"x",
																	"y",
																],
															},
														},
														legend: { show: true },
														axes: [
															{
																side: 3,
																stroke: "white",
																label: "Depth",
																//	show: false,
															},
															{
																stroke: "white",
																side: 2,
																label: el.label,
																//	show: false,
															},
														],
														series: [
															{
																label: "Depth (ft)",
															},
															{
																label: el.label,
																stroke: "#03a9f4",
															},
														],
													}}
												></UPlotComponent>
											</div>
										);
									})}
								</Stack>
							</div>
						)}

						<Stack horizontal>
							<DefaultButton
								onClick={() => {
									this.setState({ step: 3 });
								}}
							>
								Back
							</DefaultButton>
							<PrimaryButton
								onClick={() => {
									this.setState({ step: 5 });
								}}
							>
								Continue
							</PrimaryButton>
						</Stack>
					</Stack>
				);
			case 5:
				return (
					<Stack>
						<JsonForm
							onFormUpdated={(val) =>
								this.setState({ jobDetails: val })
							}
							values={this.state.jobDetails}
							schema={equipmentForm}
						></JsonForm>
						<Stack horizontal>
							<DefaultButton
								onClick={() => {
									this.setState({ step: 4 });
								}}
							>
								Back
							</DefaultButton>
							<PrimaryButton
								onClick={() => {
									this.setState({ step: 6 });
								}}
							>
								Continue
							</PrimaryButton>
						</Stack>
					</Stack>
				);
			case 6:
				var newJobId = `${this.state.jobDetails["Oil Company"]} - ${this.state.jobDetails["Pad"]} - ${this.state.jobDetails["Well"]}`;
				var oldJobId = `${this.state.initJobDetails["Oil Company"]} - ${this.state.initJobDetails["Pad"]} - ${this.state.initJobDetails["Well"]}`;
				var oldJobAsset = this.props.assets.find(
					(ass) =>
						ass.serialNumber.toLowerCase() == oldJobId.toLowerCase()
				);
				var newJobAsset = this.props.assets.find(
					(ass) =>
						ass.serialNumber.toLowerCase() == newJobId.toLowerCase()
				);
				console.log(newJobAsset);
				return (
					<Stack>
						<table>
							<thead>
								<tr>
									<th></th>
									<th>
										<h3>Previous job</h3>
									</th>
									<th>
										<h3>New job</h3>
									</th>
								</tr>
							</thead>
							<tbody>
								{this.formSchema().map((el) => {
									var newv = this.state.jobDetails[el.key];
									var prev =
										this.state.initJobDetails[el.key];
									return el.key ? (
										<tr
											style={{
												backgroundColor:
													prev == newv
														? "transparent"
														: "#ffff0055",
											}}
										>
											<td>{el.key}</td>
											<td>{prev}</td>
											<td>{newv}</td>
										</tr>
									) : (
										<></>
									);
								})}
							</tbody>
						</table>
						<Stack horizontal>
							<PrimaryButton
								onClick={() => {
									var attributes = Object.keys(
										this.state.jobDetails
									).map((key) => {
										return {
											attributeName: key,
											attributeValue:
												this.state.jobDetails[key],
										};
									});
									attributes.push({
										attributeName: "JobSerialNumber",
										attributeValue: newJobId,
									});
									attributes.push({
										attributeName: "JobAssetDefinition",
										attributeValue: "HCU-LIME",
									});
									this.state.projections?.forEach((proj) => {
										attributes.push({
											attributeName:
												"Projection-" + proj.label,
											attributeValue: JSON.stringify(
												proj.data
											),
										});
									});
									let newWell = {
										assetId:this.state.asset.assetId,
										wellName:this.state.jobDetails["Well"],
										startTime:this.state.jobDetails["Job start time"],
										endTime:this.state.jobDetails["Job end time"],
										totalDepth:this.state.jobDetails["Total Depth (ft)"],
										killDepth:this.state.jobDetails["Kill Depth (ft)"],
										productionTubingDepth:this.state.jobDetails["Prod. Tubing Depth (ft)"],
										apiNumber:this.state.jobDetails["API Number"],
										bopCount:this.state.jobDetails["BOP Count"],
										plugCount:this.state.jobDetails["Plug Count"],
										rigUpTime:this.state.jobDetails["Rig Up Time"],
										rigOverTime:this.state.jobDetails["Rig Over Time"],
										rigDownTime:this.state.jobDetails["Rig Down Time"],
										bottomHoleCirculationTime:this.state.jobDetails["Bottom Hole Circulation Time"],
										highPressure:this.state.jobDetails["High Pressure"],
										formationType:this.state.jobDetails["Formation Type"],
										jobDescription:this.state.jobDetails["Job Description"],
										fracPlugType:this.state.jobDetails["Frac Plug Type"],
										motor:this.state.jobDetails["Motor"],
										bit:this.state.jobDetails["Bit"]
									}
									Promise.all([
										// fetch(
										// 	`/api/serialNumber/HCU-LIME!${newJobId}/attributes`,
										// 	{
										// 		method: "POST",
										// 		body: JSON.stringify(
										// 			attributes
										// 		),
										// 	}
										// ),
										fetch("/api/ef/dws/wells",
										{
											method:"post",
											headers:{"Content-Type":"application/json"},
											body:JSON.stringify([newWell])
										}),
										fetch(
											`/api/serialNumber/${this.state.asset.serialNumber}/attributes`,
											{
												method: "POST",
												body: JSON.stringify(
													attributes
												),
											}
										),
									]).then((res) =>{
										this.setState({finishModal:true})
										//this.props.loadAssets(false)
									}

									);
								}}
							>
								Save job '{newJobId}'
							</PrimaryButton>

							{newJobAsset && (
								<Link
									to={`/assets/${newJobAsset.assetId}/dashboard`}
								></Link>
							)}
						</Stack>
					</Stack>
				);
		}
	}
	customerForm() {
		return [
			{
				key: "State",
				type: "enum",
				label: "State",
				options: statesApi,
			},
			{
				key: "County",
				type: "enum",
				label: "County",
				options: Counties.filter(
					(c) => c.st == this?.state?.jobDetails?.["State"]
				),
			},
			{
				key: "Oil Company",
				required: true,
				type: "enum",
				label: "Oil Company",
				options: this.state.customers?.map((el) => {
					return { key: el, text: el };
				}),
			},
			{
				required: true,
				key: "Pad",
				type: "text",
				label: "Pad",
				placeholder: "Pad",
			},
			{
				required: true,
				key: "Well",
				type: "text",
				label: "Well",
				placeholder: "Well",
			},
			{
				required: true,
				key: "API Number",
				type: "number",
				label: "API Number",
				placeholder: "XX-",
			},
		];
	}
	formSchema() {
		return [
			...this.customerForm(),
			...jobInfo,
			...technicalForm,
			...equipmentForm,
		];
	}
	setSelectedAsset(assetId) {
		if (!assetId) assetId = this.state.asset?.assetId;
		var values = {};
		var asset = this.props.assets.find((ass) => ass.assetId == assetId);
		if (asset) {
			asset.attributes?.forEach((att) => {
				if (
					this.formSchema()
						.map((el) => el.key)
						.indexOf(att.attributeName) >= 0
				) {
					values[att.attributeName] = att.attributeValue;
				}
			});
			this.setState({
				asset: asset,
				jobDetails: values,
				initJobDetails: values,
			});
		}
	}
	render() {
		return (
			<Stack style={{ margin: 10 }}>
				<Modal isOpen={this.state.finishModal}>
					<Stack style={{padding:30}} tokens={{childrenGap:10}}>
				    <Stack horizontal horizontalAlign="end">
						<Icon iconName="Cancel" onClick={()=>{this.setState({finishModal:false})}}/>
					</Stack>
					<p>Job configuration has been saved for this HCU.</p>
					<Stack horizontal tokens={{childrenGap:10}}>
						<Link to={`/assets/${this.state.asset?.assetId}/dashboard`}>
						  <PrimaryButton text={`Go to ${this.state.asset?.assetAlias} dashboard`}></PrimaryButton>
						</Link>
						
						<DefaultButton text="Return to Make Well" onClick={()=>{this.setState({finishModal:false,step:2})}}></DefaultButton>
					</Stack>
					</Stack>
				</Modal>
				<Stack>
					<Dropdown
						label="Snubbing Unit"
						options={this.state?.assets
							?.filter(
								(el) =>
									
									el.assetDefinitionId == 76192 && el.assetAlias.includes("HCU")
							)
							.map((el) => {
								return { key: el.assetId, text: el.assetAlias };
							})}
						onChange={(e, val) => {
							this.setSelectedAsset(val.key);
						}}
					></Dropdown>
					{/* <Stack horizontal><PrimaryButton onClick={()=>{this.setState({step:2})}}>Continue</PrimaryButton></Stack> */}
					<Stack horizontal verticalAlign="center">
						{[
							//{text:this.state.asset?`Select unit (${this.state.asset.assetAlias ?? ''})`:'Select Unit',isCurrentItem:true,onClick:()=>{this.setState({step:1})}},
							{
								text: "Customer info",
								onClick: () => {
									this.setState({ step: 2 });
								},
							},
							{
								text: "Job info",
								onClick: () => {
									this.setState({ step: 3 });
								},
							},
							{
								text: "Technical info",
								onClick: () => {
									this.setState({ step: 4 });
								},
							},
							{
								text: "Equipment",
								onClick: () => {
									this.setState({ step: 5 });
								},
							},
							{
								text: "Confirm",
								onClick: () => {
									this.setState({ step: 6 });
								},
							},
						].map((el, i, arr) => {
							return (
								<>
									<ActionButton
										onClick={() => {
											el.onClick();
										}}
									>
										<h2>{el.text}</h2>
									</ActionButton>
									{i != arr.length - 1 ? (
										<h2>{">"}</h2>
									) : (
										<></>
									)}
								</>
							);
						})}
					</Stack>
				</Stack>
				{this.renderStep(this.state.step)}
			</Stack>
		);
	}
}

function mstp({ assets }) {
	return { assets };
}
export default connect(mstp, actions)(JobProvisioner);

