const ui = (
	state = {
		layout: null,
		showHelp:false,
		filePanel: false,
		navBar: true,
		dashboard: {},
		dashboards: [],
		sidebar: false,
		enlargedTile: null,
		modal: {
			visible: false,
			tile: null,
		},
		assetEditPanel: {
			asset: null,
			show: false,
		},
		alarmsModal: false,
		showPlayback: false,
		exportModal: false,
		viewOnly: true,
		maintenanceModal: false,
		dashboardWidth:"100%"
	},
	action
) => {
	switch (action.type) {
		case "TOGGLE_SIDEBAR":
			const sidebar = !state.sidebar;
			return {
				...state,
				sidebar,
			};
		case "DASHBOARDS":
			return {
				...state,
				dashboards: action.payload,
			};
		case "DASHBOARD":
			return {
				...state,
				dashboard: action.payload,
			};
		case "UPDATE_NAME":
			state.dashboard.name = action.payload;
			return {
				...state,
				dashboard: {
					...state.dashboard,
					dashboardName: action.payload,
				},
			};

		case "TOGGLE_MODAL":
			return {
				...state,
				modal: {
					visible: true,
					tile: action.tile,
				},
			};

		case "RESET_MODAL": {
			return {
				...state,
				modal: {
					visible: false,
					tile: null,
				},
			};
		}
		case "LAYOUT": {
			return {
				...state,
				layout: action.payload,
			};
		}

		case "EDIT_TILE": {
			// action has tile
			return {
				...state,
				modal: {
					...state.modal,
					tile: action.tile,
				},
			};
		}
		case "TOGGLE_VIEWONLY": {
			return {
				...state,
				viewOnly: !state.viewOnly,
				dashboardWidth:"100%"
			};
		}
		case "SET_VIEWONLY": {
			return {
				...state,
				viewOnly: true,
			};
		}
		case "UNSET_VIEWONLY": {
			return {
				...state,
				viewOnly: false,
			};
		}
		case "HIDE_NAV": {
			return {
				...state,
				navBar: false,
			};
		}
		case "SHOW_NAV": {
			return {
				...state,
				navBar: true,
			};
		}
		case "HIDE_FILE_PANEL": {
			return {
				...state,
				filePanel: false,
			};
		}
		case "SHOW_FILE_PANEL": {
			return {
				...state,
				filePanel: true,
			};
		}
		case "SYSTEM_UPDATED": {
			var x = { ...state };
			x.assetEditPanel.assetId = action.payload.assetId;
			return x;
		}
		case "SHOW_ASSET_PANEL": {
			if (action.payload) {
				return {
					...state,
					assetEditPanel: {
						show: true,
						assetId: action.payload,
					},
				};
			} else {
				var x = { ...state };
				x.assetEditPanel.show = true;
				return x;
			}
		}
		case "HIDE_ASSET_PANEL": {
			var x = { ...state };
			x.assetEditPanel.show = false;
			return x;
		}

		case "TOGGLE_ALARMS": {
			return {
				...state,
				alarmsModal: !state.alarmsModal,
			};
		}
		case "TOGGLE_MAINTENANCE": {
			return {
				...state,
				maintenanceModal: !state.maintenanceModal,
			};
		}
		case "TOGGLE_EXPORT": {
			return {
				...state,
				exportModal: !state.exportModal,
			};
		}
		case "TOGGLE_PLAYBACK_BAR":
			return { ...state, showPlayback: !state.showPlayback };
		case "SHOW_PLAYBACK_BAR":
			return { ...state, showPlayback: true };
		case "HIDE_PLAYBACK_BAR":
			return { ...state, showPlayback:false };
		case "TOGGLE_HELP":
			return { ...state, showHelp: !state.showHelp };
		case "SET_DASHBOARD_WIDTH":
		return { ...state, dashboardWidth: action.payload};

		default:
			return state;
	}
};

export default ui;
