export const jobDescriptions = [
	"Cement Drillout",
	"Cleanout",
	"Drill Out",
	"Drill Out - Tubing Install",
	"Fishing",
	"Hybrid Drill Out",
	"Other",
	"Tubing Install",
	"Tubing Pull",
];
export const states = [
	"Alabama",
	"Alaska",
	"American Samoa",
	"Arizona",
	"Arkansas",
	"California",
	"Colorado",
	"Connecticut",
	"Delaware",
	"District of Columbia",
	"Florida",
	"Georgia",
	"Guam",
	"Hawaii",
	"Idaho",
	"Illinois",
	"Indiana",
	"Iowa",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Maine",
	"Maryland",
	"Massachusetts",
	"Michigan",
	"Minnesota",
	"Minor Outlying Islands",
	"Mississippi",
	"Missouri",
	"Montana",
	"Nebraska",
	"Nevada",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"New York",
	"North Carolina",
	"North Dakota",
	"Northern Mariana Islands",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Puerto Rico",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"U.S. Virgin Islands",
	"Utah",
	"Vermont",
	"Virginia",
	"Washington",
	"West Virginia",
	"Wisconsin",
	"Wyoming",
];

export const statesApi = [
	{ key: "1", text: "ALABAMA" },
	{ key: "2", text: "ARIZONA" },
	{ key: "3", text: "ARKANSAS" },
	{ key: "4", text: "CALIFORNIA" },
	{ key: "5", text: "COLORADO" },
	{ key: "6", text: "CONNECTICUT" },
	{ key: "7", text: "DELAWARE" },
	{ key: "8", text: "DISTRICT OF COLUMBIA" },
	{ key: "9", text: "FLORIDA" },
	{ key: "10", text: "GEORGIA" },
	{ key: "11", text: "IDAHO" },
	{ key: "12", text: "ILLINOIS" },
	{ key: "13", text: "INDIANA" },
	{ key: "14", text: "IOWA" },
	{ key: "15", text: "KANSAS" },
	{ key: "16", text: "KENTUCKY" },
	{ key: "17", text: "LOUISIANA" },
	{ key: "18", text: "MAINE" },
	{ key: "19", text: "MARYLAND" },
	{ key: "20", text: "MASSACHUSETTS" },
	{ key: "21", text: "MICHIGAN" },
	{ key: "22", text: "MINNESOTA" },
	{ key: "23", text: "MISSISSIPPI" },
	{ key: "24", text: "MISSOURI" },
	{ key: "25", text: "MONTANA" },
	{ key: "26", text: "NEBRASKA" },
	{ key: "27", text: "NEVADA" },
	{ key: "28", text: "NEW HAMPSHIRE" },
	{ key: "29", text: "NEW JERSEY" },
	{ key: "30", text: "NEW MEXICO" },
	{ key: "31", text: "NEW YORK" },
	{ key: "32", text: "NORTH CAROLINA" },
	{ key: "33", text: "NORTH DAKOTA" },
	{ key: "34", text: "OHIO" },
	{ key: "35", text: "OKLAHOMA" },
	{ key: "36", text: "OREGON" },
	{ key: "37", text: "PENNSYLVANIA" },
	{ key: "38", text: "RHODE ISLAND" },
	{ key: "39", text: "SOUTH CAROLINA" },
	{ key: "40", text: "SOUTH DAKOTA" },
	{ key: "41", text: "TENNESSEE" },
	{ key: "42", text: "TEXAS" },
	{ key: "43", text: "UTAH" },
	{ key: "44", text: "VERMONT" },
	{ key: "45", text: "VIRGINIA" },
	{ key: "46", text: "WASHINGTON" },
	{ key: "47", text: "WEST VIRGINIA" },
	{ key: "48", text: "WISCONSIN" },
	{ key: "49", text: "WYOMING" },
	{ key: "50", text: "ALASKA" },
	{ key: "51", text: "HAWAII" },
	{ key: "55", text: "ALASKA OFFSHORE" },
	{ key: "56", text: "PACIFIC COAST OFFSHORE" },
	{ key: "60", text: "NORTHERN GULF OF MEXICO" },
	{ key: "61", text: "ATLANTIC COAST OFFSHORE" },
];
export const plugs = [
	"Magnum",
	"Magnum Ace",
	"FraXion",
	"Fas Drill",
	"Obsidian",
	"Other",
	"EZ Drill",
	"Diamondback",
	"Copperhead",
	"Boss Hog",
	"RzrFRAC",
	"Scorpion",
	"Snub Nose",
	"Team",
];
export const motors = ["BICO", "DRILEX", "NOV", "Other"];
export const bits = [
	"Varel Slipstream Tri Cone",
	"JZ - Tri Cone",
	"PDC - Mill",
	"PDC - One Trip",
	"PlugBuster - Mill",
	"Reed",
	"Smith - Tri Cone",
	"Valco",
	"Other",
];
export const formations = [
	"Big Lime",
	"Berea",
	"Gordon Stray",
	"DJ",
	"Clinton",
	"Marcellus",
	"Oriskany",
	"Permian",
	"Utica",
	"Other",
];
export const jobInfo = [
	{
		required: true,
		key: "Job start time",
		type: "datetime",
		label: "Job start time",
		horizontal: true,
		datetime: new Date(),
	},
	{
		key: "Rig Up Time",
		type: "number",
		suffix: "hours",
		label: "Rig Up Time",
	},
	{
		key: "Rig Over Time",
		type: "number",
		suffix: "hours",
		label: "Rig Over Time",
	},
	{
		key: "BOP Count",
		type: "number",
		label: "BOP Count",
	},
	{
		key: "Plug Count",
		type: "number",
		label: "Plug Count",
	},

	{
		key: "High Pressure",
		type: "boolean",
		label: "High Pressure?",
	},
	{
		key: "Circulation Time",
		type: "number",
		suffix: "hours",
		label: "Circulation Time",
	},
	{
		key: "Bottom Hole Circulation Time",
		type: "number",
		suffix: "hours",
		label: "Bottom Hole Circulation Time",
	},

	{
		key: "Rig Down Time",
		type: "number",
		suffix: "hours",
		label: "Rig Down Time",
	},
	{
		required: true,
		key: "Job end time",
		type: "datetime",
		label: "Job end time",
		horizontal: true,
		datetime: new Date(),
	},
];
export const technicalForm = [
	{
		key: "Formation Type",
		type: "enum",
		label: "Formation Type",
		options: formations.map((el) => {
			return { key: el, text: el };
		}),
	},
	{
		key: "Job Description",
		type: "enum",
		label: "Job Description",
		options: jobDescriptions.map((el) => {
			return { key: el, text: el };
		}),
	},
	{
		key: "Kill Depth (ft)",
		type: "number",
		label: "Kill Depth (ft)",
	},
	{
		key: "Prod. Tubing Depth (ft)",
		type: "number",
		label: "Prod. Tubing Depth (ft)",
	},
	{
		key: "Total Depth (ft)",
		type: "number",
		label: "Total Depth (ft)",
	},
];
export const equipmentForm = [
	{
		key: "Rig Rating",
		type: "enum",
		label: "Rig Rating",
		options: [
			{ key: "285,000 lbs", text: "285,000 lbs" },
			{ key: "300,000 lbs", text: "300,000 lbs" },
		],
	},

	{
		key: "Frac Plug Type",
		type: "enum",
		label: "Frac Plug Type",
		options: plugs.map((el) => {
			return { key: el, text: el };
		}),
	},
	{
		key: "Motor",
		type: "enum",
		label: "Motor",
		options: motors.map((el) => {
			return { key: el, text: el };
		}),
	},
	{
		key: "Bit",
		type: "enum",
		label: "Bit",
		options: bits.map((el) => {
			return { key: el, text: el };
		}),
	},
];
