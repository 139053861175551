import {
	Checkbox,
	ChoiceGroup,
	createTheme,
	loadTheme,
	PrimaryButton,
	Stack,
	TextField,
} from "@fluentui/react";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions/index";
import { httpErrorHandler } from "../util";
class UserConfiguration extends React.Component {
	state = {
		...this.props.user?.attributes,
		user: this.props.user,
		extraLinks: JSON.parse(this.props.user?.attributes.extraLinks ?? "[]"),
	};
	componentDidMount() {
		//this.loadUserAttributes()
	}
	loadUserAttributes() {
		fetch(`/api/ef/users/${this.props.user?.userId}/`)
			.then(async (response) => {
				if (response.status == 401) {
					const { history } = this.props;
					if (history) history.push("/login");
					return;
				} else if (response.status == 200) return response.json();
			})
			.then((json) => this.setState({ user: json }))
			.catch(httpErrorHandler);
	}
	addLink() {
		var links = [...this.state.extraLinks];
		links.push({
			link: this.state.newLink,
			label: this.state.newLinkLabel,
		});
		this.setState({ extraLinks: links });
	}
	deleteLink(i) {
		var links = [...this.state.extraLinks];
		links.splice(i, 1);
		this.setState({ extraLinks: links });
	}
	saveSettings() {
		var attributes = [
			{
				userId: this.state.user?.userId,
				attributeName: "firstName",
				attributeValue: this.state.firstName,
			},
			{
				userId: this.state.user?.userId,
				attributeName: "lastName",
				attributeValue: this.state.lastName,
			},
			{
				userId: this.state.user?.userId,
				attributeName: "altEmail",
				attributeValue: this.state.altEmail,
			},
			{
				userId: this.state.user?.userId,
				attributeName: "phone",
				attributeValue: this.state.phone,
			},
			{
				userId: this.state.user?.userId,
				attributeName: "emailAlerts",
				attributeValue: this.state.emailAlerts,
			},
			{
				userId: this.state.user?.userId,
				attributeName: "textAlerts",
				attributeValue: this.state.textAlerts,
			},
			{
				userId: this.state.user?.userId,
				attributeName: "extraLinks",
				attributeValue: JSON.stringify(this.state.extraLinks),
			},
		];
		fetch(`/api/ef/userattributes`, {
			method: "POST",
			body: JSON.stringify(attributes),
			headers: { "Content-Type": "application/json" },
		}).then(() => {
			if (this.props.onSave) {
				this.props.onSave();
			}
		});
		//this.props.validateLogin();
	}
	render() {
		return (
			<Stack style={{ margin: "20px" }} tokens={{ childrenGap: 15 }}>
				<TextField
					readOnly={true}
					disabled={true}
					defaultValue={this.props.user?.username}
					value={this.props.user?.username}
					placeholder="Username"
					label="Username"
					onChange={(e, val) => {
						this.setState({ firstName: val });
					}}
				></TextField>
				<TextField
					defaultValue={this.props.user?.attributes.firstName}
					placeholder="First Name"
					label="First Name"
					value={this.state.firstName}
					onChange={(e, val) => {
						this.setState({ firstName: val });
					}}
				></TextField>
				<TextField
					defaultValue={this.props.user?.attributes.lastName}
					placeholder="Last Name"
					label="Last Name"
					value={this.state.lastName}
					onChange={(e, val) => {
						this.setState({ lastName: val });
					}}
				></TextField>
				<TextField
					defaultValue={this.props.user?.attributes.altEmail}
					placeholder="Alternate Email"
					label="Alternate Email"
					value={this.state.altEmail}
					onChange={(e, val) => {
						this.setState({ altEmail: val });
					}}
				></TextField>
				<TextField
					defaultValue={this.props.user?.attributes.phone}
					placeholder="Phone"
					label="Phone"
					value={this.state.phone}
					onChange={(e, val) => {
						this.setState({ phone: val });
					}}
				></TextField>
				<Checkbox
					label="Alert via email"
					checked={this.state.emailAlerts == "true"}
					onChange={(e, val) => {
						this.setState({ emailAlerts: val ? "true" : "false" });
					}}
				></Checkbox>
				<Checkbox
					label="Alert via text"
					checked={this.state.textAlerts == "true"}
					onChange={(e, val) => {
						this.setState({ textAlerts: val ? "true" : "false" });
					}}
				></Checkbox>
				{/* {this.props.showExtraLinks && (
					<Stack>
						<Stack
							horizontal
							verticalAlign="end"
							tokens={{ childrenGap: 15 }}
						>
							<TextField
								placeholder="/assets"
								label="URL"
								value={this.state.newLink}
								onChange={(e, val) => {
									this.setState({ newLink: val });
								}}
							></TextField>
							<TextField
								placeholder="Assets"
								label="Label"
								value={this.state.newLinkLabel}
								onChange={(e, val) => {
									this.setState({ newLinkLabel: val });
								}}
							></TextField>
							<IconButton
								iconProps={{ iconName: "AddToShoppingList" }}
								onClick={() => {
									this.addLink();
								}}
							></IconButton>
						</Stack>
						{this.state.extraLinks.map((el, i) => {
							return (
								<Stack
									horizontal
									verticalAlign="center"
									tokens={{ childrenGap: 15 }}
								>
									<IconButton
										iconProps={{ iconName: "ChromeClose" }}
										onClick={() => {
											this.deleteLink(i);
										}}
									></IconButton>
									<Text>{el.label}</Text>
									<Text>links to</Text>
									<Text>{el.link}</Text>
								</Stack>
							);
						})}
						
					</Stack>
				)} */}
				<ChoiceGroup
					label="Theme"
					options={[
						{ key: "palette-dark", text: "Dark" },
						{ key: "palette-light", text: "Light" },
					]}
					selectedKey={localStorage.theme}
					onChange={(e, val) => {
						localStorage.theme = val.key;
						this.forceUpdate();
						fetch("/config/" + val.key + ".json")
							.then((res) => res.json())
							.then((palette) =>
								loadTheme(createTheme({ palette }))
							);
					}}
				></ChoiceGroup>
				<Stack horizontal>
					<PrimaryButton
						text="Save settings"
						onClick={() => {
							this.saveSettings();
						}}
					></PrimaryButton>
				</Stack>
			</Stack>
		);
	}
}

export default connect(null, actions)(UserConfiguration);
