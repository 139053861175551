import axios from "axios";
import { defaultDash, getParameterByName, guid } from "../util";
import { keys } from "../util/keys";

///////// GENERAL ACTIONS ////////////

export const saveDashboard =
	(tiles, layouts, tabs, dashboard) => async (dispatch) => {
		var dashId = dashboard.dashId;
		if (dashId) {
			dashId = getParameterByName("dashboardId");
		}
		dashboard.config = JSON.stringify({ tiles, layouts, tabs });
		const request = await axios({
			url: "/api/dashboards",
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify(dashboard),
		});
		dispatch({
			type: "SAVE_DASHBOARD",
			payload: request.data,
		});
		dispatch(loadDashboard());
	};
export const saveDashboardAs =
	(tiles, layouts, tabs, name) => async (dispatch, getState) => {
		var system = getState().system;
		const newDash = {
			dashboardId: 0,
			dashboardName: name,
			assetDefinitionId: system.assetDefinitionId,
			systemDefinitionId: system.systemDefinitionId,
			config: JSON.stringify({ tiles, layouts, tabs }),
		};

		const postNewDash = await axios({
			url: "/api/dashboards",
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify(newDash),
		});
		dispatch(loadDashboard());
	};
export const deleteDashboard = (dashboardId) => async (dispatch) => {
	await axios({
		url: "/api/dashboards/" + dashboardId,
		method: "DELETE",
	});
	dispatch(loadDashboard());
};
export const updateDashboardName = (name) => async (dispatch) => {
	dispatch({
		type: "UPDATE_NAME",
		payload: name,
	});
};
export const getAssetId = () => {
	return (dispatch, getState) => {
		const state = getState();
		return state?.system?.assetId;
	};
};
export const applyDashboard = (dashboard) => async (dispatch, getState) => {
	dispatch({
		type: "DASHBOARD",
		payload: dashboard,
	});
	try {
		var dash = JSON.parse(dashboard.config);

		dispatch({
			type: "LOAD_DASHBOARD",
			payload: JSON.parse(dashboard.config),
		}).then(() => dispatch(changeToFirstValidTab()));
	} catch (e) {}
};
export const loadSystemDefinition = (systemId) => async (dispatch) => {
	fetch(`/api/ef/systems/${systemId}/definition`)
		.then((res) => res.json())
		.then((systemDefinition) => {
			dispatch({
				type: "DEFINITION",
				payload: systemDefinition,
			});
		});
};

export const loadAssetDefinition = (assetId) => async (dispatch) => {
	const url = `/api/ef/assets/${assetId}/definition`;
	fetch(url)
		.then((res) => res.json())
		.then((assetDefinition) => {
			dispatch({
				type: "DEFINITION",
				payload: assetDefinition,
			});
		});
};
export const loadAssets = (refreshToken) => async (dispatch) => {
	var res = await fetch(
		"/api/ef/assets"  
	)
		.then(async (response) => {
			if (response.status == 401) {
				invalidLogin();
				return;
			} else if (response.status == 403) {
				throw new Error();
			} else if (response.status == 200) return response.json();
		})
		.then((data) => {
			dispatch(setAssets(data));
			return data;
		})
		.catch((response) => {
			return [];
		});
	return res;
};
export const loadAsset = (assetId) => async (dispatch, getState) => {
	if (!assetId) assetId = getState().system.assetId;
	var res = await fetch("/api/ef/assets/" + assetId)
		.then(async (response) => {
			if (response.status == 401) {
				invalidLogin();
				return;
			} else if (response.status == 403) {
				throw new Error();
			} else if (response.status == 200) return response.json();
		})
		.then((data) => {
			dispatch(updateAsset(data));
			return data;
		})
		.catch((response) => {
			return [];
		});
	return res;
};

export const loadDashboard = () => (dispatch, getState) => {
	var currentSystem = getState().system;
	if (currentSystem.systemId) {
		dispatch(loadSystemDashboard(currentSystem.systemId));
	} else if (currentSystem.assetId) {
		dispatch(loadAssetDashboard(currentSystem));
	}
};

const generateEmptyDashboard = () => async (dispatch, getState) => {
	var system = getState().system;
	const newDash = {
		dashboardId: 0,
		dashboardName: "Unnamed Dashboard",
		systemDefinitionId: system.systemDefinitionId,
		assetDefinitionId: system.assetDefinitionId,
		config: defaultDash,
	};
	const postNewDash = await axios({
		url: "/api/dashboards",
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		data: JSON.stringify(newDash),
	});
	dispatch({
		type: "DASHBOARDS",
		payload: postNewDash,
	});
	return postNewDash[0];
};
// export const currentData = (referenceTime) =>  async dispatch => {
//   const assetId = getParameterByName("assetId");
//   let url = `/api/Assets/${assetId}/currentDataItems`;
//   if(referenceTime != undefined){
//      url = `/api/Assets/${assetId}/currentDataItems?referenceTime=${referenceTime.toJSON()}`;
//   }
//   const request = await axios({
//     url: url,
//     baseURL: keys.BASE_URL,
//     method: 'GET',
//     headers: {"Authorization": keys.TOKEN, "Content-Type": 'application/json'}
//   });
//   dispatch({
//     type: 'CURRENT_DATA',
//     payload: request
//   });
// }
export const singleCurrentDataItem = (cdi) => async (dispatch) => {
	dispatch({ type: "UPDATE_SINGLE", payload: cdi });
};
export const multipleCurrentDataItems = (cdis) => async (dispatch) => {
	dispatch({ type: "UPDATE_MULTIPLE", payload: cdis });
};

export const attributes = (assetId) => async (dispatch) => {
	if (!assetId) {
		assetId = getParameterByName("assetId");
	}
	const url = `/api/assets/${assetId}/attributes`;
	const request = await axios({
		url: url,
		baseURL: keys.BASE_URL,
		method: "GET",
		headers: {
			Authorization: keys.TOKEN,
			"Content-Type": "application/json",
		},
	});
	dispatch({
		type: "ATTRIBUTES_DATA",
		payload: request.data,
	});
};
export const createAttribute = (att) => async (dispatch) => {
	if (!att.assetId) {
		att.assetId = getParameterByName("assetId");
	}
	fetch(`/api/assets/${att.assetId}/attributes`, {
		method: "put",
		body: JSON.stringify(att),
		headers: { "content-type": "application/json" },
	})
		.then((res) => res.json())
		.then((attribute) => {
			console.log("updated attribute", attribute);

			dispatch(updateAttributes([attribute]));
		});
};
export const deleteAttribute = (att) => async (dispatch) => {
	console.log("hell deleteAttribute");
	if (!att.assetId) {
		att.assetId = getParameterByName("assetId");
	}
	fetch(`/api/assets/${att.assetId}/attributes/${att.attributeName}`, {
		method: "delete",
	}).then((res) => dispatch(updateDeletedAttributes([att])));
};
export const updateDeletedAttributes = (attributes) => async (dispatch) => {
	dispatch({
		type: "DELETED_ATTRIBUTES",
		payload: attributes,
	});
};
export const updateAttributes = (attributes) => async (dispatch) => {
	dispatch({
		type: "ATTRIBUTES_DATA",
		payload: attributes,
	});
};
export const assetData = () => async (dispatch) => {
	const assetId = getParameterByName("assetId");
	const url = `/api/assets/${assetId}`;
	const request = await axios({
		url: url,
		baseURL: keys.BASE_URL,
		method: "GET",
		headers: {
			Authorization: keys.TOKEN,
			"Content-Type": "application/json",
		},
	});
	dispatch({
		type: "ASSET_DATA",
		payload: request,
	});
};
export const loadAllAssets = () => async (dispatch) => {
	const url = `/api/assets/`;
	const request = await axios({
		url: url,
		baseURL: keys.BASE_URL,
		method: "GET",
		headers: {
			Authorization: keys.TOKEN,
			"Content-Type": "application/json",
		},
	});

	dispatch({
		type: "ASSETS",
		payload: request.payload,
	});
};
export const loadSystemDashboard = (systemId) => async (dispatch) => {
	return await fetch(`/api/ef/systems/${systemId}/dashboard`)
		.then((res) => res.json())
		.then((system) => {
			dispatch({
				type: "SYSTEM_UPDATED",
				payload: system,
			});
			var dashboards = system.systemDefinition.dashboards;
			if (dashboards.length > 0) {
				dispatch(applyDashboard(dashboards[0]));
				dispatch({
					type: "DASHBOARDS",
					payload: dashboards,
				});
			} else {
				dispatch(generateEmptyDashboard());
			}
			//dispatch(applyDashboard(dashboards[0]))
		});
};

export const loadAssetDashboard = (asset) => async (dispatch, getState) => {
	if (asset) {
		dispatch({
			type: "SYSTEM_UPDATED",
			payload: asset,
		});
	} else {
		asset = getState().system;
	}
	fetch(`/api/ef/assets/${asset.assetId}/dashboards`)
		.then((res) => res.json())
		.then((dashboards) => {
			if (dashboards.length > 0) {
				if (getParameterByName("dashboardId")) {
					dispatch(
						applyDashboard(
							dashboards.find(
								(d) =>
									d.dashboardId ==
									getParameterByName("dashboardId")
							)
						)
					);
				} else {
					dispatch(applyDashboard(dashboards[0]));
				}

				dispatch({
					type: "DASHBOARDS",
					payload: dashboards,
				});
			} else {
				dispatch(generateEmptyDashboard(asset.assetDefinitionId));
			}
		});
};

export const assetDefinitionData = () => async (dispatch) => {
	const assetDefinitionId = getParameterByName("assetDefinitionId");
	const url = `/api/assetdefinitions/${assetDefinitionId}`;
	const request = await axios({
		url: url,
		baseURL: keys.BASE_URL,
		method: "GET",
		headers: {
			Authorization: keys.TOKEN,
			"Content-Type": "application/json",
		},
	});
	dispatch({
		type: "ASSET_DEFINITION_DATA",
		payload: request,
	});
};

//////// TILE ACTIONS //////////////
export const addTile = (properties, activeTab) => async (dispatch) => {
	const tile = {
		dataGrid: { x: 0, y: 0, w: 20, h: 20 },
		properties: {
			...properties,
			decimals: 2,
		},
	};
	dispatch(unsetViewOnly());
	dispatch({
		type: "ADD_TILE",
		id: guid(),
		tile,
		activeTab,
	});
};
export const cloneTile = (tile, activeTab) => async (dispatch) => {
	//dispatch(unsetViewOnly())
	dispatch({
		type: "CLONE_TILE",
		id: guid(),
		tile,
		activeTab,
	});
};
export const enlargeTile = (id) => ({
	type: "ENLARGE_TILE",
	id,
});

export const deleteTile = (id, activeTab) => ({
	type: "DELETE_TILE",
	id,
	activeTab,
});

export const hideTile = (id, activeTab) => ({
	type: "HIDE_TILE",
	id,
	activeTab,
});

export const editTile = (newTile, index) => ({
	type: "EDIT_TILE",
	newTile,
	index,
});
//////// UI ACTIONS /////////

export const showAssetEditPanel = (el) => async (dispatch) => {
	dispatch({
		type: "SHOW_ASSET_PANEL",
		payload: el,
	});
};
export const setDashboardWidth = (el) => async (dispatch) => {
	dispatch({
		type: "SET_DASHBOARD_WIDTH",
		payload: el,
	});
	
};
export const hideAssetEditPanel = () => async (dispatch) => {
	dispatch({
		type: "HIDE_ASSET_PANEL",
	});
};
export const toggleViewOnly = () => async (dispatch) => {
	dispatch({
		type: "TOGGLE_VIEWONLY",
	});
};
export const setViewOnly = () => async (dispatch) => {
	dispatch({
		type: "SET_VIEWONLY",
	});
	changeToFirstValidTab();
};
export const unsetViewOnly = () => async (dispatch, getState) => {
	dispatch({
		type: "UNSET_VIEWONLY",
	});
};
export const loadDefinition = () => (dispatch, getState) => {
	var currentSystem = getState().system;
	if (currentSystem.systemId) {
		dispatch(loadSystemDefinition(currentSystem.systemId));
	} else if (currentSystem.assetId) {
		dispatch(loadAssetDefinition(currentSystem.assetId));
	}
};
export const toggleSidebar = () => ({
	type: "TOGGLE_SIDEBAR",
});

export const toggleModal = (tile) => ({
	type: "TOGGLE_MODAL",
	tile,
});

export const setLayout = (layout) => ({
	type: "LAYOUT",
	payload: layout,
});

export const resetModal = () => ({
	type: "RESET_MODAL",
});

export const toggleAlarms = () => ({
	type: "TOGGLE_ALARMS",
});
export const toggleMaintenance = () => ({
	type: "TOGGLE_MAINTENANCE",
});
export const toggleExport = () => ({
	type: "TOGGLE_EXPORT",
});
/////////// LAYOUT ACTIONS ////////////////
export const saveLayouts = (layouts, index) => {
	return {
		type: "SAVE_LAYOUT",
		layouts,
		index,
	};
};

/////// TAB ACTIONS ///////////
export const addTab = (tab) => {
	return {
		type: "ADD_TAB",
		tab,
	};
};

export const editTabName = (tab, name) => {
	return {
		type: "EDIT_TAB",
		tab,
		name,
	};
};

export const editTab = (tab) => {
	return {
		type: "EDIT_TAB_BY_ID",
		payload: tab,
	};
};
export const moveTabUp = (id) => {
	return {
		type: "MOVE_TAB_UP",
		id,
	};
};
export const moveTabDown = (id) => {
	return {
		type: "MOVE_TAB_DOWN",
		id,
	};
};
export const switchTabs = (from, to) => {
	return {
		type: "SWITCH_TABS",
		payload: { to, from },
	};
};

export const deleteTab = (index) => {
	return {
		type: "DELETE_TAB",
		index,
	};
};

export const changeTab = (index) => {
	window.dispatchEvent(new Event("resize"));
	setTimeout(() => window.dispatchEvent(new Event("resize")), 25);

	return {
		type: "CHANGE_TAB",
		index,
	};
};
export const changeToFirstValidTab = () => async (dispatch, getState) => {
	var state = getState();
	var currentTab = state.tabs[state.activeTab.index];

	var validTabs = state.tabs.slice().filter((tab) => {
		const permission = state.auth.permissions?.find(
			(p) => p.permissionId == tab.permissionId || !tab.permissionId
		);
		const showInLayout = tab[state.ui.layout] ?? true;

		return (permission && showInLayout) || !state.ui.viewOnly;
	});
	var isCurrentTabValid =
		validTabs?.findIndex((t) => t.id == currentTab?.id) >= 0;
	var firstValidIndex = state.tabs.findIndex((t) => t.id == validTabs[0]?.id);
	if (!isCurrentTabValid) {
		dispatch(changeTab(firstValidIndex));
	}
};
//Login and Auth status
export const validLogin = (contextSettings) => {
	return {
		type: "VALID_LOGIN",
		contextSettings,
	};
};
export const invalidLogin = () => {
	return {
		type: "INVALID_LOGIN",
	};
};
export const validateLogin = () => async (dispatch) => {
	let request;
	const url = "/api/ef/auth/login";
	var action;
	var succ;
	try {
		request = await axios({
			url,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (request.status == 200) {
			action = {
				type: "VALID_LOGIN",
				payload: request.data,
			};
		} else {
			action = {
				type: "INVALID_LOGIN",
				payload: "Login has expired.",
			};
		}
	} catch (e) {
		window.document.cookie =
			"authJWT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		action = {
			type: "INVALID_LOGIN",
			payload: "Login invalid or expired.",
		};
	}
	dispatch(action);
};
export const login = (username, password, success) => async (dispatch) => {
	// axios request
	const url = "/api/ef/auth/login";
	const auth = btoa(`${username}:${password}`);
	fetch(url, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Basic ${auth}`,
		},
	}).then((res) => {
		if (res.status == 200) {
			return res.json().then((data) => {
				dispatch({
					type: "VALID_LOGIN",
					payload: data,
				});
			});
		} else if (res.status == 403) {
			return res.text().then((message) => {
				dispatch({
					type: "INVALID_LOGIN",
					payload: message,
				});
			});
		} else {
			return res.text().then((message) => {
				dispatch({
					type: "INVALID_LOGIN",
					payload: "Uknown error while logging in.",
				});
			});
		}
	});
};
export const resetPassword =
	(username, password, changeId, success) => async (dispatch) => {
		// axios request
		let request;
		const url = `/api/auth/submit/${changeId}`;
		const auth = btoa(`${username}:${password}`);
		var action;
		try {
			request = await axios({
				url,
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Basic ${auth}`,
				},
			});
			action = {
				type: "VALID_LOGIN",
				payload: request.data,
			};
			dispatch(validLogin());
			if (success) {
				success(request.data);
			}
		} catch (e) {
			action = {
				type: "INVALID_LOGIN",
				payload: "Unknown error while resetting password",
			};
		}
		dispatch(action);
	};
export const logout = () => async (dispatch) => {
	// axios request
	let request;
	const url = "/api/auth/logout";
	request = await axios({
		url,
		method: "GET",
	});
	var action = {
		type: "INVALID_LOGIN",
		payload: "You have been logged out.",
	};
	dispatch(action);
};
export const setAssets = (assets) => async (dispatch) => {
	dispatch({ type: "REFRESH_ASSETS", payload: assets });
};

export const updateAsset = (asset) => async (dispatch) => {
	dispatch({ type: "UPDATE_ASSET", payload: asset });
};

//Playback Options
export const setStartDate = (start) => async (dispatch) => {
	dispatch({ type: "SET_START_DATE", payload: start });
};
export const setEndDate = (end) => async (dispatch) => {
	dispatch({ type: "SET_END_DATE", payload: end });
};
export const togglePlaybackBar = () => async (dispatch) => {
	dispatch({ type: "TOGGLE_PLAYBACK_BAR" });
};
export const toggleHelp = () => async (dispatch) => {
	dispatch({ type: "TOGGLE_HELP" });
};
export const togglePlayPause = () => async (dispatch) => {
	dispatch({ type: "TOGGLE_PLAY_PAUSE" });
};

export const showNav = () => async (dispatch) => {
	dispatch({ type: "SHOW_NAV" });
};
export const hideNav = () => async (dispatch) => {
	dispatch({ type: "HIDE_NAV" });
};

export const showFilePanel = () => async (dispatch) => {
	dispatch({ type: "SHOW_FILE_PANEL" });
};
export const hideFilePanel = () => async (dispatch) => {
	dispatch({ type: "HIDE_FILE_PANEL" });
};
