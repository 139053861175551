import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import uPlot from "uplot";
import "uplot/dist/uPlot.min.css";

import { sort2DarrayByFirstArray } from "./util";



function UPlotComponent({ opts, data,autoScaleX }) {

	
	let uplot = useRef();
	let domRef = useRef();
	let observer = useRef();

	let resize = () => {
		if (uplot.current) {
			var extra =
				(domRef.current?.querySelector("table.u-legend")?.getBoundingClientRect()
					.height ?? 0) +
				(domRef.current
					?.querySelector(".u-title")
					?.getBoundingClientRect().height ?? 0);
			var size = {
				width: domRef.current?.offsetWidth ?? 100,
				height: Math.max(
					extra,
					(domRef.current?.offsetHeight ?? 100) - extra
				),
			};
			uplot.current.setSize(size);
			// uplot.current.redraw(true, true);
		}
	};

	useEffect(() => {
		observer.current = new ResizeObserver(resize).observe(domRef.current);
		return () => {
			if (observer.current) {
				observer.current.disconnect();
			}
		};
	}, [uplot.current]);
	
	let preppedData = useMemo(() => {
		if(!data || opts.series.length != data.length) return data
		var newdata = data;
		for (var i = 0; i < newdata?.length; i++) {
			if (opts.series[i].smooth) {
				newdata[i] = smooth(
					newdata[i],
					opts.series[i].smooth,
					opts.series[i].smoothMode
				);
				//newdata[i] = derive([newdata[0], newdata[i]]);
			}
		}
		
		while(newdata[0].find(el=>typeof el !== "number" && typeof el !== "string")){
			var ind = newdata[0].findIndex(el=>typeof el !== "number" && typeof el !== "string")
			newdata.forEach(arr=>arr.splice(ind,1))
		}
		// let target = 100
		// let dec = parseInt(newdata[0].length/target)
		// newdata = newdata.map(arr=>arr=arr.filter((el,i)=>i%dec==0))
		return newdata;
	}, [data]);

	
	useEffect(()=>{
		if (uplot.current) {
			requestAnimationFrame((t) =>{
				let { min, max } = uplot.current.scales.x;
				let xData = uplot.current.data?.[0];
				var x = Math.abs(min - xData[0]) + Math.abs(max -xData[xData.length - 1])
				var isZoomedOut = Math.abs(min - xData[0]) + Math.abs(max -xData[xData.length - 1]) < 10
				if(isZoomedOut){
					uplot.current.setData(preppedData,true)
				}
				else{
					uplot.current.setData(preppedData,false)
					uplot.current.redraw(true,true)
				}
				
				//uplot.current.redraw( )
			}
			);

			//resize();
			// uplot.current.setScale("x", {
			// 	min: preppedData[0][0],
			// 	max: preppedData[0][preppedData[0].length - 1],
			// });
			// uplot.current.redraw(true, true);
		}
	},[preppedData])
	useEffect(() => {
		if (domRef.current) {
			uplot.current = new uPlot(opts, preppedData, domRef.current);
			resize();
		}
		return () => uplot.current.destroy();
	}, [domRef.current, opts]);

	return (
		<div
			style={{
				minHeight: "24px",
				height: "100%",
				width: "100%",
			}}
			ref={domRef}
		/>
	);
}

function integrate([x, y]) {
	var res = [0];
	var tot = 0;
	for (var i = 1; i < x.length; i++) {
		tot = tot + (y[i - 1] * (x[i] - x[i - 1])) / 1000;
		res.push(tot);
	}
	return res;
}
function derive([x, y]) {
	var res = [null];
	for (var i = 1; i < x.length - 1; i++) {
		res.push((y[i + 1] - y[i - 1]) / (x[i + 1] - x[i - 1]));
	}
	res.push(null);
	return res;
}
function smooth(arr, samp, method) {
	if (samp <= 1) {
		return arr;
	}
	var buff = [];
	var res = [];
	var sum = 0;
	for (var i = 0; i < arr.length; i++) {
		buff.unshift(arr[i]);
		sum += arr[i];
		if (buff.length > samp) {
			sum -= buff.pop();
		}
		switch (method) {
			case "min": {
				res[i] = Math.max(...buff);
			}
			case "max": {
				res[i] = Math.max(...buff);
			}
			case "med": {
				res[i] = [...buff].sort()[Math.floor(samp / 2)];
			}
			case "avg": {
				res[i] = sum / buff.length;
			}
			default: {
				res[i] = sum / buff.length;
			}
		}
	}
	return res;
}

export default UPlotComponent;
