import React, { Component } from "react";
import { connect } from "react-redux";
import { Controlled as CodeMirror } from "react-codemirror2";
import {
	TextField,
	Checkbox,
	PrimaryButton,
	Separator,
	Stack,
	Text,
} from "@fluentui/react";
import { FetchForm, executeFetch } from "../FetchForm";
import { format, guid } from "../../../../util";
require("codemirror/mode/javascript/javascript");
class CustomTableForm extends Component {
	state = {
		url: "",
		rows: "10",
		autoFormat: true,
		columns: "",
		parsing: "",
		title: "",
		pageSize: 10,
		fetch: { url: "" },
	};

	componentDidMount() {
		const { tile } = this.props.ui.modal;
		if (tile) {
			let {
				url,
				columns,
				rows,
				autoFormat,
				fetch,
				parsing,
				title,
				pageSize,
			} = tile.properties;
			if (!fetch) {
				fetch = { url };
			}
			if (!parsing) {
				parsing = `//Extra parsing can be done here with JS.
				(json)=>{
					var newJson = json
					return newJson;
				}`;
			}
			this.setState({
				url,
				columns,
				rows,
				autoFormat,
				fetch,
				parsing,
				title,
				pageSize,
			});
		}
	}

	handleChange = (e) => {
		e.preventDefault();
		const { addData } = this.props;
		const value = e.target.value ? e.target.value : e.target.checked;
		this.setState({ [e.target.name]: value }, () => {
			addData(this.state);
		});
	};
	handleChange2 = (state) => {
		const { addData } = this.props;
		this.setState(Object.assign({ ...this.state }, state), () => {
			addData(this.state);
		});
	};

	render() {
		const { url, columns, autoFormat, rows, parsing } = this.state;
		return (
			<Stack tokens={{ childrenGap: 10 }}>
				<FetchForm
					{...this.state.fetch}
					onChange={(e, val) => {
						this.handleChange2({ fetch: val });
					}}
				></FetchForm>
				<Stack horizontal>
					<PrimaryButton
						onClick={() => {
							executeFetch(this.state.fetch, this.props).then(
								(data) => {
									try {
										if (
											this.props.ui.modal.tile.properties
												.fontSize == 69
										) {
											var columns = Object.keys(
												data[0]
											).map((field) => {
												return {
													key: field,
													name: field,
													onRenderContent: `{{(row)=>{return row['${field}'].toString()}}}`,
												};
											});
											var colStr = JSON.stringify(
												columns,
												null,
												2
											);
											const regex = /"{{(.*?)}}"/g;
											var matches =
												colStr.matchAll(regex);
											for (let match of matches) {
												colStr = colStr.replace(
													match[0],
													match[1]
												);
											}
											this.handleChange2({
												columns: colStr,
											});
										} else {
											var columns = Object.keys(
												data[0]
											).map((field) => {
												return {
													key: guid(),
													fieldName: field,
													name: field,

													onRenderContent: `{{(row)=>{return row['${field}']?.toString()}}}`,
												};
											});
											var colStr = JSON.stringify(
												columns,
												null,
												2
											);
											const regex = /"{{(.*?)}}"/g;
											var matches =
												colStr.matchAll(regex);
											for (let match of matches) {
												colStr = colStr.replace(
													match[0],
													match[1]
												);
											}
											this.handleChange2({
												columns: colStr,
											});
										}
									} catch (e) {
										console.log(e);
									}
								}
							);
						}}
					>
						Auto detect columns
					</PrimaryButton>
				</Stack>
				<Text>Column Configuration</Text>
				<CodeMirror
					value={columns}
					options={{
						mode: "javascript",
						theme: "material",
						lineNumbers: true,
					}}
					onBeforeChange={(editor, data, value) => {
						this.handleChange2({ columns: value });
					}}
					onCursor={(editor, pos) => {
						this.editor = editor;
					}}
					onChange={(editor, data, value) => {}}
				></CodeMirror>
				<TextField
					label="Title"
					value={this.state.title}
					onChange={(e, val) => {
						this.handleChange2({ title: val });
					}}
				></TextField>
				<TextField
					label="Page Size (row count)"
					type="number"
					value={this.state.pageSize}
					onChange={(e, val) => {
						this.handleChange2({ pageSize: parseInt(val) });
					}}
				></TextField>
			</Stack>
		);
	}
}

function mapStateToProps({ ui, currentData, system }) {
	return { ui, currentData, system };
}

export default connect(mapStateToProps)(CustomTableForm);
