import { arraySort, guid } from "../util";

const tabs = (state = [{ name: "default", index: 0 }], action) => {
	switch (action.type) {
		case "ADD_TAB":
			if (!action.tab.id) {
				action.tab.id = guid();
			}
			return [...state, action.tab];
		case "EDIT_TAB": {
			return state.map((tab) => {
				if (tab.id === action.tab.id) {
					Object.assign(tab, action.payload);
					return { ...tab };
				} else {
					return tab;
				}
			});
		}
		case "EDIT_TAB_BY_ID": {
			return state.map((tab) => {
				if (tab.id === action.payload.id) {
					Object.assign(tab, action.payload);
					return { ...tab };
				} else {
					return tab;
				}
			});
		}

		case "MOVE_TAB_UP": {
			//This will take this ID and decrease its order
			//Copy Array
			let tabsCopy = state.slice();
			//Reduce order
			let { sortedArr, sortedPointers } = arraySort(
				tabsCopy,
				(a, b) => a.order - b.order
			);
			tabsCopy.forEach((el, i) => (el.order = sortedPointers[i]));

			//Find the requested tab and its current order
			var originalTab = tabsCopy.find((t) => t.id == action.id);
			var originalOrder = originalTab.order;

			//Find the tab that it is going to replace
			var newOrder = originalOrder - 1;
			var replacedTab = tabsCopy.find((t) => t.order == newOrder);
			originalTab.order = newOrder;
			replacedTab.order = originalOrder;
			console.log(originalTab);
			return tabsCopy;
		}
		case "MOVE_TAB_DOWN": {
			//This will take this ID and increase its order
			//Copy Array
			let tabsCopy = state.slice();
			console.log(JSON.stringify(tabsCopy));
			//Reduce order
			let { sortedArr, sortedPointers } = arraySort(
				tabsCopy,
				(a, b) => a.order - b.order
			);
			tabsCopy.forEach((el, i) => (el.order = sortedPointers[i]));

			//Find requested tab and increase its order
			var originalTab = tabsCopy.find((t) => t.id == action.id);
			var originalOrder = originalTab.order;

			var newOrder = originalOrder + 1;
			var replacedTab = tabsCopy.find((t) => t.order == newOrder);
			originalTab.order = newOrder;
			replacedTab.order = originalOrder;
			console.log(originalTab);
			return tabsCopy;
		}
		case "SWITCH_TABS": {
			let a = state.slice();
			let { from, to } = action.payload;
			[a[from], a[to]] = [a[to], a[from]];
			return a;
		}
		case "DELETE_TAB":
			return state.slice().filter((e, i) => i != action.index);
		case "LOAD_DASHBOARD":
			for (var x in action.payload.tabs) {
				if (action.payload.tabs[x]["index"])
					delete action.payload.tabs[x]["index"];
				if (action.payload.tabs[x]["newSorter"])
					delete action.payload.tabs[x]["newSorter"];
				if (!action.payload.tabs[x].id) {
					action.payload.tabs[x].id = guid();
				}
			}

			if (action.payload.tabs) {
				return action.payload.tabs;
			} else {
				return state;
			}
		default:
			return state;
	}
};

export default tabs;
