const assets = (state = [], action) => {
	switch (action.type) {
		case "REFRESH_ASSETS": {
			state = action.payload ?? [];
			return state;
		}
		case "UPDATE_ASSET": {
			var assets = [...state];
			var asset = assets.find(
				(el) => el.assetId == action.payload?.assetId
			);
			if (asset) {
				Object.assign(asset, action.payload);
			}
			return assets;
		}
		case "INVALIDATE_ASSETS": {
			state = [];
			return state;
		}
		case "ATTRIBUTES_DATA":
			const attributes = action.payload;
			attributes.forEach((att) => {
				var asset = state.find((ass) => (ass.assetId = att.assetId));
				if (asset) {
					var matchAttribute = asset.attributes.find(
						(el) => el.attributeName == att.attributeName
					);
					if (matchAttribute) {
						Object.assign(matchAttribute, att);
					}
				}
			});
		case "DELETED_ATTRIBUTES":
			const delAttr = action.payload;
			delAttr.forEach((att) => {
				var asset = state.find((ass) => (ass.assetId = att.assetId));
				if (asset) {
					asset.attributes = asset.attributes.filter(
						(el) =>
							el.attributeName.toLowerCase() !=
							att.attributeName.toLowerCase()
					);
				}
			});
		default:
			return state;
	}
};
export default assets;
