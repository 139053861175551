
export function sort2DarrayByFirstArray(data) {
	var originalOrder = data[0].map((el, i) => {
		return { el, i };
	});
	originalOrder.sort((e1, e2) => e1.el - e2.el);
	var pointers = originalOrder.map((og) => og.i);
	var newData = [];
	for (var i = 0; i < data.length; i++) {
		newData[i] = [];
		for (var j = 0; j < pointers.length; j++) {
			newData[i][j] = data[i][pointers[j]];
		}
	}
	return newData;
}

export function merge2DArraysWithFirstArrayKey(d1,d2){
    while(d2[0].length>0 && d2[0][0] && d1[0][0]){
        var newT = d2[0].pop()
        var isNew = d1[0].indexOf(newT) < 0
        if(isNew){ d1[0].push(newT)}
        for(var i = 1 ; i < d2.length;i++){
            var newV = d2[i].pop()
            if(isNew){
               
                d1[i].push(newV)
            }
        }

    }
    return d1;
}