import React from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { httpErrorHandler } from "../../util";
import { Route, NavLink } from "react-router-dom";
import Dashboard from "../dashboard/components/Dashboard";
import { ActionButton, DetailsList } from "@fluentui/react";
import * as actions from "../../actions/index";
class SystemsPage extends React.Component {
	state = { systems: [] };
	componentDidMount() {
		this.loadSystems();
	}
	loadSystems() {
		fetch("/api/ef/systems")
			.then((response) => {
				if (response.status == 401) {
					const { history } = this.props;
					if (history) history.push("/login");
					return null;
				} else if (response.status == 200) return response.json();
			})
			.then((data) => {
				if (data) {
					this.setState({
						systems: data,
					});
				}
			})
			.catch(httpErrorHandler);
	}
	render() {
		return (
			<div>
				<Route
					key={this.props.match.params.systemId}
					path="/systems/:systemId/dashboard"
					render={(props) => {
						var system = {
							...this.state.systems?.find(
								(el) =>
									el.systemId == props.match.params.systemId
							),
						};
						console.log(system);
						return (
							<>
								{system?.systemId && (
									<Dashboard
										key={props.match.params.systemId}
										system={system}
									/>
								)}
							</>
						);
					}}
				></Route>

				<Route exact={true} path="/systems">
					<DetailsList
						items={this.state?.systems ?? []}
						columns={[
							{
								key: "name",
								fieldName: "name",
							},
							{
								key: "dashboard",
								onRender: (item) => {
									return (
										<NavLink
											to={`/systems/${item.systemId}/dashboard`}
										>
											<ActionButton
												iconProps={{
													iconName: "ViewDashboard",
												}}
											>
												Dashboard
											</ActionButton>
										</NavLink>
									);
								},
							},
						]}
					></DetailsList>
				</Route>
			</div>
		);
	}
}
// function mapStateToProps({auth,assets}) {
//     return {
//       auth:auth,
//       assets:assets
//     }
//   }

export default compose(withRouter)(SystemsPage);
