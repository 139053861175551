import React, { Component } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Icon } from '@fluentui/react/lib/Icon';
class AttributeRow extends Component {
    static defaultProps = {
        assetId:0,
        readOnly:false,
        attributeName:"",
        attributeValue:"",
        onChange:(val)=>{console.log(val)},
        onDelete:()=>{console.log("deleted")}
    }
    state={
        assetId:this.props.assetId,
        readOnly:this.props.readOnly,
        attributeName:this.props.attributeName,
        attributeValue:this.props.attributeValue
    }
    render(){
        var att = {...this.state}
        const sectionStackTokens = { childrenGap: 10 };              
        return (
          <tr className="" >
          <td>
            {att.attributeName}
          </td>
          <td>
            {this.state.attributeEdit?<TextField
              value={att.attributeValue}
              multiline={att.attributeValue.length > 30}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(ev,val)=>{console.log(val);this.setState({"attributeValue":val})}}
            /> : <p>{att.attributeValue.substring(0,30)}</p>}
            
          </td>
          <td align="center">
          {
          !this.state.attributeEdit ?
          <Icon onClick={(e)=>{this.setState({attributeEdit:true})}}  iconName="Edit" />
          :
          <Icon onClick={(e)=>{
              this.setState({attributeEdit:false});
            this.props.onChange({
                assetId:this.state.assetId,
                readOnly:this.state.readOnly,
                attributeName:this.state.attributeName,
                attributeValue:this.state.attributeValue}
            )}}  
            iconName="Save" />
          }
          </td>
          <td align="center">
          {
          !this.state.attributeEdit ?
          <Icon  iconName="Delete" onClick={async (e)=>{
            var attrUrl = `/api/assets/${this.state.assetId}/attributes/${this.state.attributeName}`;
            await fetch(attrUrl,{method:"delete"})
            this.props.onDelete()
        }} />
          :
          <Icon  iconName="Cancel" onClick={(e)=>{this.setState({attributeEdit:false});
        }} />
          }
          
          </td>
          
          
        </tr>)
    }
}
export default AttributeRow