import {
	Checkbox,
	DefaultButton,
	Dropdown,
	getTheme,
	Icon,
	IconButton,
	Stack,
	TextField,
} from "@fluentui/react";
import { LimePanel as Panel } from "../../../components/LimePanel"; 
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../actions";
import { myTheme } from "../../../styles/FluentTheme";
import { guid } from "../../../util";
import NavMenu from "./NavMenu";
class Tabs extends Component {
	state = {
		nextTab: this.props.tabs.length + 1, // only used for next tabs default name
		visible: false,
		editedTab: {},
		type: "",
	};

	renderTab = (tab) => {
		const theme = getTheme();
		const i = this.props.tabs.findIndex((t) => t.id == tab.id);
		const { activeTab, ui } = this.props;
		const viewOnly = ui.viewOnly ? "none" : "";
		const permission = this.props.auth.permissions.find(
			(p) => p.permissionId == tab.permissionId || !tab.permissionId
		);
		const activeTabStyle = {
			textAlign: "center",
			minWidth: "5rem",
			maxWidth: "175px",
			padding: " .3rem",
			borderTop: `1px solid ${theme.palette.themePrimary}`,
			borderLeft: `1px solid ${theme.palette.themePrimary}`,
			borderRight: `1px solid ${theme.palette.themePrimary}`,
			verticalAlign: "middle",
			backgroundColor: theme.palette.white,
			marginBottom: "-1px",
		};
		const tabStyle = {
			textAlign: "center",
			minWidth: "5rem",
			maxWidth: "175px",
			padding: " .3rem",
			border: `1px solid ${theme.palette.neutralLight}`,
			verticalAlign: "middle",
			backgroundColor: theme.palette.white,
		};
		const showInLayout = tab[this.props.ui.layout] ?? true;
		return (showInLayout || !viewOnly) && permission ? (
			<span
				key={i}
				style={activeTab.index == i ? activeTabStyle : tabStyle}
				onClick={() => this.props.changeTab(i)}
			>
				<span
					key="edit"
					style={{ fontSize: ".85rem" }}
					name="edit"
					onDoubleClick={
						ui.viewOnly ? null : (e) => this.handleTabRename(e, tab)
					}
					title={`${tab.name}\nDouble click to edit tab name`}
				>
					{tab.name}
				</span>
				<span
					key="deete"
					style={{
						display: viewOnly,
						float: "right",
						marginLeft: "3px",
						top: " 0px",
						right: "1px",
					}}
					title="Click to delete tab"
				>
					<Icon
						style={{ height: "1rem" }}
						iconName="Cancel"
						className="tab-delete tab-icon"
						onClick={(e) => this.handleDeleteTab(i)}
					/>
				</span>
				
			</span>
		) : (
			<></>
		);
	};

	handleTabRename = (e, tab) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			editedTab: tab,
			type: "edit",
			visible: true,
		});
	};

	handleAddTab = (e) => {
		this.setState({ type: "add", visible: true });
	};

	handleDeleteTab = (index) => {
		const { deleteTab, changeToFirstValidTab, tabs, activeTab } =
			this.props;
		if (window.confirm("Are you sure you want to delete this tab?")) {
			deleteTab(index);
			if (tabs.findIndex((el) => el.id == activeTab.id) === index) {
				// const newIndex = activeTab.index === 0 ? 0 : activeTab.index - 1
				// changeTab(tabs[newIndex]);
				changeToFirstValidTab();
			}
		}
	};

	handleSubmit = () => {
		const { nextTab, type } = this.state;
		const name = this.state.editedTab.name;
		const length = this.props.tabs.length;
		if (type === "add") {
			this.props.addTab({
				name: `${name !== "" ? name : "tab" + nextTab}`,
				order: length,
				id: guid(),
			}); // deleted index: nextTab
			this.setState({ nextTab: nextTab + 1, visible: false }); // deleted, nextTab: newTab
		} else if (type === "edit") {
			// type is the tab object
			this.props.editTab(this.state.editedTab);
			this.setState({ visible: false });
		}
	};

	closeModal = () => {
		this.setState({ visible: false });
	};

	openModal = () => {
		this.setState({ visible: true }, () => {});
	};

	handleChange = (e) => {
		e.preventDefault();
		this.setState({ [e.target.name]: e.target.value });
	};
	setTabState(tabState) {
		var tab = { ...this.state.editedTab };
		Object.assign(tab, tabState);
		this.setState({ editedTab: tab });
	}
	render() {
		const theme = getTheme();
		const { type } = this.state;
		const { tabs, ui } = this.props;
		const viewOnly = ui.viewOnly ? "none" : "";
		const showHideClassName = `${
			this.state.visible ? "modal-show" : "modal-hide"
		}`;
		return (
			<Stack
				style={{
					borderBottom: `solid 1px ${theme.palette.themePrimary}`,
				}}
				horizontal
				horizontalAlign="space-between"
			>
				<Stack horizontal>
					<NavMenu></NavMenu>
					{tabs &&
						tabs
							.slice()
							//.sort((t1, t2) => t1.order - t2.order)
							.map(this.renderTab)}
					<IconButton
						iconProps={{ iconName: "Add" }}
						name="add"
						style={{ display: viewOnly }}
						title="Create New Tab"
						onClick={(e) => this.handleAddTab(e)}
					/>
				</Stack>
				<Stack>
					{!ui.viewOnly && (
						// <h1>{`${this.props.system?.assetAlias} - ${this.props.ui?.dashboard.dashboardName}`}</h1>

						<TextField
							underlined
							onChange={(e, val) => {
								this.props.updateDashboardName(val);
							}}
							value={this.props?.ui?.dashboard.dashboardName}
						></TextField>
					)}
				</Stack>
				
				<Panel
					headerText={
						type === "add" ? "Add New Tab" : "Edit Tab Name"
					}
					panelType={4}
					isOpen={this.state.visible}
					
					onDismiss={() => {
						this.setState({ visible: false, name: "" });
					}}
				>
					<Stack tokens={{ childrenGap: 5 }}>
						<TextField
							value={this.state.editedTab.name}
							onChange={(e, val) => {
								this.setTabState({ name: val });
							}}
						></TextField>
						<Dropdown
							label="Permission"
							selectedKey={this.state.editedTab.permissionId}
							onChange={(e, val) => {
								this.setTabState({ permissionId: val.key });
							}}
							options={permissions.map((p) => {
								return {
									key: p.permissionId,
									text: p.permissionName,
								};
							})}
						></Dropdown>

						<Checkbox
							key="smallVis"
							label="Visibile on small (mobile) layout"
							checked={this.state.editedTab.xxs ?? true}
							onChange={(e, val) => {
								this.setTabState({ xxs: val });
							}}
						></Checkbox>
						<Checkbox
							key="mVis"
							label="Visibile on medium layout"
							checked={this.state.editedTab.sm ?? true}
							onChange={(e, val) => {
								this.setTabState({ sm: val });
							}}
						></Checkbox>
						<Checkbox
							key="largeVis"
							label="Visibile on large layout"
							checked={this.state.editedTab.lg ?? true}
							onChange={(e, val) => {
								this.setTabState({ lg: val });
							}}
						></Checkbox>
						<Stack horizontal horizontalAlign="space-between">
							<IconButton
								iconName="PageLeft"
								iconProps={{ iconName: "PageLeft" }}
								onClick={() => {
									let from = tabs.findIndex(
										(t) => t.id == this.state.editedTab.id
									);
									if (from > 0) {
										this.props.switchTabs(from, from - 1);
									}
								}}
								text="move up"
							></IconButton>
							<Stack horizontal>
								{tabs
									//.sort((t1, t2) => t1.order - t2.order)
									.map((t, i) => {
										return t.order ==
											this.state.editedTab.order ? (
											<span
												key={i}
												style={{
													padding: 2,
													fontSize: ".8rem",
													height: "30px",
													width: "40px",
													borderTop: `1px solid ${
														myTheme().palette
															.themePrimary
													}`,
													borderLeft: `1px solid ${
														myTheme().palette
															.themePrimary
													}`,
													borderRight: `1px solid ${
														myTheme().palette
															.themePrimary
													}`,
												}}
											>
												{t.name}
											</span>
										) : (
											<span
												key={i}
												style={{
													padding: 2,
													borderBottom: `1px solid ${
														myTheme().palette
															.themePrimary
													}`,
													fontSize: ".8rem",
													height: "30px",
													width: "40px",
												}}
											>
												{t.name}
											</span>
										);
									})}
							</Stack>
							<IconButton
								iconName="PageRight"
								iconProps={{ iconName: "PageRight" }}
								onClick={() => {
									let from = tabs.findIndex(
										(t) => t.id == this.state.editedTab.id
									);
									if (from < tabs.length - 1) {
										this.props.switchTabs(from, from + 1);
									}
								}}
								text="move down"
							></IconButton>
						</Stack>
					</Stack>
					<Stack
							style={{ margin: "20px" }}
							horizontalAlign="end"
							horizontal
						>
							<DefaultButton
								label="Submit"
								text="Apply"
								onClick={() => this.handleSubmit()}
							/>
						</Stack>
				</Panel>
			</Stack>
		);
	}
}

function mapStateToProps({ activeTab, tabs, ui, auth }) {
	return { activeTab, tabs, ui, auth };
}

export default withRouter(connect(mapStateToProps, actions)(Tabs));
const permissions = [
	{ permissionId: 1, permissionName: "AdminModify" },
	{ permissionId: 2, permissionName: "PermissionsView" },
	{ permissionId: 3, permissionName: "PermissionsCreate" },
	{ permissionId: 4, permissionName: "PermissionsModify" },
	{ permissionId: 5, permissionName: "PermissionsDelete" },
	{ permissionId: 6, permissionName: "AssetsView" },
	{ permissionId: 7, permissionName: "AssetsCreate" },
	{ permissionId: 8, permissionName: "AssetsModify" },
	{ permissionId: 9, permissionName: "AssetsDelete" },
	{ permissionId: 10, permissionName: "UsersView" },
	{ permissionId: 11, permissionName: "UsersCreate" },
	{ permissionId: 12, permissionName: "UsersModify" },
	{ permissionId: 13, permissionName: "UsersDelete" },
	{ permissionId: 14, permissionName: "UserGroupsView" },
	{ permissionId: 15, permissionName: "UserGroupsCreate" },
	{ permissionId: 16, permissionName: "UserGroupsModify" },
	{ permissionId: 17, permissionName: "UserGroupsDelete" },
	{ permissionId: 18, permissionName: "AssetGroupsView" },
	{ permissionId: 19, permissionName: "AssetGroupsCreate" },
	{ permissionId: 20, permissionName: "AssetGroupsModify" },
	{ permissionId: 21, permissionName: "AssetGroupsDelete" },
	{ permissionId: 22, permissionName: "JobsView" },
	{ permissionId: 23, permissionName: "JobsCreate" },
	{ permissionId: 24, permissionName: "JobsModify" },
	{ permissionId: 25, permissionName: "JobsDelete" },
	{ permissionId: 26, permissionName: "AdminUnitsView" },
	{ permissionId: 27, permissionName: "AdminUnitsCreate" },
	{ permissionId: 28, permissionName: "AdminUnitsModify" },
	{ permissionId: 29, permissionName: "AdminUnitsDelete" },
	{ permissionId: 30, permissionName: "CurrentDataItemsView" },
	{ permissionId: 31, permissionName: "CurrentDataItemsCreate" },
	{ permissionId: 32, permissionName: "CurrentDataItemsModify" },
	{ permissionId: 33, permissionName: "CurrentDataItemsDelete" },
	{ permissionId: 34, permissionName: "HistoricalDataItemsView" },
	{ permissionId: 35, permissionName: "HistoricalDataItemsCreate" },
	{ permissionId: 36, permissionName: "HistoricalDataItemsModify" },
	{ permissionId: 37, permissionName: "HistoricalDataItemsDelete" },
	{ permissionId: 38, permissionName: "AlarmsView" },
	{ permissionId: 39, permissionName: "AlarmsCreate" },
	{ permissionId: 40, permissionName: "AlarmsModify" },
	{ permissionId: 41, permissionName: "AlarmsDelete" },
	{ permissionId: 42, permissionName: "EventsView" },
	{ permissionId: 43, permissionName: "EventsCreate" },
	{ permissionId: 44, permissionName: "EventsModify" },
	{ permissionId: 45, permissionName: "EventsDelete" },
	{ permissionId: 46, permissionName: "CoordinatesView" },
	{ permissionId: 47, permissionName: "CoordinatesCreate" },
	{ permissionId: 48, permissionName: "CoordinatesModify" },
	{ permissionId: 49, permissionName: "CoordinatesDelete" },
	{ permissionId: 50, permissionName: "AttributesView" },
	{ permissionId: 51, permissionName: "AttributesCreate" },
	{ permissionId: 52, permissionName: "AttributesModify" },
	{ permissionId: 53, permissionName: "AttributesDelete" },
	{ permissionId: 54, permissionName: "DashboardView" },
	{ permissionId: 55, permissionName: "DashboardCreate" },
	{ permissionId: 56, permissionName: "DashboardModify" },
	{ permissionId: 57, permissionName: "DashboardDelete" },
	{ permissionId: 58, permissionName: "CommandsView" },
	{ permissionId: 59, permissionName: "CommandsCreate" },
	{ permissionId: 60, permissionName: "CommandsModify" },
	{ permissionId: 61, permissionName: "CommandsDelete" },
	{ permissionId: 62, permissionName: "AlertsView" },
	{ permissionId: 63, permissionName: "AlertsCreate" },
	{ permissionId: 64, permissionName: "AlertsModify" },
	{ permissionId: 65, permissionName: "AlertsDelete" },
	{ permissionId: 66, permissionName: "FormulasView" },
	{ permissionId: 67, permissionName: "FormulasCreate" },
	{ permissionId: 68, permissionName: "FormulasModify" },
	{ permissionId: 69, permissionName: "FormulasDelete" },
	{ permissionId: 70, permissionName: "AssetDefinitionsView" },
	{ permissionId: 71, permissionName: "AssetDefinitionsCreate" },
	{ permissionId: 72, permissionName: "AssetDefinitionsModify" },
	{ permissionId: 73, permissionName: "AssetDefinitionsDelete" },
	{ permissionId: 74, permissionName: "SystemDefinitionsView" },
	{ permissionId: 75, permissionName: "SystemDefinitionsCreate" },
	{ permissionId: 76, permissionName: "SystemDefinitionsModify" },
	{ permissionId: 77, permissionName: "SystemDefinitionsDelete" },
	{ permissionId: 78, permissionName: "SlotsView" },
	{ permissionId: 79, permissionName: "SlotsCreate" },
	{ permissionId: 80, permissionName: "SlotsModify" },
	{ permissionId: 81, permissionName: "SlotsDelete" },
	{ permissionId: 82, permissionName: "SystemsView" },
	{ permissionId: 83, permissionName: "SystemsCreate" },
	{ permissionId: 84, permissionName: "SystemsModify" },
	{ permissionId: 85, permissionName: "SystemsDelete" },
	{ permissionId: 86, permissionName: "SlotAssignmentsView" },
	{ permissionId: 87, permissionName: "SlotAssignmentsCreate" },
	{ permissionId: 88, permissionName: "SlotAssignmentsModify" },
	{ permissionId: 89, permissionName: "SlotAssignmentsDelete" },
	{ permissionId: 90, permissionName: "DataItemsView" },
	{ permissionId: 91, permissionName: "DataItemsCreate" },
	{ permissionId: 92, permissionName: "DataItemsModify" },
	{ permissionId: 93, permissionName: "DataItemsDelete" },
	{ permissionId: 94, permissionName: "FilesView" },
	{ permissionId: 95, permissionName: "FilesCreate" },
	{ permissionId: 96, permissionName: "FilesModify" },
	{ permissionId: 97, permissionName: "FilesDelete" },
];
