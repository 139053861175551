import { combineReducers } from "redux";
import activeTab from "./activeTab";
import assets from "./assets";
import auth from "./auth";
import currentData from "./currentData";
import definition from "./definition";
import layouts from "./layouts";
import playback from "./playback";
import system from "./system";
import tabs from "./tabs";
import tiles from "./tiles";
import ui from "./ui";

const createRootReducer = () =>
	combineReducers({
		activeTab,
		tabs,
		tiles,
		layouts,
		ui,
		currentData,
		//data,
		auth,
		playback,
		assets,
		//asset,
		system,
		definition,
	});
export default createRootReducer;
