import {
	ActionButton,
	DefaultButton,
	PrimaryButton,
	Stack,
	TextField,
} from "@fluentui/react";
import { connect } from "mqtt";
import React from "react";

class MessageViewer extends React.Component {
	state = {
		messages: [],
		broker: "wss://mqtt01.limeinst.com/mqtt/",
		subscriptions: [],
		newSub: "sandbox.limeinst.com/fipy/240ac4fffec82a6c/data",
		connected: false,
		connecting: false,
	};
	connectToBroker() {
		if (this.client) {
			this.client.end(true);
		}
		this.setState({ connecting: true });
		this.client = connect(this.state.broker);
		for (var i = 0; i < this.state.subscriptions.length; i++) {
			this.client.subscribe(this.state.subscriptions[i]);
		}
		this.client.on("connect", () => {
			this.setState({ connected: true, connecting: false });
		});
		this.client.on("message", (topic, payload) => {
			var prev = [...this.state.messages];
			prev.push(topic + ": " + payload.toString());
			this.setState({ messages: prev });
		});
	}
	subscribeToNew() {
		this.setState(
			{ subscriptions: [...this.state.subscriptions, this.state.newSub] },
			() => {
				if (!this.client) {
					this.connectToBroker();
				} else {
					this.client.subscribe(this.state.newSub);
				}
			}
		);
	}
	unsubscribe(topic) {
		var subs = this.state.subscriptions.filter((el) => {
			return el != topic;
		});
		this.setState({ subscriptions: subs });
		this.client.unsubscribe(topic);
	}

	render() {
		return (
			<Stack>
				<Stack>
					<Stack horizontal>
						<TextField
							value={this.state.broker}
							onChange={(e, val) => {
								this.setState({ broker: val });
							}}
						></TextField>
						<PrimaryButton
							onClick={() => {
								this.connectToBroker();
							}}
						>
							Connect
						</PrimaryButton>
					</Stack>
					<Stack horizontal>
						<TextField
							value={this.state.newSub}
							onChange={(e, val) => {
								this.setState({ newSub: val });
							}}
						></TextField>
						<PrimaryButton
							onClick={() => {
								this.subscribeToNew();
							}}
						>
							Subscribe
						</PrimaryButton>
					</Stack>
				</Stack>
				<Stack>
					Subscriptions:
					{this.state.subscriptions.map((el) => {
						return (
							<Stack horizontal>
								<ActionButton
									onClick={() => {
										this.unsubscribe(el);
									}}
									style={{ color: "red", background: "#333" }}
									iconProps={{ iconName: "Unsubscribe" }}
								>
									{"Unsubscribe from " + el}
								</ActionButton>
							</Stack>
						);
					})}
				</Stack>
				Messages
				<Stack horizontal>
					<DefaultButton
						onClick={() => {
							this.setState({ messages: [] });
						}}
					>
						Clear Messages
					</DefaultButton>
				</Stack>
				<Stack>
					{this.state.messages.map((el) => {
						return <p>{el}</p>;
					})}
				</Stack>
			</Stack>
		);
	}
}

export default MessageViewer;
