import { useSelector } from "react-redux";
import {PaginatedTable} from "../../../../components/PaginatedTable/PaginatedTable";
import { getParameterByName } from "../../../../util";

export default function DataItemTable(props) {
	const data = useSelector((state) => {
		let assetId = getParameterByName("assetId");
		return Object.values(state?.currentData?.[assetId] ?? {}).map((di) => {
			di.unit = state?.definition?.dataItemDefinitions?.find(
				(did) => did.dataItemId == di.dataItemId
			)?.unit;
			return di;
		});
	});

	return (
		<PaginatedTable
			{...props.properties}
			columns={[
				{
					key: "label",
					fieldName: "label",
					name: "Label",
					visible: props.properties.showLabel,
					sort: (l1, l2) => l1.label?.localeCompare(l2.label),
				},
				{
					key: "value",
					fieldName: "value",
					name: "Value",
					visible: props.properties.showValue,
					onRenderContent: (di) => {
						if (typeof di.value == "number") {
							return di.value.toFixed(props.properties.decimals);
						} else {
							return di.value;
						}
					},
				},
				{
					key: "unit",
					fieldName: "unit",
					name: "Unit",
					visible: props.properties.showUnit,
				},
			]}
			filter={(item, search) => {
				try {
					return item.label?.match(search);
				} catch (e) {
					return item.label?.includes(search);
				}
			}}
			style={{ height: "100%" }}
			items={data}
		></PaginatedTable>
	);
}
