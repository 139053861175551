import {
	Text,
	Panel,
	TextField,
	Stack,
	Icon,
	DefaultEffects,
	PanelType,
	PrimaryButton,
	mergeStyles,
	mergeStyleSets,
	Separator,
	ActionButton,
	DefaultButton,
	Dropdown,
	DetailsList,
	DetailsListLayoutMode,
	SelectionMode,
	DEFAULT_MASK_CHAR,
} from "@fluentui/react/";
import csv2json from "csvjson-csv2json";

import React from "react";
const iconClass = mergeStyles({
	// fontSize: 25,
	// height: 50,
	// width: 50,
	// margin: '0 25px',
});
const classNames = mergeStyleSets({
	white: [iconClass],
	green: [{ color: "green" }, iconClass],
	red: [{ color: "red" }, iconClass],
});

class AssetDataPanel extends React.Component {
	state = {
		search: "",
		aliasMap: JSON.parse(
			this.props.asset?.attributes.find(
				(el) => el.attributeName.toLowerCase() == "aliasmap"
			)?.attributeValue ?? "{}"
		),
		streamMap: JSON.parse(
			this.props.asset?.attributes.find(
				(el) => el.attributeName.toLowerCase() == "streammap"
			)?.attributeValue ?? "{}"
		),
		dataitems: [],
	};
	saveAttribute(att) {
		fetch(`/api/assets/${att.assetId}/attributes`, {
			method: "put",
			body: JSON.stringify(att),
			headers: { "content-type": "application/json" },
		}).then((res) => res.text());
	}
	componentDidMount() {}
	updateAttribute(attributeName, attributeValue) {
		if (this.props.asset) {
			var x = { ...this.props.asset };
			var att = x.attributes.filter((el) => {
				return (
					el.attributeName.toLowerCase() ==
					attributeName.toLowerCase()
				);
			});
			if (x.length > 0) {
				att[0].attributeValue = attributeValue;
			} else {
				x.attributes.push({
					assetId: this.props.asset.assetId,
					attributeName,
					attributeValue,
				});
			}
			this.saveAttribute({
				assetId: this.props.asset.assetId,
				attributeName,
				attributeValue,
			});
		}
	}
	loadDataItemDefinitions() {
		this.setState({
			aliasMap: JSON.parse(
				this.props.asset?.attributes.find(
					(el) => el.attributeName.toLowerCase() == "aliasmap"
				)?.attributeValue ?? "{}"
			),
			streamMap: JSON.parse(
				this.props.asset?.attributes.find(
					(el) => el.attributeName.toLowerCase() == "streammap"
				)?.attributeValue ?? "{}"
			),
		});
		fetch(
			`/api/assetDefinitions/${this.props.asset.assetDefinitionId}/dataitemdefinitions`
		)
			.then((data) => data.json())
			.then((data) => this.setState({ dataitems: data }))
			.catch((err) => console.log(err));
	}
	checkCSV() {
		try {
			if (this.state.csvText && this.state.csvText?.length > 1) {
				var x = csv2json(this.state.csvText);
				var aliasMap = {};
				var streamMap = {};
				for (var i in x) {
					var di = this.state.dataitems.find((el) => {
						return (
							el.label.toLowerCase() == x[i].label.toLowerCase()
						);
					});
					var streamIndex = x[i].index ?? i;
					if (di) {
						streamMap[streamIndex] = di.dataItemId;
						if (x[i].alias) {
							aliasMap[x[i].label] = x[i].alias;
						}
					}
				}
				this.setState({ aliasMap, streamMap });
			}
		} catch (e) {
			console.log(e);
		}
	}
	changeAliasMap(item, val) {
		var am = { ...this.state.aliasMap };
		am[item.dataItemId] = val;
		this.setState({ aliasMap: am });
	}
	changeStreamMap(item, val) {
		var sm = { ...this.state.streamMap };
		sm[item.val] = item.dataItemId;
		this.setState({ streamMap: sm });
	}
	getKeyByValue(object, value) {
		return Object.keys(object).find((key) => object[key] === value);
	}
	render() {
		var showForm = this.state.showForm;
		return (
			<Panel
				isLightDismiss={true}
				isOpen={this.props.isOpen}
				onOpen={() => {
					this.loadDataItemDefinitions();
				}}
				onDismiss={this.props.onDismiss}
				type={PanelType.medium}
				onRenderHeader={() => {
					return (
						<Stack horizontal>
							<h3
								style={{
									fontFamily: "Proxima Nova Bold",
									fontSize: "1.2rem",
								}}
							>
								{this.props.asset?.assetAlias.toUpperCase()}
							</h3>
							<pre> </pre>
							<h3
								style={{
									fontFamily: "Proxima Nova Thin",
									fontSize: "1.2rem",
								}}
							>
								DATA ITEMS
							</h3>
						</Stack>
					);
				}}
			>
				<Stack tokens={{ childrenGap: 15 }}>
					<TextField
						label="Enter a CSV with headers 'label', 'alias', and optionally 'index'"
						value={this.state.csvText}
						onChange={(e, val) => {
							this.setState({ csvText: val }, () => {
								if (val.length > 5) {
									this.checkCSV();
								}
							});
						}}
						multiline
					></TextField>
					<Stack horizontalAlign="space-between" horizontal>
						<DefaultButton
							text="Download template"
							onClick={() => {
								let csvContent =
									"data:text/csv;charset=utf-8,index,label,alias\n";
								csvContent += this.state.dataitems
									.map((el, i) => {
										return `${i},${el.label},${el.label}`;
									})
									.join("\n");
								var encodedUri = encodeURI(csvContent);
								var link = document.createElement("a");
								link.setAttribute("href", encodedUri);
								link.setAttribute(
									"download",
									"dataitem_mapping_template.csv"
								);
								document.body.appendChild(link); // Required for FF
								link.click();
							}}
						></DefaultButton>
						<Stack
							horizontalAlign="end"
							horizontal
							tokens={{ childrenGap: 15 }}
						>
							<PrimaryButton
								onClick={() => {
									this.updateAttribute(
										"streamMap",
										JSON.stringify(this.state.streamMap)
									);
									this.updateAttribute(
										"aliasMap",
										JSON.stringify(this.state.aliasMap)
									);
								}}
							>
								Apply to Asset
							</PrimaryButton>
							{/* <DefaultButton onClick={()=> {this.setState({assetSelector:true})}}>
                        Apply to Multiple Assets
                    </DefaultButton> */}
							{this.state.assetSelector && <div></div>}
						</Stack>
					</Stack>
				</Stack>
				<DetailsList
					compact
					items={this.state.dataitems}
					columns={[
						{ key: "label", fieldName: "label", name: "Label" },
						{
							key: "index",
							name: "Stream Index",
							onRender: (item) => {
								return (
									<TextField
										type="number"
										value={
											this.getKeyByValue(
												this.state.streamMap,
												item.dataItemId
											) ?? ""
										}
										onChange={(e, val) => {
											this.changeStreamMap(item, val);
										}}
									></TextField>
								);
							},
						},
						{
							key: "alias",
							name: "Portal Alias",
							onRender: (item) => {
								return (
									<TextField
										value={
											this.state.aliasMap[
												item.dataItemId
											] ?? ""
										}
										onChange={(e, val) => {
											this.changeAliasMap(item, val);
										}}
									></TextField>
								);
							},
						},
					]}
				></DetailsList>
			</Panel>
		);
	}
}
export default AssetDataPanel;
