import React from "react";
const ExternalPage = (props) => {
	console.log(props);
	return (
		<div style={{ display: "flex", width: "100%", height: "100vh" }}>
			<iframe
				frameBorder="0"
				style={{ flexGrow: 1 }}
				src={`${props.match.params[0]}${props.location.search}`}
			></iframe>
		</div>
	);
};
export default ExternalPage;
