import { ActionButton, Panel, PanelType, Slider } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { guid } from "../../util";
import { LimePanel } from "../LimePanel";
import { PaginatedTable } from "../PaginatedTable/PaginatedTable";
import SearchableDropdown from "../SearchableDropdown";
import AlarmSlider from "./AlarmSlider";
import {AlarmExpression, ExpressionBuilder} from "./ExpressionBuilder";

interface IPanelProps  {
    isOpen:boolean,
    onDismiss:()=>void
}

export type UserAlert = {
    alertId? : string,
    alertName:string,
    alertText:string,
    userId : number,
    assetId: number,
    expiration:Date,
    alarmExpression : AlarmExpression
}

export default function ExpressionBuilderPanel(props:IPanelProps){
    let assetId = useSelector(state=>(state as any).system.assetId)
    let {userId,username} = useSelector(state=>(state as any).auth.auth.user)
    let [userAlerts,setUserAlerts] = useState<UserAlert[]>([])
    let variables = useSelector(state=> 
        (state as any).definition?.dataItemDefinitions?.map(
            did=>(
                {
                    dataItemId:did.dataItemId,
                    variable:"_"+did.label.replace(/[\W_]+/g,"_")
                })
        ) ?? [] )

    let [editUserAlert,setEditUserAlert] = useState<UserAlert>(null)

    let loadAlerts = () =>{
        if(assetId && userId){
            fetch(`/api/ef/useralerts?userId=${userId}`)
                .then(res=>res.json())
                .then(useralerts=>setUserAlerts(useralerts.filter(ua=>ua.assetId == assetId)))
                .catch(err=>{})
        }
    }
    useEffect(()=>{
        loadAlerts()
    },[assetId,userId])

    let RotaryTorqueAlert = userAlerts.find(ua=>ua.alertName == "Rotary Torque Alert")
    let DepthAlert = userAlerts.find(ua=>ua.alertName == "Depth Alert")
    let DifferentialPressureAlert = userAlerts.find(ua=>ua.alertName == "Differential Pressure Alert")

    return (
    <Panel
        type={PanelType.medium}
        {...props}
       
    >
{/*         
        <AlarmSlider 
        userAlert={RotaryTorqueAlert}
        maxValue={5000} 
        minValue = {0}  
        step={100}
   ></AlarmSlider>
   
   <AlarmSlider 
        userAlert={RotaryTorqueAlert}
        maxValue={5000} 
        minValue = {0}  
        step={100}
   ></AlarmSlider>
   <AlarmSlider 
        maxValue={5000} 
        minValue = {0}  
        step={100}
   ></AlarmSlider> */}
        {/* <PaginatedTable 
        onActiveItemChanged={(item)=>setEditUserAlert(item)}
        title="My alerts"
         showExport={false} showPageSize={false} items={userAlerts}  columns={[{
            fieldName:"expression",
            onRenderContent:(item,c)=>item.alarmExpression.expression
        }]}></PaginatedTable>
        {editUserAlert ?  
            <ActionButton onClick={()=>{
                let newUserAlert = {...editUserAlert} as any
                newUserAlert.expression = JSON.stringify(newUserAlert.alarmExpression)
                newUserAlert.emails = username
                console.log(newUserAlert)
                fetch("/api/ef/useralerts",{method:"post",headers:{"Content-Type":"application/json"},body:JSON.stringify([newUserAlert])})
                .then(loadAlerts)
            }} text="Save Alert" iconProps={{iconName:"Save"}}></ActionButton> : 
            <ActionButton onClick={()=>{
                let newAlert : UserAlert = 
                    {
                       // alertId:guid(),
                        userId,
                        assetId,
                        alarmExpression:{
                            variables,
                            expression:"_"+variables[0].variable+ " > 10000"
                        },
                        expiration:new Date()
                    }
                setEditUserAlert(newAlert)
                }} text="Add Alert" iconProps={{iconName:"Add"}}></ActionButton>}

        {editUserAlert &&
         <ExpressionBuilder onAlarmExpressionChanged={(val)=>{
             var newValue = {...editUserAlert}
             newValue.alarmExpression = val
             console.log(newValue)
             setEditUserAlert(newValue)
         }} alarmExpression={editUserAlert.alarmExpression}></ExpressionBuilder>
        }
        */}
    </Panel>)
}