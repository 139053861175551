import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../actions";
import AssetMapbox from "../../../assets/assetMapbox";

class Map extends Component {
	state = {
		overlays: [],
		options: {},
		loading: true,
	};

	render() {
		return (
			<AssetMapbox
				id={this.props.properties.id}
				assets={
					this.props.properties.showAll
						? this.props.assets
						: [this.props.system]
				}
			></AssetMapbox>
		);
	}
	shouldComponentUpdate(prevprops) {
		return this.props.assets;
	}
}

function mapStateToProps({ system, assets }) {
	return { system, assets };
}

export default connect(mapStateToProps, actions)(Map);
