import React, { Component } from "react";
import { connect } from "react-redux";

import SystemDataItemSelector from "../../../../components/SystemDataItemSelector";
import PopoutColorPicker from "../../../../components/PopoutColorPicker";
import { Checkbox, TextField } from "@fluentui/react";

class IndicatorForm extends Component {

	static defaultProps = {};

	handleChange2 = (key, val) => {
		this.props.addData({ [key]: val });
	};
	
	render() {
		const {
			dataItem,
			labelColor,
			valueColor,
			backgroundColor,
			timestamp,
			slotPath,UoM
		} = this.props.properties;
		const { tile } = this.props.ui.modal;
		var definition = this.props.definition;
		for (var i in tile?.properties?.slotPath ?? []) {
			var slotMatch = definition?.slots?.find(
				(el) => el.slotId == tile.properties.slotPath[i]
			);
			definition =
				slotMatch?.assetDefinition || slotMatch?.systemDefinition;
		}
		var dataItemList = definition?.dataItemDefinitions ?? [];
		console.log(timestamp,"TIOMESTAMP")
		return (
			<section className="component-form">
				<SystemDataItemSelector
					label="Data item"
					key={this.props.ui?.modal?.tile?.id}
					onDataItemSelected={({slotPath,dataItem}) => {
						this.props.addData({slotPath,dataItem});

					}}
					dataItem={dataItem}
					slotPath={slotPath}
				></SystemDataItemSelector>
				<PopoutColorPicker
					label={"Label color"}
					color={labelColor}
					onChange={(ev, color) => {
						this.handleChange2("labelColor", color.str);
					}}
				></PopoutColorPicker>
				<PopoutColorPicker
					label={"Background color"}
					color={backgroundColor}
					onChange={(ev, color) => {
						this.handleChange2("backgroundColor", color.str);
					}}
				></PopoutColorPicker>
				<PopoutColorPicker
					label={"Value color"}
					color={valueColor}
					onChange={(ev, color) => {
						this.handleChange2("valueColor", color.str);
					}}
				></PopoutColorPicker>
				<TextField label="UoM"
				value = {UoM}
				onChange={(e,val)=>this.handleChange2("UoM",val)}></TextField>
				<Checkbox
					label="Show Timestamp"
					checked={timestamp ?? false}
					onChange={(e,val)=>this.handleChange2("timestamp",val)}
					name="timestamp"
				/>
			</section>
		);
	}
}

function mapStateToProps({ ui, definition }) {
	return { ui, definition };
}

export default connect(mapStateToProps)(IndicatorForm);
