import {
	TextField,
	DetailsList,
	Checkbox,
	Stack,
	PrimaryButton,
	IconButton,
	DefaultButton,
	Separator,
	ActionButton,
} from "@fluentui/react/";
import React from "react";
import SearchableDropdown from "../../../components/SearchableDropdown";
import DateTimePicker from "../../dashboard/components/forms/DateTimePicker";

class SystemForm extends React.Component {
	state = {
		slots: [],
	};
	componentDidMount() {
		if (this.props.systemId) {
			this.loadSystem(this.props.systemId);
		} else {
			this.setState({
				name: "",
				alias: "",
				description: "",
				currentSlotAssignments: [],
			});
		}
		this.setState({
			assets: this.props.assets ?? [],
			systems: this.props.systems ?? [],
		});
	}
	loadSystem(id) {
		if (id) {
			fetch(`/api/ef/systems/${id}`)
				.then((res) => res.json())
				.then((system) => {
					console.log(system);
					this.setState({ ...system });
				});
		}
	}
	loadSystems() {
		fetch(`/api/ef/systems/`)
			.then((res) => res.json())
			.then((systems) => {
				this.setState({ ...systems });
			});
	}

	setSlotAssignmentState(slotId, state) {
		var currentSlotAssignments = [
			...(this.state.currentSlotAssignments ?? []),
		];
		var currentSlotAssignment = currentSlotAssignments.find(
			(sa) => sa.slotId == slotId
		);
		if (currentSlotAssignment) {
			Object.assign(currentSlotAssignment, state);
		} else {
			currentSlotAssignments.push(state);
		}

		this.setState({ currentSlotAssignments });
	}
	saveSystem() {
		fetch(`/api/ef/systems`, {
			method: "post",
			body: JSON.stringify(this.state),
			headers: {
				"Content-Type": "application/json",
			},
		}).then((res) => {
			if (this.props.onSaveSystem) {
				this.props.onSaveSystem();
			}
		});
	}

	
	render() {
		var slotColumns = [
			{
				key: "name",
				name: "Slot Name",
				onRender: (item, i) => {
					return item.name;
				},
			},
			{
				key: "selection",
				name: "Assignment",
				onRender: (item, i) => {
					let options = [];
					let valueId = 0;
					let callback = (e, val) => {};
					var slotAssignment = this.state.currentSlotAssignments.find(
						(el) => el.slotId == item.slotId
					);
					if (item.assetDefinitionId != null) {
						valueId = slotAssignment?.assetId;
						options =
							this.state.assets
								?.filter(
									(ass) =>
										ass.assetDefinitionId ==
										item.assetDefinitionId
								)
								.map((sd) => {
									return {
										key: sd.assetId,
										text: sd.assetAlias,
									};
								}) ?? [];
						callback = (e, val) => {
							this.setSlotAssignmentState(item.slotId, {
								slotId: item.slotId,
								parentSystemId: this.props.systemId,
								assetId: val.key,
							});
						};
					} else {
						valueId = slotAssignment?.systemId;
						options =
							this.state.systems
								?.filter(
									(sys) =>
										sys.systemDefinitionId ==
										item.systemDefinitionId
								)
								.map((sd) => {
									return { key: sd.systemId, text: sd.name };
								}) ?? [];
						callback = (e, val) => {
							this.setSlotAssignmentState(item.slotId, {
								slotId: item.slotId,
								parentSystemId: this.props.systemId,
								systemId: val.key,
							});
						};
					}
					return (
						<SearchableDropdown
							options={options}
							selectedKey={valueId}
							onChange={(e, val) => {
								callback(e, val);
							}}
						></SearchableDropdown>
					);
				},
			},
		];

		return (
			<Stack>
				<Stack verticalAlign="space-between">
					<TextField
						label="Name"
						value={this.state.name}
						onChange={(e, val) => {
							this.setState({ name: val });
						}}
					></TextField>
					<TextField
						label="Alias"
						value={this.state.alias}
						onChange={(e, val) => {
							this.setState({ alias: val });
						}}
					></TextField>
					<TextField
						label="Description"
						value={this.state.description}
						onChange={(e, val) => {
							this.setState({ description: val });
						}}
					></TextField>
					<DateTimePicker 
					label="Start Time" 
					horizontal 
					datetime={ new Date(this.state.startTime ?? new Date())} 
					onDateChanged=  { (startTime)=>this.setState({startTime})}></DateTimePicker>
					<DateTimePicker label="End Time"  horizontal datetime={ new Date(this.state.endTime ?? new Date())} onDateChanged=  { (endTime)=>this.setState({endTime})}></DateTimePicker>
					<h3>Slots</h3>
					{this.state.currentSlotAssignments && 
						<DetailsList
						columns={slotColumns}
						items={this.state?.systems?.find(sys=>sys.systemdId== this.state.systemId)?.systemDefinition?.slots ?? []}
					></DetailsList>
					}
					
				</Stack>
				<Stack horizontal horizontalAlign="end">
					<PrimaryButton
						text="Save"
						onClick={() => {
							this.saveSystem();
						}}
					></PrimaryButton>
					<DefaultButton
						text="Cancel"
						onClick={() => {
							this.props.onSaveSystem();
						}}
					></DefaultButton>
				</Stack>
			</Stack>
		);
	}
}

export default SystemForm;
