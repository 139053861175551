import React, { Component } from "react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { DatePicker } from "@fluentui/react";

type DateTimePickerProps={
	datetime?:Date,
	label?:string,
	horizontal?:boolean,
	defaultDatetime?:Date,
	minuteInterval?:number
	onDateChanged:(date:Date)=>void
}
type DateTimePickerState={
	date:Date,
	datetime?:Date,
	minutes:number,
	hours:number,
	ampm:number
}
class DateTimePicker extends Component<DateTimePickerProps,DateTimePickerState> {
	initDate = new Date(this.props.datetime) ?? new Date(this.props.defaultDatetime) ?? new Date();
	state:DateTimePickerState = {
		date: this.initDate,
		minutes: this.intify(this.initDate.getMinutes() / 15) * 15,
		hours:
			this.initDate.getHours() >= 12
				? this.initDate.getHours() - 12
				: this.initDate.getHours(),
		ampm: this.initDate.getHours() >= 12 ? 1 : 0,
	};
	componentDidMount() {
		if (this.state.date) {
			this.updateDateTime();
		}
	}
	componentDidUpdate(prevProps:DateTimePickerProps) {
		if (prevProps.datetime != this.props.datetime && this.props.datetime) {
			let initState = {
				date: this.props.datetime || new Date(),
				minutes:
					Math.round(
						(this.props.datetime || new Date()).getMinutes() / 15
					) * 15,
				hours:
					(this.props.datetime || new Date()).getHours() >= 12
						? (this.props.datetime || new Date()).getHours() - 12
						: (this.props.datetime || new Date()).getHours(),
				ampm:
					(this.props.datetime || new Date()).getHours() >= 12
						? 1
						: 0,
			};
			this.setState(initState);
		}
	}
	updateDateTime() {
		var x = new Date(this.state.date.getTime());
		x.setHours(0, 0, 0, 0);
		x.setHours(
			this.state.ampm == 0 ? this.state.hours : this.state.hours + 12
		);
		x.setMinutes(this.state.minutes);
		if (this.state.datetime) {
			this.props.onDateChanged(x);
		}
		this.setState({ datetime: x });
	}
	intify(val : string|number){
		if(typeof val =="number"){
			return Math.round(val)
		}
		else{
			return Math.round(parseInt(val))
		}
	}
	render() {
		let options = [];
		for (var i = 0; i < 12; i++) {
			options.push({ key: i, text: `${i == 0 ? 12 : i}` });
		}

		let minuteOptions = []
		var i = 0
		while(i <60 ){
			minuteOptions.push({ key: i, text: ("00"+i).slice(-2) })
			i+= this.props.minuteInterval??15
		}
		var timepicker = [
			<td key="time" style={{paddingBottom:6,verticalAlign:"bottom"}}>
				
				<Dropdown
					options={options}
					defaultSelectedKey={this.state.hours}
					onChange={(e, option) => {
						if(option){
							this.setState(
								{ hours: this.intify(option.key ?? 0) },
								this.updateDateTime
							);
						}
					}}
				/>
			</td>,
			<td key="min" style={{paddingBottom:6,verticalAlign:"bottom"}}>
				<Dropdown
					defaultSelectedKey={this.state.minutes}
					onChange={(e, option) => {
						if(option){
							this.setState(
								{ minutes: this.intify(option.key ?? 0) },
								this.updateDateTime
							);
						}
				
					}}
					options={minuteOptions}
				/>
			</td>,
			<td key="ampm"  style={{paddingBottom:6,verticalAlign:"bottom"}}>
				<Dropdown
					defaultSelectedKey={this.state.ampm}
					onChange={(e, option) => {
						if(option){
							this.setState(
								{ ampm: this.intify(option.key ?? 0) },
								this.updateDateTime
							);
						}
				
					}}
					
					options={[
						{ key: 0, text: "AM" },
						{ key: 1, text: "PM" },
					]}
				/>
			</td>,
		];
		var datepicker = (
			<td key="date" colSpan={3} >
				<DatePicker
					label={this.props.label}
					value={this.state.date}
					onSelectDate={(e) => {
						if(e){
							e.setHours(0, 0, 0, 0);
							this.setState({ date: e }, this.updateDateTime);
						}
					}}
				/>
			</td>
		);
		return (
			<div>
				<table style={{ margin: "0px" }}>
					<tbody>
						{this.props.horizontal ? (
							<tr style={{ verticalAlign: "top" }}>
								{datepicker}
								{timepicker}
							</tr>
						) : (
							<>
								<tr>{datepicker}</tr>
								<tr>{timepicker}</tr>
							</>
						)}
					</tbody>
				</table>
			</div>
		);
	}
}
export default DateTimePicker;
