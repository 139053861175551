const definition = (state={},action) =>{
    switch(action.type) {
        case 'DEFINITION':{
            state=action.payload??{}
            return state;
        }
        default:
            return state;
    }
}
export default definition;