import React from "react";

import {
	DefaultEffects,
	TextField,
	Panel,
	PanelType,
	ActionButton,
	SearchBox,
	mergeStyles,
	mergeStyleSets,
	Stack,
	Spinner,
	SpinnerSize,
	CommandBar,
	DetailsList,
	SelectionMode,
	PrimaryButton,
	Nav,
	Text,
	Separator,
	DefaultButton,
} from "@fluentui/react/";
import { Switch, Route, NavLink } from "react-router-dom";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { httpErrorHandler } from "../../util";
import SystemConfiguration from "./systems/SystemConfiguration";
import AssetConfiguration from "./assets/AssetConfiguration";
import AssetDefinitionConfiguration from "./assetdefinitions/AssetDefinitionConfiguration";
import SystemDefinitionConfiguration from "./systemdefinitions/SystemDefinitionConfiguration";
const iconClass = mergeStyles({
	fontSize: "2rem",
	// height: 50,
	// width: 50,
	// margin: '0 25px',
});
const classNames = mergeStyleSets({
	white: [iconClass],
	green: [{ color: "green" }, iconClass],
	red: [{ color: "red" }, iconClass],
});
const Link = (props) => {
	return <NavLink to={props.link.to}>{props.link.name}</NavLink>;
};
class ConfigurationPage extends React.Component {
	state = {
		filteredAssets: [],
		filteredSystemDefinitions: [],
		filteredAssetDefinitions: [],
		filteredSystems: [],
		tab: this.props.match?.params?.subpage ?? "defs",
	};
	componentDidMount() {
		window.document.title = "Configuration";
		this.loadAssetDefinitions();
		this.loadSystemDefinitions();
		this.loadSystems();
		if (this.props.assets.length == 0) {
			this.loadAssets();
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.assets) {
			this.setState({ assets: nextProps.assets }, () => {
				this.filter();
			});
		}
	}
	loadAssetDefinitions() {
		fetch("/api/ef/assetdefinitions/")
			.then((response) => {
				if (response.status == 401) {
					const { history } = this.props;
					if (history) history.push("/login");
					return null;
				} else if (response.status == 200) return response.json();
			})
			.then((data) => {
				if (data) {
					this.setState(
						{
							assetDefinitions: data,
							selectedAssetDefinition: data.find(
								(el) =>
									el.assetDefinitionId ==
									this.props.match?.params?.id
							),
							alarmsEditor:
								this.props.match?.params?.panelName == "alarms",
							eventEditor:
								this.props.match?.params?.panelName == "events",
							dataItemsEditor:
								this.props.match?.params?.panelName ==
								"dataitems",
						},
						() => {
							this.filter();
						}
					);
				}
			})
			.catch(httpErrorHandler);
	}
	loadSystemDefinitions() {
		fetch("/api/ef/systemdefinitions")
			.then((response) => {
				if (response.status == 401) {
					const { history } = this.props;
					if (history) history.push("/login");
					return null;
				} else if (response.status == 200) return response.json();
			})
			.then((data) => {
				if (data) {
					this.setState(
						{
							systemDefinitions: data,
						},
						() => {
							this.filter();
						}
					);
				}
			})
			.catch(httpErrorHandler);
	}
	loadAssets() {
		fetch("/api/ef/assets")
			.then(async (response) => {
				if (response.status == 401) {
					const { history } = this.props;
					if (history) history.push("/login");
					return;
				} else if (response.status == 200) return response.json();
			})
			.then((data) => {
				this.props.setAssets(data);
				this.setState({ assets: data }, () => {
					this.filter();
				});
			})
			.catch(httpErrorHandler);
	}
	loadSystems() {
		fetch("/api/ef/systems")
			.then((response) => {
				if (response.status == 401) {
					const { history } = this.props;
					if (history) history.push("/login");
					return null;
				} else if (response.status == 200) return response.json();
			})
			.then((data) => {
				if (data) {
					this.setState(
						{
							systems: data,
						},
						() => {
							this.filter();
						}
					);
				}
			})
			.catch(httpErrorHandler);
	}

	filter() {
		var filteredAssets =
			this.state.assets?.filter((ass) => {
				return (
					!this.state.filter ||
					ass?.assetAlias
						.toLowerCase()
						.includes(this.state.filter?.toLowerCase()) ||
					ass?.serialNumber
						.toLowerCase()
						.includes(this.state.filter?.toLowerCase()) ||
					ass.assetDefinition.assetDefinitionName
						.toLowerCase()
						.includes(this.state.filter?.toLowerCase())
				);
			}) ?? [];
		var filteredAssetDefinitions =
			this.state.assetDefinitions?.filter((ass) => {
				return (
					!this.state.filter ||
					ass?.assetDefinitionName
						?.toLowerCase()
						.includes(this.state.filter?.toLowerCase())
				);
			}) ?? [];
		var filteredSystemDefinitions =
			this.state.systemDefinitions?.filter((sd) => {
				return (
					!this.state.filter ||
					sd?.name
						?.toLowerCase()
						.includes(this.state.filter?.toLowerCase())
				);
			}) ?? [];
		var filteredSystems =
			this.state.systems?.filter((sd) => {
				return (
					!this.state.filter ||
					sd?.name
						?.toLowerCase()
						.includes(this.state.filter?.toLowerCase())
				);
			}) ?? [];
		this.setState({
			filteredAssets,
			filteredAssetDefinitions,
			filteredSystemDefinitions,
			filteredSystems,
		});
	}
	render() {
		if (this.state.assetDefinitions?.length > 0) {
			return (
				<div>
					{this.props.auth.valid ? (
						<div>
							<Stack horizontal>
								<Nav
									styles={[{ minWidth: 200 }]}
									// linkAs={Link}
									groups={[
										{
											name: "Assets",
											links: [
												{
													key: "ass",
													name: "Set up assets",
													onClick: () =>
														this.props.history.push(
															"/configuration/assets"
														),
												},
												{
													key: "ad",
													name: "Asset definitions",
													onClick: () =>
														this.props.history.push(
															"/configuration/assetdefinitions"
														),
												},
											],
										},
										{
											name: "Systems",
											links: [
												{
													key: "sys",
													name: "Set up system definitions",
													onClick: () =>
														this.props.history.push(
															"/configuration/systemdefinitions"
														),
												},
												{
													key: "sysd",
													name: "Configure new systems",
													onClick: () =>
														this.props.history.push(
															"/configuration/systems"
														),
												},
											],
										},
									]}
								></Nav>
								<Separator vertical></Separator>
								<Stack grow vertical>
									<h1>Configuration</h1>
									<Separator></Separator>
									<SearchBox
										placeholder="Search..."
										onChange={(e) =>
											this.setState(
												{
													filter:
														e?.target?.value ?? "",
												},
												this.filter
											)
										}
									/>

									<Switch>
										<Route path="/configuration/assetdefinitions">
											<AssetDefinitionConfiguration
												filteredAssetDefinitions={
													this.state
														.filteredAssetDefinitions
												}
											></AssetDefinitionConfiguration>
										</Route>
										<Route path="/configuration/systemdefinitions">
											<SystemDefinitionConfiguration
												filteredSystemDefinitions={
													this.state
														.filteredSystemDefinitions
												}
												systemDefinitions={
													this.state.systemDefinitions
												}
												assetDefinitions={
													this.state.assetDefinitions
												}
												onSystemDefinitionUpdated={() => {
													this.loadSystemDefinitions();
												}}
											></SystemDefinitionConfiguration>
										</Route>
										<Route path="/configuration/systems">
											<SystemConfiguration
												systemDefinitions={
													this.state.systemDefinitions
												}
												onSystemsUpdated={() => {
													this.loadSystems();
												}}
												systems={this.state.systems}
												assets={this.state.assets}
												filteredSystems={
													this.state.filteredSystems
												}
											></SystemConfiguration>
										</Route>
										<Route path="/configuration/assets">
											<AssetConfiguration
												filteredAssets={
													this.state.filteredAssets
												}
											></AssetConfiguration>
										</Route>
									</Switch>
								</Stack>
							</Stack>
						</div>
					) : (
						<div>Invalid Login</div>
					)}
				</div>
			);
		} else {
			return (
				<>
					<div
						style={{
							height: "94vh",
							position: "fixed",
							top: "50%",
							left: "50%",
							transform: "translateX(-50%)",
						}}
					>
						<Spinner size={SpinnerSize.large} />
					</div>
				</>
			);
		}
	}
}
function mapStateToProps({ auth, assets }) {
	return {
		auth: auth,
		assets: assets,
	};
}

export default compose(
	withRouter,
	connect(mapStateToProps, actions)
)(ConfigurationPage);
