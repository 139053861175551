import { getParameterByName, setParameterByName } from "../util";

const activeTab = (state = { index: getParameterByName("tab") ?? 0 }, action) => {
	switch (action.type) {
		case "CHANGE_TAB":

			var newState = { ...state };
			setParameterByName("tab",action.index)
			newState.index = action.index;
			return newState;
		// case 'DELETE_TAB':
		//   return state;
		case "SWITCH_TABS": {

			let { to } = action.payload;
			var newState = { ...state };
			setParameterByName("tab",to)
			newState.index = to;
			return newState;
		}
		default:
			return state;
	}
};

export default activeTab;
