import {
	Text,
	Panel,
	TextField,
	Stack,
	Icon,
	DefaultEffects,
	PanelType,
	PrimaryButton,
	mergeStyles,
	mergeStyleSets,
	Separator,
	ActionButton,
	DefaultButton,
	Dropdown,
	DetailsList,
	DetailsListLayoutMode,
	SelectionMode,
} from "@fluentui/react/";
import AlarmExpressionEditor from "../../../components/AlarmExpressionEditor";
import React from "react";
const iconClass = mergeStyles({
	// fontSize: 25,
	// height: 50,
	// width: 50,
	// margin: '0 25px',
});
const classNames = mergeStyleSets({
	white: [iconClass],
	green: [{ color: "green" }, iconClass],
	red: [{ color: "red" }, iconClass],
});

class AssetAlarmOverrides extends React.Component {
	state = {
		search: "",
		dataitems: [],
	};
	loadAlarmDefinitions() {
		fetch(
			`/api/assetDefinitions/${this.props.asset.assetDefinitionId}/alarmdefinitions`
		)
			.then((data) => data.json())
			.then((data) => this.setState({ alarmDefinitions: data }))
			.catch((err) => console.log(err));
	}
	loadDataItemDefinitions() {
		fetch(
			`/api/assetDefinitions/${this.props.asset.assetDefinitionId}/dataitemdefinitions`
		)
			.then((data) => data.json())
			.then((data) => this.setState({ dataitems: data }))
			.catch((err) => console.log(err));
	}
	loadAlarmOverrides() {
		fetch(`/api/ef/assets/${this.props.asset.assetId}/alarmoverrides`)
			.then((data) => data.json())
			.then((data) => this.setState({ overrides: data }))
			.catch((err) => console.log(err));
	}
	setAlarmOverride() {
		fetch(`/api/ef/alarmoverrides`, {
			method: "put",
			body: JSON.stringify([
				{
					assetId: this.props.asset.assetId,
					alarmDefinitionId: this.state.selectedAlarmDefinition
						.alarmDefinitionId,
					overrideMethod: this.state.overrideMethod,
					expression: this.state.overrideAlarmExpression,
				},
			]),
			headers: { "Content-Type": "application/json" },
		}).then(this.loadAlarmOverrides.bind(this));
	}
	alarmDefinitionRow(ad) {
		return (
			<tr
				onClick={() => {
					this.setState({ selectedAlarmDefinition: ad });
				}}
				style={{ boxShadow: DefaultEffects.elevation8 }}
			>
				<td>{ad.name}</td>
				<td>{ad.reason}</td>
				<td>{ad.severity}</td>
				<td>{ad.escalation}</td>
				<td>{ad.expiration}</td>
				<td>{ad.selfAck ? "true" : "false"}</td>
				<td>
					<Stack horizontal>
						<Icon
							className={iconClass}
							iconName="Edit"
							onClick={() => {
								this.setState({
									edit: true,
									changed: false,
									showForm: true,
									newAlarmName: ad.name,
									newAlarmFlee: ad.flee,
								});
							}}
						></Icon>
						<Icon
							className={iconClass}
							iconName="Delete"
							onClick={() => {
								this.deleteAlarmDefinition(
									ad.alarmDefinitionId
								);
							}}
						></Icon>
					</Stack>
				</td>
			</tr>
		);
	}
	render() {
		var showForm = this.state.showForm;
		return (
			<Panel
				isLightDismiss={true}
				isOpen={this.props.isOpen}
				onOpen={() => {
					this.loadAlarmDefinitions();
					this.loadDataItemDefinitions();
					this.loadAlarmOverrides();
				}}
				onDismiss={this.props.onDismiss}
				type={PanelType.medium}
				onRenderHeader={() => {
					return (
						<Stack horizontal>
							<h3
								style={{
									fontFamily: "Proxima Nova Bold",
									fontSize: 22,
								}}
							>
								{this.props.asset?.assetAlias.toUpperCase()}
							</h3>
							<pre> </pre>
							<h3
								style={{
									fontFamily: "Proxima Nova Thin",
									fontSize: 22,
								}}
							>
								ALARMS
							</h3>
						</Stack>
					);
				}}
			>
				<DetailsList
					items={this.state.alarmDefinitions ?? []}
					compact
					columns={[
						{
							key: "column1",
							name: "Name",
							fieldName: "name",
						},
						{
							key: "column2",
							name: "Reason",
							minWidth: 140,
							fieldName: "reason",
						},
						{
							key: "column3",
							name: "Severity",
							fieldName: "severity",
						},
					]}
					onActiveItemChanged={(item, index, ev) => {
						var override = this.state.overrides.find(
							(el) =>
								el.alarmDefinitionId == item.alarmDefinitionId
						);
						this.setState({
							overrideMethod: override?.overrideMethod,
							overrideAlarmExpression: override?.expression,
							selectedAlarmDefinition: item,
							showForm: true,
						});
					}}
					layoutMode={DetailsListLayoutMode.justified}
					selectionMode={SelectionMode.single}
				></DetailsList>
				<div hidden={!showForm}>
					<Separator>
						<Stack
							tokens={{ childrenGap: 5 }}
							horizontal
							onClick={() => {
								this.setState({ showForm: !showForm });
							}}
						>
							<p>Alarm Settings</p>
							<Icon
								iconName={
									showForm
										? "CollapseContentSingle"
										: "ExploreContentSingle"
								}
							/>
						</Stack>
					</Separator>
					<Stack tokens={{ childrenGap: 10 }}>
						<TextField
							disabled={true}
							label="Alarm Name"
							placeholder="Name..."
							value={this.state.selectedAlarmDefinition?.name}
						></TextField>
						<TextField
							disabled={true}
							label="Definition Expression"
							placeholder="1=1"
							value={
								this.state.selectedAlarmDefinition
									?.alarmExpression?.expression
							}
						></TextField>
						<Separator>Override</Separator>
						<Dropdown
							label="Override Method"
							selectedKey={this.state.overrideMethod}
							onChange={(e, val) => {
								this.setState({ overrideMethod: val.key });
							}}
							options={[
								{
									key: 0,
									text: "No override (use definition only)",
								},
								{
									key: 1,
									text:
										"Override only (ignore alarm calculation)",
								},
								{
									key: 2,
									text:
										"Extend (alarm calculation OR override)",
								},
								{
									key: 3,
									text:
										"Combine (alarm calculation AND override)",
								},
							]}
						></Dropdown>
						<Separator>Expression</Separator>
						<AlarmExpressionEditor
							key={
								"flee" +
								this.state.selectedAlarmDefinition
									?.alarmDefinitionId
							}
							alarmDefinition={this.state.selectedAlarmDefinition}
							dataItems={this.state.dataitems}
							expression={this.state.overrideAlarmExpression}
							onValidated={(expression) => {
								this.setState({
									changed: true,
									overrideAlarmExpression: JSON.stringify(
										expression
									),
								});
							}}
						></AlarmExpressionEditor>
						<Stack horizontal>
							{this.state.changed && (
								<PrimaryButton
									text="Save expression"
									onClick={() => {
										this.setAlarmOverride();
									}}
								></PrimaryButton>
							)}
							<DefaultButton
								onClick={() => {
									this.setState({
										edit: false,
										changed: false,
										showForm: false,
									});
								}}
							>
								Cancel
							</DefaultButton>
						</Stack>
					</Stack>
				</div>
			</Panel>
		);
	}
}
export default AssetAlarmOverrides;
