import { useMemo, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";

type ActivityItem = {
  activity:string,
  depth:number,
  startTime:string,
  endTime:string,
  hours:number,
  hoursString:string
}

type SchedulePieChartProps={
  data:ActivityItem[],
  colorMap:any
}

type PieChartDatum = {
  title:string,
  value:number,
  color:string
}

  
export function SchedulePieChartSimple(props:SchedulePieChartProps){
    let [hoveredIndex,setHoveredIndex] = useState(null)
    let summary:PieChartDatum[] = useMemo(()=>{
      let activities = props.data.map(a=>a.activity).filter((el,i,arr)=>i==arr.indexOf(el))
      return activities.map((activity,i)=>{
        return {
          title:activity,
          value:props.data.filter(r=>r.activity == activity).reduce((val,el)=>val+=el.hours,0),
          color:props.colorMap[activity]
        } as PieChartDatum
      })
    },[props.data])

    return(<div style={{display:"flex",justifyContent:"center",flexWrap:"wrap"}}>
      <div style={{display:"flex",columnGap:20,flexDirection:"row",alignContent:"center",flexWrap:"wrap",justifyContent:"center",maxWidth:"500px"}}> 
        {summary.map((a,i)=>(
        <div 
        onMouseOver={()=>setHoveredIndex(i)}  
        onMouseOut={()=>setHoveredIndex(null)} 
        style={{backgroundColor:i==hoveredIndex ? "#fff8":"#0000",gap:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
          <span style={{width:20,height:20,margin:3,backgroundColor:a.color}}></span>
          <span>{a.title}</span>
          <span> {("0"+Math.floor(a.value)).slice(-2)+":"+("00"+Math.floor(a.value%1*60)).slice(-2)}</span>
        </div>))}
      </div>
      <div style={{height:200,minWidth:400}}>
      <PieChart
      segmentsShift={(index) => (index === hoveredIndex ? 2 : 0)}
      onMouseOver={(ev,i)=>setHoveredIndex(i)}
      onMouseOut={()=>setHoveredIndex(null)}
      animate={true}
        data={summary}
        label={({ dataEntry }) =>  dataEntry.title + " " + (Math.floor(dataEntry.value))+":"+("00"+Math.floor(dataEntry.value%1*60)).slice(-2)}
        labelStyle={(index) => ({
          fill: "white",
          backgroundColor:summary[index].color,
          fontSize: '5px',
          fontFamily: 'Proxima Nova',
        })}
        radius={42}
        labelPosition={112}
      />
      </div>
     
      </div>)
}