import React, { Component } from "react";
import { connect } from "react-redux";
import { getAssetIdFromSystem } from "../../../../util/index";
import variables from "../../styles/variables.scss";
class Indicator extends Component {
	static defaultProps = {
		defaultValue: Math.floor(Math.random() * 100),
		defaultBackground: variables.tileBackground,
		decimals: "2",
		fontSize: "14",
	};

	componentDidMount() {}

	defaultConfig = () => {
		const { fontSize, defaultBackground } = this.props;
		return (
			<div
				className="indicator"
				style={{
					width: "100%",
					height: "100%",
					textAlign: "center",
					backgroundColor: defaultBackground,
					fontSize: `${fontSize}px`,
				}}
			>
				<span>Indicator</span>
				<br />
				<span>{`Value: XX`}</span>
			</div>
		);
	};

	config = () => {
		try {
			const {decimals, fontSize, labelColor, valueColor, backgroundColor, timestamp,UoM } =
				this.props.properties;
			
			var dataItem = { ...this.props.properties.dataItem };
			var assetId = getAssetIdFromSystem(
				null,
				this.props.properties.slotPath ?? []
			);
			Object.assign(
				dataItem,
				this.props.currentData[assetId][dataItem.dataItemId]
			);
			const showValue = dataItem?.value?.toFixed(decimals ?? 0 ) ?? "- -";
			return (
				<div
					
					className="indicator"
					style={{
						display:"flex",
						flexDirection:"column",
						alignItems:"center",
						justifyContent:"center",
						alignContent:"center",
						width: "100%",
						height: "100%",
						textAlign: "center",
						backgroundColor: `${backgroundColor}`,
						fontSize: `${fontSize}px`,
					}}
				>
					<div style={{color: `${labelColor}` }}>
						{dataItem.label}
					</div>
					
					<div style={{flexGrow:1, color: `${valueColor}`,display:"flex",
						flexDirection:"column",
						alignItems:"center",
						overflow:"hidden",
						justifyContent:"center",
						alignContent:"center"}}>

						<svg
							width="100%"
							height="100%"
							viewBox="0 0 500 75"
							dominantBaseline="middle"
							preserveAspectRatio="xMinYMid meet"
						>
								<text
								x="250"
								y="37.5"
								dominantBaseline="middle"
								textAnchor="middle"
								fontSize="55"
								fill={valueColor ?? "#fff"}
								>	 {dataItem.isString
									? dataItem.stringValue
									: `${showValue} ${UoM || dataItem.unit || ""}`}</text>
							</svg>
						
					</div>
					
					
					<div  style={{color: `${labelColor}` }}>
						{timestamp
							? new Date(dataItem.timestamp).toLocaleString()
							: ""}
					</div>
				</div>
			);
		} catch (e) {
			return <div>{e.toString()}</div>;
			return this.defaultConfig();
		}
	};

	render() {
		const { type } = this.props.properties;
		return type.default ? this.defaultConfig() : this.config();
	}
}

function mapStateToProps({ currentData }) {
	return { currentData };
}

export default connect(mapStateToProps)(Indicator);
