import React, { Component } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import Tile from "./Tile";

const ResponsiveGridLayout = WidthProvider(Responsive);

class Main extends Component {
	state = {};
	componentDidMount() {
		if (this.props.tabIndex) {
			this.props.changeTab(this.props.tabIndex);
		}
		this.props.hideNav();
	}
	componentWillUnmount() {
		this.props.showNav();
	}
	componentDidUpdate(nextProps){
		if(this.props.ui?.dashboardWidth != nextProps.ui?.dashboardWidth){
			setTimeout(() => window.dispatchEvent(new Event("resize")), 25);
		}
	}
	renderGrids = (tab, i) => {
		const { tiles, layouts, activeTab } = this.props;
		const isCurrentTab =
			activeTab.index == i || document.webkitIsFullScreenl;
		const height = isCurrentTab ? "" : "0px";
		const visibility = isCurrentTab ? "visible" : "hidden";
		return (
			<div
				style={{
					width: this.props.ui.dashboardWidth,
					height,
					visibility,
					overflow: "hidden",
				}}
				key={i}
			>
				<ResponsiveGridLayout
					margin={[4, 4]}
					width={this.props.ui.dashboardWidth}
					//verticalCompact={false}
					//key={`tab${tab.index}${this.props.ui.viewOnly?"view":"edit"}`}
					isDraggable={!this.props.ui.viewOnly}
					isResizable={!this.props.ui.viewOnly}
					breakpoints={{ lg: 1200, sm: 768, xxs: 1 }}
					cols={{ lg: 96, sm: 96, xxs: 6 }}
					rowHeight={10}
					layouts={layouts[i]}
					onLayoutChange={(layout, layouts) => {
						if (isCurrentTab) {
							this.handleLayoutChange(layout, layouts, i);
						}
					}}
				>
					{tiles[i] && tiles[i].map(this.renderTile)}
				</ResponsiveGridLayout>
			</div>
		);
	};

	renderTile = (tile) => {
		const { ui, layouts, activeTab } = this.props;
		const noResize = ui.viewOnly ? "no-resize" : "";
		const fullGrid = { x: 0, y: 0, w: 96, h: 4 };
		var grid = this.state.layout?.find((el) => el.i == tile.id);
		tile.grid = grid;
		return (
			<div data-grid={fullGrid} key={tile.id} className={noResize}>
				<ErrorBoundary>
					<Tile grid={grid} tile={tile} />
				</ErrorBoundary>
			</div>
		);
	};

	handleLayoutChange = (layout, layouts, index) => {
		this.props.saveLayouts(layouts, index);
		this.props.changeToFirstValidTab();
		this.setState({ layout: layout });
	};

	render() {
		const { tabIndex, tabs, activeTab } = this.props;
		if (tabIndex) {
			return this.renderGrids(tabs[tabIndex], tabIndex);
		}
		return <>{tabs && tabs.map(this.renderGrids)}</>;
	}
}
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <h1>Something went wrong.</h1>;
		}

		return this.props.children;
	}
}
function mapStateToProps({ activeTab, layouts, tiles, ui, tabs }) {
	return { activeTab, layouts, tiles, ui, tabs };
}

export default connect(mapStateToProps, actions)(Main);

// breakpoints={{lg: 1200, sm: 768, xs: 480, xxs: 1}}
// cols={{lg: 96, md: 96, sm: 48, xs: 24, xxs: 1}}
