import React from "react";
import { ActionButton, DetailsList, SelectionMode } from "@fluentui/react/";
import AssetDataPanel from "../../../components/Panels/AssetDataPanel";
import AssetAlarmOverrides from "./AssetAlarmOverrides";
export default class AssetConfiguration extends React.Component {
	state = {};
	render() {
		return (
			<>
				<DetailsList
					compact
					selectionMode={SelectionMode.none}
					items={this.props.filteredAssets}
					columns={[
						{
							fieldName: "assetAlias",
							name: "Alias",
							key: "name",
							data: "string",
							onRender: (item) => {
								return (
									<span
										style={{
											height: "100%",
											verticalAlign: "middle",
										}}
									>
										{item.assetAlias}
									</span>
								);
							},
						},
						{
							fieldName: "serialNumber",
							name: "Serial Number",
							key: "serial",
							data: "string",
						},
						{
							fieldName: "assetDefinition",
							name: "Asset Definition",
							key: "ad",
							data: "string",
							onRender: (item) => {
								return (
									<span
										style={{
											height: "100%",
											verticalAlign: "middle",
										}}
									>
										{
											item.assetDefinition
												.assetDefinitionName
										}
									</span>
								);
							},
						},
						{
							key: "attributes",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "CustomList",
										}}
										onClick={() => {
											this.setState({
												assetEditor: true,
												selectedAsset: item,
											});
										}}
									>
										Edit Asset
									</ActionButton>
								);
							},
						},
						{
							key: "alarm",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "AlertSettings",
										}}
										onClick={() => {
											this.setState({
												assetAlarmsEditor: true,
												selectedAsset: item,
											});
										}}
									>
										Alarm Overrides
									</ActionButton>
								);
							},
						},
						{
							key: "dataitems",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "LineChart",
										}}
										onClick={() => {
											this.setState({
												assetDataEditor: true,
												selectedAsset: item,
											});
										}}
									>
										Data items
									</ActionButton>
								);
							},
						},
						{
							key: "delete",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "Delete",
										}}
										onClick={() => {}}
									>
										Delete
									</ActionButton>
								);
							},
						},
					]}
				></DetailsList>
				<AssetAlarmOverrides
					isOpen={this.state.assetAlarmsEditor}
					onDismiss={(a, b) => {
						this.setState({ assetAlarmsEditor: false });
					}}
					asset={this.state.selectedAsset}
				></AssetAlarmOverrides>
				<AssetDataPanel
					isOpen={this.state.assetDataEditor}
					onDismiss={(a, b) => {
						this.setState({ assetDataEditor: false });
					}}
					asset={this.state.selectedAsset}
				></AssetDataPanel>
			</>
		);
	}
}
