import { ActionButton, getTheme, IconButton, Modal, Spinner } from '@fluentui/react'
import { useEffect, useState, useRef, useMemo} from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { Route, NavLink } from "react-router-dom";
import { getNumericAttribute, getParameterByName, guid, indexMatch } from '../../util';
import SnubWellKPIs from '../dashboard/components/visuals/StateReport/SnubWellKPIs';
import { TooltipTypes } from '../dashboard/components/visuals/uPlot/Plugins/TooltipPlugin';
import UPlotComponent from '../dashboard/components/visuals/uPlot/uPlot';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { PaginatedTable } from '../../components/PaginatedTable/PaginatedTable';
import { MakeWellForm } from '../DeepWellJobProvisioner/MakeWellForm';
import { merge2DArraysWithFirstArrayKey, sort2DarrayByFirstArray } from '../dashboard/components/visuals/uPlot/util';
 
mapboxgl.accessToken = "pk.eyJ1Ijoia2JpZWJlciIsImEiOiJjazlrZ3c4OHkwNDY2M2RtdWIzZjczeW5zIn0.Js07hZZ-_djZb4RBF0zqnA";

const queryClient = new QueryClient()
const primaryHCUSlotId = 31
const depthId = 19
const plugCountId = 39
const jobFunctionId = 148

export default function Jobs(){
    const { isLoading, error, data } = useQuery('jobs', () =>
    fetch('/api/ef/dws/jobs').then(res =>
      res.json()
    )
  )
  if(isLoading || !data){
      return <Spinner></Spinner>
  }
    return (
        <QueryClientProvider client={queryClient}>
             <MakeWellForm></MakeWellForm>
            <table>
                <tbody>
                {data.map(j=><JobHUD job = {j}></JobHUD>)}
                </tbody>
         
            </table>
        
        </QueryClientProvider>
      )
}

function JobHUD({job}){
    const hcu = job.currentSlotAssignments.find(sa=>sa.slotId ==primaryHCUSlotId).asset
    const depth = useSelector((state)=>state.currentData?.[hcu.assetId]?.[depthId])

    let [addWell,setAddWell] = useState(false)

    const plugCount = useSelector((state)=>state.currentData?.[hcu.assetId]?.[plugCountId])
    const jobFunction = useSelector((state)=>state.currentData?.[hcu.assetId]?.[jobFunctionId])
    const jobDays = ((new Date() - new Date(job.startTime))/(24*3600*1000)).toFixed(1)
    const jobTotalDays = ((new Date(job.endTime) - new Date(job.startTime))/(24*3600*1000)).toFixed(1)

    let [showDeets,setShowDeets] = useState(false)

    const map = useMemo(()=>{return <JobLocation job={job}></JobLocation>},[job])
    return (
    <>
    <tr style={{padding:10}} title={JSON.stringify(job)}>
        <td><IconButton iconProps={{iconName:showDeets?"PaddingTop":"PaddingBottom"}} onClick={()=>{setShowDeets(!showDeets)}}></IconButton></td>
        <td>{job.name} ({job.salesForceId})</td>
        <td><div>{hcu.assetAlias} </div></td>
        <td>
        <div title={JSON.stringify(depth)}>
            <span>Currently 
                </span>
                <JobFunctionIndicator jobFunction={jobFunction}></JobFunctionIndicator>
                <span>at {depth?.value.toFixed(0)} ft</span>
                </div>
        </td>
       
       <td>
       <SnubWellKPIs scale={.4} onlyProgress={true} system={job} plugCount={plugCount?.value} depth = {depth?.value} filteredSchedule={job.jobSchedules} ></SnubWellKPIs>

       </td>
       <td> <NavLink  to={`/systems/${job.systemId}/dashboard`}>
            <ActionButton
																
            iconProps={{
                iconName:
                    "ViewDashboard",
            }}
            text="Dashboard"
        ></ActionButton>
    </NavLink></td>
    <td> <a target="_blank" href={`https://deepwellservices.lightning.force.com/lightning/r/WorkOrder/${job.salesForceId}/view`}>
            <ActionButton
																
            iconProps={{
                iconName:
                    "ViewDashboard",
            }}
            text="Edit in salesforce"
        ></ActionButton>
    </a></td>
    </tr>
    {showDeets &&
    <tr>
   
   <td  colSpan={"100%"}>
        <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
           
         <div style={{width:300}}>
                {map}
                <WellBoreIndicator 
                well={job.name} 
                depth={depth?.value} 
                kd = {getNumericAttribute("Kill Depth (ft)",10000,hcu)} 
                td={getNumericAttribute("Total Depth (ft)",20000,hcu)}></WellBoreIndicator>
        </div>
        

        <div style={{width:600}}>
        <ScheduleChart schedule={job.jobSchedules}></ScheduleChart>
        </div>
       
        <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
            <div>
                <h3>Attributes</h3>
            <table>
                <tbody>
                    {job.attributes.map(att=><tr><td>{att.attributeName}</td><td>{att.attributeValue}</td></tr>)}
                </tbody>
            </table>
            </div>
            <div>
            <h3>Files</h3>
            <table>
                <tbody>
                    {job.files.map(f=><tr><td><a href="#" onClick={() => {
												fetch(
													`/api/files/${f.fileId}/download`
												).then(function (t) {
													return t
														.blob()
														.then((b) => {
															var a = document.createElement(
																"a"
															);
															a.href = URL.createObjectURL(
																b
															);
															a.setAttribute(
																"download",
																f.filename
															);
															a.click();
														});
												});
											}}>{f.filename}</a></td><td>{formatSize(f.length)}</td></tr>)}
                </tbody>
            </table>
            </div>
            
        </div>
       
        <div>
            <PaginatedTable title="Wells" items={job.wells}></PaginatedTable>
            <ActionButton onClick={()=>setAddWell(true)}  text="Add Well"></ActionButton>
            <Modal isOpen={addWell}>
                <MakeWellForm></MakeWellForm>
            </Modal>
        </div>
        
        
        
        </div></td>
    </tr>}
    </>)
 
}

function JobFunctionIndicator({jobFunction}){
    var jobFunctionString = ""
  switch( jobFunction?.value ) { 
      case 0: jobFunctionString = 'Tripping In';break;
      case 1: jobFunctionString = 'Circulating';break; 
      case 2: jobFunctionString = 'Drill';break; 
      case 3: jobFunctionString = 'Washdown';break;
        case 4: jobFunctionString = 'Other';break;
        case 5: jobFunctionString = 'Tripping Out';break;
        case 6: jobFunctionString = 'Tripping in production';break;    
        case 7: jobFunctionString = 'BOP Pressure Test';break;
        case 8: jobFunctionString = 'Ram Change';break;
        case 9: jobFunctionString = 'Maintenance';break; 
        case 10: jobFunctionString = 'Rig Up';break;
        case 11: jobFunctionString = 'Rig Over';break;
        case 12: jobFunctionString = 'Rig Down';break;
        default: jobFunctionString='N/A';break;
    }
    return <span>{" "+jobFunctionString+" "}</span>
}

function WellBoreIndicator({well,depth,kd,td}){
 const id = guid()
    var dur = indexMatch(depth,[0,kd,td],[0,40,100])
    return (
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
    <path id={id} style={{
        strokeWidth: 30, 
        strokeLinecap: "round",
         strokeLinejoin: "round",
            stroke: "rgb(200, 200, 200)",
            fillOpacity: 0,
            paintOrder: "fill",
            strokeOpacity:" 0.63",
            strokeDashoffset: "-12px"}}
            d="M 29.517 48.767 L 29.092 160.874 C 29.092 160.874 21.887 218.049 100.719 222.534 C 179.549 227.019 358.402 230.942 358.402 230.942 L 452.915 235.426"/>
            <text style={{fontSize: "45px",
            fill: "red",
            dominantBaseline: "central"}}>
                <textPath href={`#${id}`} startOffset={`${dur}%`}>⬤</textPath>

            </text>
    </svg>)
}
function JobLocation({job}){
   
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(job.attributesObject.longitude ?? -90);
    const [lat, setLat] = useState( job.attributesObject.latitude ?? 30);
    const [zoom, setZoom] = useState(9);

  
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom
        });
        const marker = new mapboxgl.Marker()
        .setLngLat([lng,lat])
        .addTo(map.current);
        });
        return (
            <div>
            <div ref={mapContainer} style={{height:300}} />
            </div>
            );
}
export const colors = [
	"#3c0091",
	"#003891",
	
	"#bb00ff",
	"#0f9100",
	"#ff8800",
	"#0051ff",
	"#99ff00",
	"#ffcc00",
	"#008c91",
	"#f2ff00",
	"#99ff00",
	"#00ffa6",
	"#660091",
	"#04ff00",
	"#ffcc00",
	"#00ffa6",

    "#660091",
	"#00ffff",
	"#ff0000",
	"#ff00fb",
	"#8000ff",
	"#00aaff",
	"#009155",
	"#916800",
	"#912e00",
];
export function ScheduleChart({schedule,colorMap,projection}){
    const randomColor = ()=>"#"+Math.floor(Math.random()*16777215).toString(16);
    var palette= getTheme().palette
    let fontColor = palette.black;
    let bgColor = palette.neutralLight;

    let [plotData,activities] = useMemo(()=>{
        let sTimes = schedule.map(s=>new Date(s.startTime).getTime()/1000)
        let sDepths = schedule.map(s=>s.depth)
        let pTimes = projection.map(p=>new Date(p.timestamp).getTime()/1000)
        let pDepths = projection.map(s=>s.value)
        let sData = [sTimes,sDepths,sTimes.map(s=>null)]
        let pData = [pTimes,pDepths.map(s=>null),pDepths]
        let data = merge2DArraysWithFirstArrayKey(sData,pData)
        data = sort2DarrayByFirstArray(data)
        console.log(data)
        let activities = schedule.map(s=>s.activity).filter((el,i,arr)=>arr.indexOf(el) == i)
        let activityData=activities.map(activity=>{
            return data[0].map(t=>{
                var isInRange=
                schedule.find(s=>s.activity == activity && (new Date(s.startTime).getTime()/1000 == t || new Date(s.endTime).getTime()/1000 == t))
    
                return isInRange ?2:null
            })
        })
        let plotData= [...data,...activityData]
        return [plotData,activities]
    },[schedule])
  
    let opts = useMemo(()=>{
        return {
            width: 400,
            height: 250,
            fill:bgColor,
            legend:{show:false},
            cursor: {
                fill: bgColor,
                drag: { x: true, y: true, uni: 50 },
                bind: {
                    mousedown: (u, targ, handler) => {
                        return e => {
                            if (e.ctrlKey && e.altKey) {
                                let tag =  new Date(1000* u.posToVal( e.offsetX, 'x'))
                                console.log(tag)
                                if(window.confirm("Would you like to set the job START time to "+tag.toJSON())){

                                    fetch(
                                        `/api/assets/${getParameterByName("assetId")}/attributes`,
                                        {
                                            method: "POST",
                                            body: JSON.stringify(
                                                [{attributeName:"Job start time",attributeValue:tag.toJSON()}]
                                            ),
                                        }
                                    ).then(()=> window.location.href = ""+window.location.href)
                                }
                                else if(window.confirm("Would you like to set the job END time to "+tag.toJSON())){

                                    fetch(
                                        `/api/assets/${getParameterByName("assetId")}/attributes`,
                                        {
                                            method: "POST",
                                            body: JSON.stringify(
                                                [{attributeName:"Job end time",attributeValue:tag.toJSON()}]
                                            ),
                                        }
                                    ).then(()=> window.location.href = ""+window.location.href)
                                }
                               
                             }
                            
                            handler(e);
                        }
                    }
                    
                    }
                },
          
            series: [
                {},
                {
                    label: "Depth",
                    points:{show:false},
                    scale: "ft",
                    stroke: "white",
                    spanGaps:true,
                    tooltip:(t)=>{
                        let val = schedule.find(s=>t >= new Date(s.startTime).getTime()/1000 && t < new Date(s.endTime).getTime()/1000)
                        if(val){
                            return val.activity
                        }
                        
                    }
                },
                {
                    label: "Projected Depth",
                    points:{show:false},
                    scale: "ft",
                    dash:[5,5],
                    spanGaps:true,
                    stroke: "white",
                    // tooltip:(t)=>{
                    //     return `Predicted depth at this time: ${t} ft`
                        
                    // }
                },
                // ...activities.map((a,i)=>{
                //     return{
                //         label:a,
                //         scale:"fill",
                //         fill:colors[i]+"55",
                //         spanGaps:false,
                //         value:()=>a
                //     }
                // })
            ],
            axes: [
                {stroke:"White",grid:{stroke:"#0000"}},
                {
                    stroke:"White",
                    grid:{stroke:"#0000"},
                    scale: "ft",
                }
            ],
            hooks:{
                draw:[(u)=>{

                    schedule.forEach(event=>{
                        let color = colorMap[event.activity]  ?? colors[activities.indexOf(event.activity)] 
                        var start = new Date(event.startTime)/1000
                        var end =new Date(event.endTime)/1000
                        var startPx = u.valToPos(start,"x",true)
                        var endPx = u.valToPos(end,"x",true)
                    
                        u.ctx.save();
                        
                        u.ctx.beginPath();
                        u.ctx.rect(startPx, 1, endPx-startPx, u.over.clientHeight -2); 
                        u.ctx.lineWidth = 2
                        u.ctx.globalAlpha=0.1
                        u.ctx.setLineDash([3, 3]);
                        u.ctx.strokeStyle="white"
                        u.ctx.fillStyle=color;
                        u.ctx.globalAlpha=.4
                        //u.ctx.stroke(); // draw stroke of the shape
                        
                        u.ctx.fill();   // fill the shape
                        u.ctx.globalAlpha=1
                        
                        if(event.cause){
                            u.ctx.fillStyle="white"
                            u.ctx.textAlign = "center";
                            var px = (Math.min(endPx,u.ctx.canvas.clientWidth)+Math.max(0,startPx))/2
                            u.ctx.fillText(event.cause, px, 15);
                        }
                        
                        u.ctx.restore();
                        
                    })
                    
                }],
            },
            plugins:[TooltipTypes.BlockAuto()],
            scales:{
                x:{
                    auto:true
                },
                ft:{
                   // ori:0,
                    dir:-1,
                    auto:true
                },
                fill:{
                    auto:false,
                    range:[0,1]
                }
            }
        };
    },[activities,plotData]) 
    
    return <div style={{height:250,width:"100%"}}>
        <UPlotComponent data={plotData} opts={opts}/>
        </div>
}

function formatSize(len) {
    var sizes = ["B", "KB", "MB", "GB", "TB"];
    var order = 0;
    while (len >= 1024 && order < sizes.length - 1) {
        order++;
        len = len / 1024;
    }

    // Adjust the format string to your preferences. For example "{0:0.#}{1}" would
    // show a single decimal place, and no space.
    return `${len?.toFixed(1)} ${sizes[order]}`;
}
