import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Panel,
	Stack,
	Separator,
	PanelType,
	ActionButton,
	DetailsList,
	SelectionMode,
	mergeStyleSets,
	DetailsListLayoutMode,
} from "@fluentui/react";
import * as actions from "../../actions/index";
class FilePanel extends Component {
	state = {};

	loadFiles() {
		fetch(`/api/ef/assets/${this.props.system.assetId}/files`)
			.then((res) => res.json())
			.then((json) => this.setState({ files: json }));
	}
	formatSize(len) {
		var sizes = ["B", "KB", "MB", "GB", "TB"];
		var order = 0;
		while (len >= 1024 && order < sizes.length - 1) {
			order++;
			len = len / 1024;
		}

		// Adjust the format string to your preferences. For example "{0:0.#}{1}" would
		// show a single decimal place, and no space.
		return `${len?.toFixed(1)} ${sizes[order]}`;
	}
	render() {
		return (
			<Panel
				isLightDismiss
				type={PanelType.medium}
				isOpen={this.props.isOpen}
				onDismiss={this.props.hideFilePanel}
				onOpen={this.loadFiles.bind(this)}
			>
				<Stack>
					<Separator>Upload a file</Separator>
					<div
						style={{
							textAlign: "center",
							objectFit: "contain",
							border: "1px dashed white",
							width: "100%",
							lineHeight: "100px",
							height: "100px",
						}}
						onDragOver={(e) => {
							e.preventDefault();
						}}
						onDrop={(e) => {
							e.preventDefault();
							var items = e.dataTransfer.items;
							let formData = new FormData();
							for (var i = 0; i < items.length; i++) {
								formData.append("file", items[i].getAsFile());
							}
							this.setState({ loading: true });
							fetch(
								`/api/ef/assets/${this.props.system.assetId}/files`,
								{ method: "post", body: formData }
							).then(() => {
								this.setState({ loading: false });
								this.loadFiles();
							});
						}}
					>
						Drop files here
					</div>
					<Separator>Existing files</Separator>
					<DetailsList
						items={this.state.files ?? []}
						compact
						columns={[
							{
								key: "column1",
								name: "File name",
								isPadded: true,
								fieldName: "filename",
							},
							{
								key: "column2",
								name: "Uploaded",
								minWidth: 140,
								fieldName: "timestamp",
								isSorted: true,
								isSortedDescending: true,
								onRender: (item) => {
									return (
										<p>
											{new Date(
												item.timestamp + "Z"
											).toLocaleString()}
										</p>
									);
								},
							},
							{
								key: "column3",
								name: "Size",
								minWidth: 140,
								onRender: (item) => {
									return (
										<p>{this.formatSize(item.length)}</p>
									);
								},
							},
							{
								key: "column4",
								name: "",
								minWidth: 140,
								onRender: (item) => {
									return (
										<ActionButton
											className={classNames.fileIconImg}
											onClick={() => {
												fetch(
													`/api/files/${item.fileId}/download`
												).then(function (t) {
													return t
														.blob()
														.then((b) => {
															var a = document.createElement(
																"a"
															);
															a.href = URL.createObjectURL(
																b
															);
															a.setAttribute(
																"download",
																item.filename
															);
															a.click();
														});
												});
											}}
											iconProps={{ iconName: "Download" }}
										>
											Download
										</ActionButton>
									);
								},
							},
						]}
						layoutMode={DetailsListLayoutMode.justified}
						selectionMode={SelectionMode.none}
					></DetailsList>
				</Stack>
			</Panel>
		);
	}
}
function mapStateToProps({ system, ui }) {
	return {
		system: system,
		isOpen: ui.filePanel,
	};
}
export default connect(mapStateToProps, actions)(FilePanel);

const classNames = mergeStyleSets({
	fileIconHeaderIcon: {
		padding: 0,
		fontSize: "1.2rem",
	},
	fileIconCell: {
		textAlign: "center",
		selectors: {
			"&:before": {
				content: ".",
				display: "inline-block",
				verticalAlign: "middle",
				height: "100%",
				width: "0px",
				visibility: "hidden",
			},
		},
	},
	fileIconImg: {
		verticalAlign: "middle",
		maxHeight: "16px",
		maxWidth: "16px",
	},
	controlWrapper: {
		display: "flex",
		flexWrap: "wrap",
	},
	exampleToggle: {
		display: "inline-block",
		marginBottom: "10px",
		marginRight: "30px",
	},
	selectionDetails: {
		marginBottom: "20px",
	},
});
const controlStyles = {
	root: {
		margin: "0 30px 20px 0",
		maxWidth: "300px",
	},
};
