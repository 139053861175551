import { Checkbox } from '@fluentui/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
class MapForm extends Component {
  state = {
    showAll: false
  }

  componentDidMount() {
    const { tile } = this.props.ui.modal;
    if(tile) {
      const { showAll } = tile.properties;
      this.setState({
        showAll
      });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const {addData} = this.props;
    const value = e.target.checked ? e.target.checked : e.target.value;
    this.setState({[e.target.name]: value}, () => {
      addData(this.state);
    });
  }

  render () {
    const { showAll } = this.state;
    return (
      <section className='component-form'>
        <Checkbox checked={showAll} onChange={this.handleChange} name='showAll' />
        <label htmlFor='showAll' className='p-checkbox-label'>Show All Assets</label>
      </section>
    )
  }
}

function mapStateToProps({ui}) {
  return {ui}
}

export default connect(mapStateToProps)(MapForm);
