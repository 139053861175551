import {
	Text,
	Panel,
	TextField,
	Stack,
	Icon,
	DefaultEffects,
	PanelType,
	PrimaryButton,
	mergeStyles,
	mergeStyleSets,
	Separator,
	ActionButton,
	DefaultButton,
} from "@fluentui/react/";
import AlarmExpressionEditor from "../../../components/AlarmExpressionEditor";
import React from "react";
const iconClass = mergeStyles({
	// fontSize: 25,
	// height: 50,
	// width: 50,
	// margin: '0 25px',
});
const classNames = mergeStyleSets({
	white: [iconClass],
	green: [{ color: "green" }, iconClass],
	red: [{ color: "red" }, iconClass],
});

class AlarmDefinitionsPanel extends React.Component {
	state = {
		search: "",
		dataitems: [],
	};
	loadAlarmDefinitions() {
		fetch(
			`/api/assetDefinitions/${this.props.assetDefinition.assetDefinitionId}/alarmdefinitions`
		)
			.then((data) => data.json())
			.then((data) => this.setState({ alarmDefinitions: data }))
			.catch((err) => console.log(err));
	}
	loadDataItemDefinitions() {
		fetch(
			`/api/assetDefinitions/${this.props.assetDefinition.assetDefinitionId}/dataitemdefinitions`
		)
			.then((data) => data.json())
			.then((data) => this.setState({ dataitems: data }))
			.catch((err) => console.log(err));
	}
	addAlarmDefinition() {
		var ad = {
			name: this.state.newAlarmName,
			assetDefinitionId: this.props.assetDefinition.assetDefinitionId,
			reason: this.state.newAlarmReason ?? "",
			severity: this.state.newAlarmSeverity ?? 1,
			escalation: this.state.newAlarmEscalation ?? 0,
			expiration: this.state.newAlarmExpiration ?? 0,
			selfAck: this.state.newAlarmSelfAck ?? 0,
			flee: this.state.newAlarmFlee,
			jobId: 0,
		};
		fetch(`/api/ef/alarmdefinitions`, {
			headers: {
				"content-type": "application/json",
			},
			body: JSON.stringify([ad]),
			method: "put",
		}).then(() => {
			this.loadAlarmDefinitions();
		});
	}
	editAlarmDefinition() {
		var ad = {
			alarmDefinitionId: this.state.selectedAlarmDefinition
				.alarmDefinitionId,
			assetDefinitionId: this.props.assetDefinition.assetDefinitionId,
			name: this.state.newAlarmName,
			reason: this.state.newAlarmReason ?? "",
			severity: this.state.newAlarmSeverity ?? 1,
			escalation: this.state.newAlarmEscalation ?? 0,
			expiration: this.state.newAlarmExpiration ?? 0,
			selfAck: this.state.newAlarmSelfAck ?? 0,
			flee: this.state.newAlarmFlee,
			jobId: 0,
		};
		fetch(`/api/ef/alarmdefinitions`, {
			headers: {
				"content-type": "application/json",
			},
			body: JSON.stringify([ad]),
			method: "put",
		}).then(() => {
			this.loadAlarmDefinitions();
		});
	}
	deleteAlarmDefinition(id) {
		fetch(
			`/api/assetdefinitions/${this.props.assetDefinition.assetDefinitionId}/alarmdefinitions/${id}`,
			{
				headers: {
					"content-type": "application/json",
				},
				method: "DELETE",
			}
		).then(() => {
			this.loadAlarmDefinitions();
		});
	}
	alarmDefinitionRow(ad) {
		return (
			<tr
				onClick={() => {
					this.setState({ selectedAlarmDefinition: ad });
				}}
				style={{ boxShadow: DefaultEffects.elevation8 }}
			>
				<td>{ad.name}</td>
				<td>{ad.reason}</td>
				<td>{ad.severity}</td>
				<td>{ad.escalation}</td>
				<td>{ad.expiration}</td>
				<td>{ad.selfAck ? "true" : "false"}</td>
				<td>
					<Stack horizontal>
						<Icon
							className={iconClass}
							iconName="Edit"
							onClick={() => {
								this.setState({
									edit: true,
									changed: false,
									showForm: true,
									newAlarmName: ad.name,
									newAlarmReason: ad.reason,
									newAlarmSeverity: ad.severity,
									newAlarmExpiration: ad.expiration,
									newAlarmEscalation: ad.escalation,
									newAlarmFlee: ad.flee,
								});
							}}
						></Icon>
						<Icon
							className={iconClass}
							iconName="Delete"
							onClick={() => {
								this.deleteAlarmDefinition(
									ad.alarmDefinitionId
								);
							}}
						></Icon>
					</Stack>
				</td>
			</tr>
		);
	}
	render() {
		var showForm = this.state.showForm;
		return (
			<Panel
				isLightDismiss={true}
				isOpen={this.props.isOpen}
				onOpen={() => {
					this.loadAlarmDefinitions();
					this.loadDataItemDefinitions();
				}}
				onDismiss={this.props.onDismiss}
				type={PanelType.medium}
				onRenderHeader={() => {
					return (
						<Stack horizontal>
							<h3
								style={{
									fontFamily: "Proxima Nova Bold",
									fontSize: "2rem",
								}}
							>
								{this.props.assetDefinition?.assetDefinitionName.toUpperCase()}
							</h3>
							<pre> </pre>
							<h3
								style={{
									fontFamily: "Proxima Nova Thin",
									fontSize: "2rem",
								}}
							>
								ALARMS
							</h3>
						</Stack>
					);
				}}
			>
				<Separator>Alarms</Separator>
				<table style={{ width: "100%" }}>
					<thead>
						<tr>
							<th>Name</th>
							<th>Reason</th>
							<th>Severity</th>
							<th>Escalation</th>
							<th>Expiration</th>
							<th>Self Acknowledge</th>
						</tr>
					</thead>
					{this.state.alarmDefinitions?.map((di) => {
						return this.alarmDefinitionRow(di);
					})}
				</table>
				<ActionButton
					iconProps={{ iconName: "Add" }}
					onClick={() => {
						this.setState({
							selectedAlarmDefinition: null,
							showForm: true,
							newAlarmName: null,
							newAlarmReason: null,
							newAlarmSeverity: null,
							newAlarmExpiration: null,
							newAlarmEscalation: null,
							newAlarmFlee: null,
						});
					}}
				>
					<Text>New Alarm</Text>
				</ActionButton>

				<div hidden={!showForm}>
					<Separator>
						<Stack
							tokens={{ childrenGap: 5 }}
							horizontal
							onClick={() => {
								this.setState({ showForm: !showForm });
							}}
						>
							<p>Set up Alarm</p>
							<Icon
								iconName={
									showForm
										? "CollapseContentSingle"
										: "ExploreContentSingle"
								}
							/>
						</Stack>
					</Separator>
					<Stack tokens={{ childrenGap: 10 }}>
						{}
						<TextField
							label="Alarm Name"
							placeholder="Name..."
							value={this.state.newAlarmName}
							onChange={(e, val) => {
								this.setState({
									changed: true,
									newAlarmName: val,
								});
							}}
						></TextField>
						<TextField
							label="Reason"
							placeholder="Reason..."
							text="Reason"
							value={this.state.newAlarmReason}
							onChange={(e, val) => {
								this.setState({
									changed: true,
									newAlarmReason: val,
								});
							}}
						></TextField>
						<TextField
							label="Severity"
							type="number"
							placeholder="Severity"
							value={this.state.newAlarmSeverity}
							onChange={(e, val) => {
								this.setState({
									changed: true,
									newAlarmSeverity: val,
								});
							}}
						></TextField>
						<TextField
							label="Escalation"
							type="number"
							placeholder="Escalation"
							value={this.state.newAlarmEscalation}
							onChange={(e, val) => {
								this.setState({
									changed: true,
									newAlarmEscalation: val,
								});
							}}
						></TextField>
						<TextField
							label="Expiration"
							type="number"
							placeholder="Expiration"
							value={this.state.newAlarmExpiration}
							onChange={(e, val) => {
								this.setState({
									changed: true,
									newAlarmExpiration: val,
								});
							}}
						></TextField>
						<Separator>Expression</Separator>
						<AlarmExpressionEditor
							key={
								"flee" +
								this.state.selectedAlarmDefinition
									?.alarmDefinitionId
							}
							alarmDefinition={this.state.selectedAlarmDefinition}
							dataItems={this.state.dataitems}
							expression={this.state.newAlarmFlee}
							onValidated={(expression) => {
								this.setState({
									changed: true,
									newAlarmFlee: JSON.stringify(expression),
								});
							}}
						></AlarmExpressionEditor>
						<Stack horizontal>
							{this.state.edit ? (
								this.state.changed && (
									<PrimaryButton
										text="Save alarm"
										onClick={() => {
											this.editAlarmDefinition();
										}}
									></PrimaryButton>
								)
							) : (
								<PrimaryButton
									text="Add alarm"
									onClick={() => {
										this.addAlarmDefinition();
									}}
								></PrimaryButton>
							)}
							<DefaultButton
								onClick={() => {
									this.setState({
										edit: false,
										changed: false,
										showForm: false,
									});
								}}
							>
								Cancel
							</DefaultButton>
						</Stack>
					</Stack>
				</div>
			</Panel>
		);
	}
}
export default AlarmDefinitionsPanel;
