import {
	Text,
	Panel,
	TextField,
	Stack,
	DefaultEffects,
	PanelType,
	PrimaryButton,
	mergeStyles,
	mergeStyleSets,
	Separator,
	IconButton,
	DefaultButton,
	DetailsList,
} from "@fluentui/react/";
import React from "react";
import csv2json from "csvjson-csv2json";

const iconClass = mergeStyles({
	// fontSize: 25,
	// height: 50,
	// width: 50,
	// margin: '0 25px',
});
const classNames = mergeStyleSets({
	white: [iconClass],
	green: [{ color: "green" }, iconClass],
	red: [{ color: "red" }, iconClass],
});

class EventDefinitionsPanel extends React.Component {
	state = {
		search: "",
		dataitems: [],
	};
	loadEventDefinitions() {
		fetch(
			`/api/events/definitions?assetDefinitionId=${this.props.assetDefinition.assetDefinitionId}`
		)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ eventDefinitions: data });
			});
	}
	componentDidMount() {
		if (this.props.assetDefinition) {
			this.loadEventDefinitions();
		}
	}
	checkCSV() {
		try {
			var x = csv2json(this.state.csvText);
			this.setState({ activeOptions: x });
		} catch (e) {}
	}
	renderMaintenanceEditor() {
		return (
			this.state.activeEvent && (
				<Stack tokens={{ childrenGap: 15 }}>
					<h2>{this.state.activeEvent.name}</h2>
					<Stack horizontal verticalAlign="end">
						<TextField
							label="Enter new options"
							onChange={(e, val) => {
								this.setState({ newOption: val });
							}}
						></TextField>
						<IconButton
							iconProps={{ iconName: "Add" }}
							onClick={() => {
								this.setState({
									activeOptions: [
										...this.state.activeOptions,
										{ option: this.state.newOption },
									],
								});
							}}
						></IconButton>
					</Stack>
					<TextField
						label="Or enter a CSV of part numbers with a header named 'option'"
						value={this.state.csvText}
						onChange={(e, val) => {
							this.setState({ csvText: val }, () => {
								this.checkCSV();
							});
						}}
						multiline
					></TextField>

					<DetailsList
						items={this.state.activeOptions}
						columns={[
							{
								fieldName: "option",
								name: "Part Number",
								key: "option",
							},
							{
								key: "delete",
								name: "Remove",
								onRender: (item, i) => {
									return (
										<IconButton
											iconProps={{
												iconName: "ChromeClose",
											}}
											style={{ color: "red" }}
											onClick={() => {
												this.removeOption(i);
											}}
										></IconButton>
									);
								},
							},
						]}
					></DetailsList>
					<Stack
						horizontal
						horizontalAlign="end"
						tokens={{ childrenGap: 15 }}
					>
						<PrimaryButton
							text="Save options"
							onClick={() => {
								this.saveOptions();
							}}
						></PrimaryButton>
						<DefaultButton
							text="Discard"
							onClick={() => {
								this.setState({
									activeEvent: undefined,
									activeOptions: undefined,
								});
							}}
						></DefaultButton>
					</Stack>
					<Separator></Separator>
				</Stack>
			)
		);
	}
	saveOptions() {
		var ed = { ...this.state.activeEvent };
		ed.reason = JSON.stringify({
			options: this.state.activeOptions.map((el) => {
				return el.option;
			}),
		});
		fetch(`/api/events/definitions`, {
			method: "put",
			body: JSON.stringify(ed),
			headers: { "Content-Type": "application/json" },
		}).then(() => {
			this.loadEventDefinitions();
		});
	}
	removeOption(i) {
		let options = [...this.state.activeOptions];
		options.splice(i, 1);
		this.setState({ activeOptions: options });
	}
	activeEvent(item) {
		const options = item.reasonExpanded?.options.slice(0).map((el) => {
			return { option: el };
		});
		this.setState({
			activeEvent: item,
			activeOptions: options,
		});
	}
	renderEventEditor() {
		switch (this.state.activeEvent.category) {
			case 0:
				return <Stack></Stack>;
				break;
			case 1:
				return this.renderMaintenanceEditor();
				break;
		}
	}
	render() {
		var showForm = this.state.showForm;
		return (
			<Panel
				isLightDismiss={true}
				isOpen={this.props.isOpen}
				onOpen={() => {
					this.loadEventDefinitions();
				}}
				onDismiss={this.props.onDismiss}
				type={PanelType.medium}
				onRenderHeader={() => {
					return (
						<Stack horizontal>
							<h3
								style={{
									fontFamily: "Proxima Nova Bold",
									fontSize: "2rem",
								}}
							>
								{this.props.assetDefinition?.assetDefinitionName.toUpperCase()}
							</h3>
							<pre> </pre>
							<h3
								style={{
									fontFamily: "Proxima Nova Thin",
									fontSize: "2rem",
								}}
							>
								EVENTS
							</h3>
						</Stack>
					);
				}}
			>
				{this.state.activeEvent && this.renderEventEditor()}
				<DetailsList
					items={this.state.eventDefinitions ?? []}
					onActiveItemChanged={(item, i, ev) => {
						this.activeEvent(item);
					}}
					columns={[
						{ fieldName: "name", name: "Name", key: "name" },
						{
							fieldName: "category",
							name: "Category",
							key: "category",
							onRender: (item, i, col) => {
								switch (item.category) {
									case 0:
										return "Annotation";
										break;
									case 1:
										return "Maintenance";
										break;
									default:
										return "Event";
										break;
								}
							},
						},
					]}
				></DetailsList>
			</Panel>
		);
	}
}
export default EventDefinitionsPanel;
