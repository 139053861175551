import React from "react";
import { connect as mqttConnect } from "mqtt";
import * as actions from "../actions/index";
import { connect } from "react-redux";
import {
	getAllSlotPathsFromSystem,
	getAssetIdFromSystem,
	getAssetIdsFromSystem,
	getAttributeValueFromSystem,
	getCookie,
} from "../util";

class RealtimeMessageClient extends React.Component {
	dataItems = [];
	state = {
		broker: null,
		host:
			window.location.hostname == "localhost" ||
			window.location.hostname == "biebertest.com"
				? "sandbox.limeinst.com"
				: window.location.hostname,
		topics: [],
	};
	componentDidMount() {
		fetch("/config/mqtt.json")
			.then((d) => d.json())
			.then((config) => {
				this.setState(config, () => this.connectToBroker());
			});
			if(this.interval) clearInterval(this.interval)
			this.interval = setInterval(()=>{this.dispatchDataItems()},1000)
	}
	componentDidUpdate(prevProps) {
		if (prevProps.auth?.auth?.authId != this.props.auth?.auth?.authId) {
			console.log("NEW AUTHID! TIME TO RECONNECT");
			this.connectToBroker();
		} else if (
			prevProps.system?.assetId != this.props.system?.assetId ||
			prevProps.system?.systemId != this.props.system?.systemId
		) {
			this.subscribeToAll();
		}
	}
	connectToBroker() {
		const assetIds = getAssetIdsFromSystem(this.props.system);
		if (!assetIds) {
			return;
		}
		// fetch("/api/assets/" + assetId + "/currentdataitems")
		// 	.then((r) => r.json())
		// 	.then((cdi) => {
		// 		this.props.multipleCurrentDataItems(cdi);
		// 	});

		if (this.client) {
			this.client.end(true);
		}
		let jwt = this.state.auth ? getCookie("authJWT") : undefined;

		this.client = mqttConnect(this.state.broker, {
			protocol: "wss",
			username: jwt,
			password: " ",
			clean: true,
		});

		this.client.on("connect", () => {
			this.subscribeToAll();
			var dataItems = [];
			var slotPaths = getAllSlotPathsFromSystem(
				this.props.system,
				null,
				null
			);

			for (var i = 0; i < slotPaths.length; i++) {
				var aliasMap = JSON.parse(
					getAttributeValueFromSystem(
						this.props.system,
						slotPaths[i],
						"aliasMap",
						"{}"
					)
				);

				for (var x in aliasMap) {
					dataItems.push({
						assetId: getAssetIdFromSystem(
							this.props.system,
							slotPaths[i]
						),
						dataItemId: x,
						label: aliasMap[x],
					});
				}
			}

			this.props.multipleCurrentDataItems(dataItems);
		});
		this.client.on("message", (topic, payload) => {

			if (topic.split("/")[3] == "dataitems" && !document.hidden ) {
				var cdi = JSON.parse(payload.toString());
				this.dataItems.push(cdi);

				

				// if (this.timeout) clearTimeout(this.timeout);
				// this.timeout = setTimeout(() => {
				// 	this.props.multipleCurrentDataItems(
				// 		this.dataItems.splice(0)
				// 	);
				// }, 200);


				//this.props.singleCurrentDataItem(cdi)
			} 
			
			else if (topic.split("/")[3] == "commands") {
				//this.props.singleCurrentDataItem(cdi)
			}
		
		});
	}
	dispatchDataItems(){
		let disp = this.dataItems.splice(0)
		disp.sort((d2,d1) => new Date(d2.timestamp) - new Date(d1.timestamp))
		.filter(
			(el,i,arr)=>{
				return i !== arr.findIndex((v)=>{return v.assetId == el.assetId && v.dataItemId == el.dataItemId})
		})
		if (!document.hidden) {
			window.requestAnimationFrame(()=>this.props.multipleCurrentDataItems(disp))
		}
	
	}
	subscribeToAll() {
		if (this.client) {
			this.client.unsubscribe(this.state.topics);
			var assetIds = getAssetIdsFromSystem(this.props.system);
			//let newTopics = [`${this.state.host}/assets/+/dataitems/19`,`${this.state.host}/assets/+/dataitems/39`,`${this.state.host}/assets/+/dataitems/148`];
			let newTopics = []
			for (var x in assetIds) {
				var topic = `${this.state.host}/assets/${assetIds[x]}/dataitems/#`;
				newTopics.push(topic);
			}
			newTopics.forEach(topic=>	this.client.subscribe(topic))
		;
			this.setState({ topics: newTopics });
		}
	}

	render() {
		return null;
	}
}

function mapStateToProps({ tiles, system, auth }) {
	return { tiles, system, auth };
}

export default connect(mapStateToProps, actions)(RealtimeMessageClient);
