import { connect } from "react-redux";
import {
	Icon,
	IconButton,
	Text,
	Stack,
	Selection,
	SearchBox,
	TextField,
	Checkbox,
	DetailsList,
	mergeStyles,
	getTheme,
	ActionButton,
} from "@fluentui/react";
import React from "react";
import { guid } from "../util";
import SystemDataItemSelector from "./SystemDataItemSelector";
// Warn if overriding existing method
class SystemMultiDataItemSelector extends React.Component {
	id = guid();
	state = {
		dataItems: [],
		selectedItems: [],
	};
	selection = new Selection({
		//	onSelectionChanged: () => console.log(this.selection.getSelection()),
	});
	getListItems(definition, slotPath) {
		if (!slotPath) slotPath = [];
		if (!definition) definition = this.props.definition;
		var listItems = [];
		if (definition.slots) {
			for (var x in definition.slots) {
				if (definition.slots[x].assetDefinition) {
					var newItems = this.getListItems(
						definition.slots[x].assetDefinition,
						[...slotPath, definition.slots[x].slotId]
					);
					listItems.concat(newItems);
				} else if (definition.slots[x].systemDefinition) {
					var newItems = this.getListItems(
						definition.slots[x].systemDefinition,
						[...slotPath, definition.slots[x].slotId]
					);
					listItems.concat(newItems);
				}
			}
		} else if (definition.dataItemDefinitions) {
			var dids = definition.dataItemDefinitions;
			for (var x in dids) {
				let did = { ...dids[x] };
				listItems.push({
					slotPath,
					dataItem: did,
				});
			}
		}
		return listItems;
	}
	formatString(item) {
		var definition = this.props.definition;
		var str = "";
		for (var x in item.slotPath) {
			definition = definition.slots?.find(
				(el) => el.slotId == item.slotPath[x]
			);
			if (!definition) break;
			str += definition.name;
			str += " > ";
		}
		str += item?.dataItem?.label;
		return str;
	}
	closeDialogListener = (event) => {
		console.log(event);
		var specifiedElement = document.getElementById(this.id);
		if (specifiedElement == null) {
			return;
		}
		var isClickInside = specifiedElement.contains(event.target);

		if (!isClickInside) {
			this.setState({ showDropdown: false });
			document.removeEventListener("click", this.closeDialogListener);
		}
	};
	componentDidMount() {
		this.setState({ dataItems: this.getListItems() });

		//I'm using "cclick" but it works with any event
	}
	handleChange(dataItems) {
		this.setState({ selectedItems: dataItems }, () => {
			if (this.props.onChange) {
				this.props.onChange(this.state.selectedItems);
			}
		});
	}
	render() {
		return (
			<Stack>
				{this.props.label && (
					<label className="ms-Label">{this.props.label}</label>
				)}
				<Stack
					horizontal
					horizontalAlign="space-between"
					verticalAlign="center"
					iconProps={{ iconName: "ChevronDown" }}
					onClick={() => {
						this.setState({
							showDropdown: !this.state.showDropdown,
						});
					}}
					style={{ border: "1px solid rgb(208, 208, 208)" }}
				>
					<Text style={{ marginLeft: "5px" }}>
						{" "}
						{this.state.selectedItems.length} Selected
					</Text>
					<IconButton
						iconProps={{ iconName: "ChevronDown" }}
						onClick={() => {
							this.setState({
								showDropdown: !this.state.showDropdown,
							});
						}}
						subMenuHoverDelay={0}
						subMenuProps={{ subMenuHoverDelay: 0 }}
						subMenuHoverDelay={0}
						subMenuProps={{ subMenuHoverDelay: 0 }}
					/>
				</Stack>
				<div
					id={this.id}
					style={{
						display: this.state.showDropdown ? "block" : "none",
					}}
				>
					<Stack
						tokens={{ childrenGap: 3 }}
						style={{
							zIndex: 5,
							backgroundColor: "#252525",
							position: "absolute",
							padding: "14px",
						}}
					>
						<TextField
							placeholder="Search..."
							iconProps={{ iconName: "search" }}
							value={this.state.filter}
							onChange={(e, val) => {
								this.setState({ filter: val });
							}}
						></TextField>
						<Stack horizontal horizontalAlign="space-between">
							<Text
								onClick={() =>
									this.handleChange([
										...this.state.dataItems.filter((di) =>
											this.formatString(di)
												.toLowerCase()
												.includes(
													this.state.filter?.toLowerCase() ??
														""
												)
										),
									])
								}
							>
								Check all
							</Text>
							<Text onClick={() => this.handleChange([])}>
								Clear all
							</Text>
						</Stack>
						{this.state.dataItems.map((el) => {
							return (
								<Stack
									style={{
										display: el.dataItem.label
											.toLowerCase()
											.includes(
												this.state.filter?.toLowerCase() ??
													""
											)
											? "initial"
											: "none",
										margin: "2px",
									}}
									horizontal
								>
									<Checkbox
										checked={
											this.state.selectedItems.find(
												(sel) =>
													sel.dataItem.dataItemId ==
														el.dataItem
															.dataItemId &&
													JSON.stringify(
														sel.slotPath
													) ==
														JSON.stringify(
															el.slotPath
														)
											) ?? false
										}
										onChange={(e, val) => {
											var selected = [
												...this.state.selectedItems.filter(
													(sel) =>
														!(
															sel.dataItem
																.dataItemId ==
																el.dataItem
																	.dataItemId &&
															JSON.stringify(
																sel.slotPath
															) ==
																JSON.stringify(
																	el.slotPath
																)
														)
												),
											];
											if (val) {
												selected.push(el);
											}
											this.handleChange(selected);
										}}
										label={el.dataItem.label}
									></Checkbox>
								</Stack>
							);
						})}
						<ActionButton
							iconProps={{ iconName: "CheckMark" }}
							onClick={() => {
								this.setState({
									showDropdown: !this.state.showDropdown,
								});
							}}
							text="Done"
						></ActionButton>
					</Stack>
				</div>
			</Stack>
		);
	}
	_insertBeforeItem(item) {
		const draggedItems = this._selection.isIndexSelected(this._draggedIndex)
			? this._selection.getSelection()
			: [this._draggedItem];

		const insertIndex = this.state.dataItems.indexOf(item);
		const dataItems = this.state.dataItems.filter(
			(itm) => draggedItems.indexOf(itm) === -1
		);

		dataItems.splice(insertIndex, 0, ...draggedItems);
		this.setState({ dataItems });
	}
	_getDragDropEvents() {
		return {
			canDrop: (dropContext, dragContext) => {
				return true;
			},
			canDrag: (item) => {
				return true;
			},
			onDragEnter: (item, event) => {
				// return string is the css classes that will be added to the entering element.
				return dragEnterClass;
			},
			onDragLeave: (item, event) => {
				return;
			},
			onDrop: (item, event) => {
				if (this._draggedItem) {
					this._insertBeforeItem(item);
				}
			},
			onDragStart: (item, itemIndex, selectedItems, event) => {
				this._draggedItem = item;
				this._draggedIndex = itemIndex;
			},
			onDragEnd: (item, event) => {
				this._draggedItem = undefined;
				this._draggedIndex = -1;
			},
		};
	}
}

function mapStateToProps({ definition }) {
	return { definition };
}
export default connect(mapStateToProps)(SystemMultiDataItemSelector);
const theme = getTheme();
const dragEnterClass = mergeStyles({
	backgroundColor: theme.palette.neutralLight,
});
