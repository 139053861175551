import React from "react";
import { ActionButton, DetailsList, SelectionMode } from "@fluentui/react/";
import AlarmDefinitionsPanel from "./AlarmDefinitionPanel";
import EventDefinitionsPanel from "../../../components/Panels/EventDefinitionsPanel";
import DataItemDefinitionsPanel from "./DataItemDefinitionsPanel";

export default class AssetDefinitionConfiguration extends React.Component {
	state = {};
	deleteAssetDefinitions(id) {
		fetch(`/api/assetDefinitions/${id}`, { method: "delete" })
			.then((data) => data.json())
			.then((data) => {
				console.log(data);
				this.loadAssetDefinitions();
			})
			.catch((err) => console.log(err));
	}

	render() {
		return (
			<>
				<DetailsList
					compact
					selectionMode={SelectionMode.none}
					items={this.props.filteredAssetDefinitions}
					columns={[
						{
							fieldName: "assetDefinitionName",
							name: "Name",
							key: "name",
						},
						{
							key: "alarm",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "AlertSettings",
										}}
										onClick={() => {
											this.setState({
												alarmsEditor: true,
												selectedAssetDefinition: item,
											});
										}}
									>
										Alarms
									</ActionButton>
								);
							},
						},
						{
							key: "dataitems",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "LineChart",
										}}
										onClick={() => {
											this.setState({
												dataItemsEditor: true,
												selectedAssetDefinition: item,
											});
										}}
									>
										Data items
									</ActionButton>
								);
							},
						},
						{
							key: "events",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "EventInfo",
										}}
										onClick={() => {
											this.setState({
												eventEditor: true,
												selectedAssetDefinition: item,
											});
										}}
									>
										Events/Maintenance
									</ActionButton>
								);
							},
						},
						{
							key: "delete",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "Delete",
										}}
										onClick={() => {
											this.deleteAssetDefinitions(
												item.assetDefinitionId
											);
										}}
									>
										Delete
									</ActionButton>
								);
							},
						},
					]}
				></DetailsList>
				<AlarmDefinitionsPanel
					isOpen={this.state.alarmsEditor}
					onDismiss={(a, b) => {
						this.setState({ alarmsEditor: false });
					}}
					assetDefinition={this.state.selectedAssetDefinition}
				></AlarmDefinitionsPanel>
				<EventDefinitionsPanel
					isOpen={this.state.eventEditor}
					onDismiss={(a, b) => {
						this.setState({ eventEditor: false });
					}}
					assetDefinition={this.state.selectedAssetDefinition}
				></EventDefinitionsPanel>

				<DataItemDefinitionsPanel
					isOpen={this.state.dataItemsEditor}
					onDismiss={(a, b) => {
						this.setState({ dataItemsEditor: false });
					}}
					assetDefinition={this.state.selectedAssetDefinition}
				></DataItemDefinitionsPanel>
			</>
		);
	}
}
