import React, { Component } from "react";
import Iframe from "react-iframe";
import { connect } from "react-redux";
import { format } from "../../../../util";

class IframeTile extends Component {
	render() {
		const { id, properties } = this.props;
		var subObject = { ...this.props };

		subObject.attributes = Object.fromEntries(
			this.props.system.attributes.map((att) => {
				return [att.attributeName, att.attributeValue];
			})
		);
		return (
			<div
				id={id}
				style={{ width: "100%", height: "100%", margin: "auto" }}
			>
				<Iframe
					url={format(properties.url, subObject)}
					style={{ border: "none" }}
					frameBorder="0"
					width="100%"
					height="100%"
					className=""
					display="initial"
					position="relative"
					allow="camera; fullscreen; microphone;"
				/>
			</div>
		);
	}
}

function mapStateToProps({ system }) {
	return { system };
}

export default connect(mapStateToProps)(IframeTile);
