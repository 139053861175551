import React, { Component } from "react";
import { connect } from "react-redux";
import { TextField } from "@fluentui/react";

class IframeTileForm extends Component {
	state = {
		url: "",
	};

	componentDidMount() {
		const { tile } = this.props.ui.modal;
		if (tile) {
			const { url } = tile.properties;
			this.setState({
				url,
			});
		}
	}

	handleChange = (e) => {
		e.preventDefault();
		const { addData } = this.props;
		this.setState({ [e.target.name]: e.target.value }, () => {
			addData(this.state);
		});
	};

	handleChange2 = (state) => {
		const { addData } = this.props;
		this.setState(Object.assign({ ...this.state, state }, state), () => {
			addData(this.state);
		});
	};

	render() {
		const { url } = this.state;
		return (
			<TextField
				value={url}
				onChange={(e, val) => {
					this.handleChange2({ url: val });
				}}
				label="URL"
			></TextField>
		);
	}
}

function mapStateToProps({ ui }) {
	return { ui };
}

export default connect(mapStateToProps)(IframeTileForm);
