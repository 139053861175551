import * as Papa from "papaparse";
import { Check, Checkbox, getTheme, IconButton, Slider } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import SystemDataItemSelector from "../../../../../components/SystemDataItemSelector";
import { cleanColor, getAssetIdFromSystem } from "../../../../../util";
import { TooltipTypes } from "../uPlot/Plugins/TooltipPlugin";
import UPlotComponent from "../uPlot/uPlot";
import { sort2DarrayByFirstArray } from "../uPlot/util";



const fontColor = "#ffffff"
export function TorqueProjection() {

    let fontColor = getTheme().palette.black;
    let [rawData, setRawData] = useState()
    let [LUT, letLUT] = useState()
    let [data, setData] = useState()
    let [opts,setOpts] = useState(basicOpts)
    let [useDepth,setUseDepth] = useState(false)
    let [depthSmooth,setDepthSmooth] = useState(1)
    useEffect(() => {
        fetchFromApi([19,160], new Date(new Date() - 130 *  3600 * 1000), new Date(), -1)
            .then(d=>setRawData(sort2DarrayByFirstArray(d)))
        loadProjection()
            .then(letLUT)
    }, [])

    useEffect(()=>{
        if(rawData && LUT){
            let data = [...rawData]
            let newSeries = []
            newSeries = [{ stroke: fontColor,smooth:useDepth?depthSmooth:1},{scale:"Depth",label:"Depth",stroke:"#ff0000",smooth:1},{ scale: "Torque",label:"Actual Torque",stroke:"#a0a0ff66",spanGaps:true}]

            let depths = LUT.data.map(d=>d[LUT.meta.fields[0]])
            for(var i = 1 ; i <   LUT.meta.fields.length; i++){
               let projection = LUT.data.map(d=>d[LUT.meta.fields[i]])
               data.push(data[1].map(depth=>indexMatch(depth,depths,projection)))
               newSeries.push(
                   {
                        stroke: "#00000000",
                        scale: "Torque",
                        smooth:useDepth?depthSmooth:1,
                        dash: [5, 5],
                        spanGaps: true,
                        label: LUT.meta.fields[i],
                    })
            }
            let newOpts = {...opts}
            newOpts.scales["x"].time = !useDepth
            setOpts({...newOpts,series:newSeries})
            if(useDepth){
                data[0] = [...data[1]]
            }
            var q3 = [...data[2]].sort()[parseInt(data[2].length*.75)]
            
            // data[2] = data[2].map((el,i,arr)=>{
            //     //Top quarter
            //     if(el >= q3){
            //         return el
            //     }

            //     else{
            //         return null
            //     }
            // })
            //Local maxima
            // data[2] = data[2].map((el,i,arr)=>{
            // if(i == 0 || i == arr.length - 1 || (el > arr[i-1] && el > arr[i+1] ) ){
               
            //         return el
            // }
            // else{
            //     return null
            // }
            // })
            setData(data)
        }
    },[rawData,LUT,useDepth,depthSmooth])

    if(opts && data){
        return  <div style={{paddingTop:30}}><Checkbox label="Use Depth as independent variable" checked={useDepth} onChange={(e,val)=>setUseDepth(val)}></Checkbox><Slider max={1000} defaultValue={depthSmooth} onChanged={(e,val)=>setDepthSmooth(val)}></Slider><UPlotComponent data = {data} opts = {opts}></UPlotComponent></div>
    }
    return <div></div>
   
}

function loadProjection(){
    const assetId = getAssetIdFromSystem()
    return new Promise((resolve,reject)=>{
        Papa.parse(`/api/ef/assets/${assetId}/files/projection.csv`,{download:true,header:true,dynamicTyping: true,complete:(a)=>resolve(a)},(e)=>reject(e))
    })
}
function fetchFromApi(dataItems, startTime, endTime, thresh) {
    const assetId = getAssetIdFromSystem()
    return fetch(`/api/historicaldata?columnar=true&dataItems=${dataItems.map(di => `${assetId}-${di}`)}&threshold=${thresh}&startTime=${startTime.toJSON()}&endTime=${endTime.toJSON()}`,
    )
        .then((res) => res.json())
        .catch(() => { return [[[]]] });
}

function indexMatch(x,xVals,yVals){
    var firstGreaterIndex = xVals.findIndex(xVal=>xVal > x)
    if(firstGreaterIndex > 0){
        var nextX = xVals[firstGreaterIndex]
        var lastX = xVals[firstGreaterIndex -1]
        var fractionalIndex = 0
    
        if(lastX >=0){
            fractionalIndex = (x - lastX) / (nextX - lastX)
    
        }
        
        var nextY = yVals[firstGreaterIndex]
        var lastY = yVals[firstGreaterIndex -1]
    
        return lastY + (nextY - lastY) * fractionalIndex
    }
    else{
        return yVals[0]
    }

}
const shouldInvert = true
const basicOpts = {
    invert:shouldInvert,
    legend: { show: true },
    padding: [0, 10, 0, 10],
    cursor: {
        fill: "#ffffff",
        drag: { x: true, y: true, uni: 50 },
    },
    plugins: [TooltipTypes["BlockRight"]()],
    scales:{
        x: {
            dir: shouldInvert ? -1 : 1,
            ori: shouldInvert ? 1 : 0,
            time: false
        },
         Depth:{
             min:0,
             max:20000,
			 ori: shouldInvert ? 1 : 0,
            grid:{show:false}
            }
             ,
         Torque:{
            ori: shouldInvert ? 1 : 0,
            auto:true
        }
    },
    axes:[	{
        stroke: fontColor,
        font: "12px Proxima Nova",
        labelFont: "12px Proxima Nova",
        side: shouldInvert ? 3 : 2,

    },
    
    {
        stroke: fontColor,
        scale:"Torque",
        font: "12px Proxima Nova",
        labelFont: "12px Proxima Nova",
        label:"ft-lbs",
        side: shouldInvert? 2:3
    }],
    bands: [
        {
            series: [5,3],
            fill: "rgba(0,255,0,0.3)",
            dir: 1
        }
    ],

};

