import React, { Component } from "react";
import { connect } from "react-redux";
import { getAssetIdFromSystem } from "../../../../util/index";
class CylinderGauge extends Component {
	static defaultProps = {
		decimals: "2",
		fontSize: "14",
	};

	render() {
		var fillColor = this.props.properties?.color ?? "red";
		var strokeColor = this.props.properties?.strokeColor ?? "#aaa";
		var backgroundColor = "transparent";
		var assetId = getAssetIdFromSystem(
			null,
			this.props.properties?.slotPath
		);

		var value =
			this.props.currentData?.[assetId]?.[
				this.props.properties?.dataItem?.dataItemId
			]?.value ?? 0;
		var min = parseFloat(this.props.properties.min ?? 0);
		min = isNaN(min) ? 0 : min;
		var max = parseFloat(this.props.properties.max ?? 100);

		var pct = ((value - min) / (max - min)) * 100;
		if (pct < 0) pct = 0;
		if (pct > 100) pct = 100;
		var invPct = 100 - pct;
		const classes = {
			valueText: {
				marginTop: pct > 50 ? "-20px" : "0px",
				fontSize: "calc(6px + 1vh)",
				color: "white",
				lineHeight: "calc(6px + 1vh)",
			},
			siloBottom: {
				position: "relative",
				background: `linear-gradient(0deg, ${fillColor} 50%,  #00000000 50%)`,
				borderRadius: "100%",
				border: `1px solid ${strokeColor}`,
				height: "20px",
				marginTop: "-10px",
				zIndex: -1,
			},
			siloTop: {
				position: "absolute",
				zIndex: 2,
				border: `1px solid ${strokeColor}`,
				height: "20px",
				width: "100%",
				marginTop: "-10px",
				borderRadius: "100%",
			},
			siloMiddle: {
				position: "absolute",
				transition: "max-height 0.8s",
				margin: "10px",
				position: "relative",
				height: "calc(100% - 60px)",
				textAlign: "center",
				zIndex: 1,
			},
			sideBorders: {
				width: "100%",
				height: "100%",
				position: "absolute",
				marginBottom: "10px",
				borderLeft: `1px solid ${strokeColor}`,
				borderRight: `1px solid ${strokeColor}`,
			},
			siloEmpty: {
				backgroundColor: backgroundColor,
				maxHeight: invPct + "%",
				transition: "all 0.8s",
				height: invPct + "%",
				width: "100%",
			},
			siloFill: {
				backgroundColor: fillColor,
				maxHeight: pct + "%",
				height: pct + "%",
				transition: "all 0.8s",
				width: "100%",
			},
			siloFillTop: {
				position: "absolute",
				background: `linear-gradient(0deg,#00000000 50%, ${fillColor}  50%)`,
				borderRadius: "100%",
				width: "100%",
				height: "20px",
				marginTop: "-10px",
				border: `1px solid ${strokeColor}`,
			},
		};

		return (
			<div
				style={{
					position: "absolute",
					height: "90%",
					width: "100%",
					margin: "auto",
				}}
			>
				<div style={{ marginTop: "15px", textAlign: "center" }}>
					{this.props.properties.name}
				</div>
				<div className="siloMiddle" style={classes.siloMiddle}>
					<div
						className="sideBorders"
						style={classes.sideBorders}
					></div>
					<div className="siloTop" style={classes.siloTop}></div>
					<div className="siloEmpty" style={classes.siloEmpty}></div>
					<div
						className="siloFillTop"
						style={classes.siloFillTop}
					></div>
					<div className="siloFill" style={classes.siloFill}></div>
					<div
						className="siloBottom"
						style={classes.siloBottom}
					></div>
				</div>
				<div style={{ marginTop: "15px", textAlign: "center" }}>
					{value?.toFixed(this.props.properties.decimals ?? 0)}
					{this.props.properties.unit}
				</div>
			</div>
		);
	}
}

function mapStateToProps({ currentData }) {
	return { currentData };
}

export default connect(mapStateToProps)(CylinderGauge);
