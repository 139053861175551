import { getTheme, IconButton, Separator, Stack } from "@fluentui/react";
import { useEffect, useMemo, useRef } from "react";

import ReactDOM from "react-dom";

export function FullScreener(props) {
	var fullscreen = useRef();

	useEffect(() => {
		fullscreen.current = document.createElement("div");
		fullscreen.current.style =
			"position:fixed;display:flex;width:100vw;height:100vh;overflow:auto;flex-direction:row;top:0px;z-index:1000000;background-color:#aaaaaaaa;";
		document.querySelector("body").append(fullscreen.current);
		fullscreen.current.style.visibility = props.fullscreen
			? "visible"
			: "hidden";
		return () => {
			document.querySelector("body").removeChild(fullscreen.current);
		};
	}, []);
	var content = useMemo(() => <>{props.children}</>, [props.children]);
	useEffect(() => {
		fullscreen.current.style.visibility = props.fullscreen
			? "visible"
			: "hidden";
	}, [props.fullscreen]);

	if (props.fullscreen && fullscreen.current) {
		return ReactDOM.createPortal(content, fullscreen.current);
	} else {
		return content;
	}
}
