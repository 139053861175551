import { Dropdown, SearchBox, DropdownMenuItemType } from "@fluentui/react";
import React from "react";
export default class SearchableDropdown extends React.Component {
	state = {};
	searchBoxRef = React.createRef();
	filterOptions = (item, filterString) => {
		return (
			!filterString ||
			item.text?.toLowerCase().includes(filterString?.toLowerCase())
		);
	};
	componentDidMount() {
		if (this.props.filterOptions) {
			this.filterOptions = this.props.filterOptions;
		}
		if (!this.focusInterval) {
			this.focusInterval = setInterval(() => {
				this.setFocusOnSearch();
			}, 50);
		}
	}
	componentWillUnmount() {
		clearTimeout(this.focusInterval);
	}
	setFocusOnSearch() {
		if (this.searchBoxRef.current) {
			//this.searchBoxRef.current._inputElement.current.focus()
		}
	}
	options() {
		let options = [
			{
				key: "search",
				//itemType: DropdownMenuItemType.Header,
				text: (
					<SearchBox
						onHover={(ev) => {
							ev.stopPropagation();
						}}
						onMouseMove={(ev) => {
							ev.stopPropagation();
						}}
						ref={this.searchBoxRef}
						placeholder="Search"
						onClick={(ev) => {
							ev.stopPropagation();
						}}
						//onSearch={(newValue) => this.setState({filterString:newValue})}
						onChange={(e, newValue) =>
							this.setState({ filterString: newValue })
						}
					/>
				),
			},
		].concat(
			this.props.options?.filter((el) => {
				return this.filterOptions(el, this.state.filterString);
			}) ?? []
		);
		return options;
	}
	render() {
		var props = { ...this.props };
		delete props.options;
		return (
			<Dropdown
				dropdownWidth="200px"
				onChange={(e, val) => {
					if (val.key != "search") this.props.onChange(e, val);
				}}
				className="searchableDropdown"
				placeholder={this.state.lastTitle}
				options={this.options()}
				{...props}
			></Dropdown>
		);
	}
}
