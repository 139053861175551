import { getTheme, IconButton } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import SystemDataItemSelector from "../../../../../components/SystemDataItemSelector";
import { cleanColor, getAssetIdFromSystem } from "../../../../../util";
import { TooltipTypes } from "../uPlot/Plugins/TooltipPlugin";
import UPlotComponent from "../uPlot/uPlot";

const cursorSync = {
    key: "drillChart",
    setSeries: false,
    scales: ["x"]
}
const opts = {
    legend: { show: false },
    invert: true,
    padding: [0, 10, 0, 10],
    cursor: {
        fill: "#ffffff",
        drag: { x: true, y: true, uni: 50 },
        sync: cursorSync
    },
    plugins: [TooltipTypes["BlockRight"]()],
    time: false,
    rotated: true,
};

const config = {
    plots: [
        {
            useDepth: true,

            series: [
                {
                    stroke: "#ff0000",
                    slotPath: [],
                    dataItem: {
                        dataItemId: 160,
                        label: "Torque",
                    }
                },
                {
                    stroke: "#ff0000",
                    slotPath: [],
                    dataItem: {
                        dataItemId: 39,
                        label: "Plug Count"
                    }
                }
            ]
        },
        {
            series: [
                {
                    stroke: "#ff0000",
                    slotPath: [],
                    dataItem: {
                        dataItemId: 19,
                        label: "Depth",
                    }
                },
                {
                    stroke: "#ff0000",
                    slotPath: [],
                    dataItem: {
                        dataItemId: 39,
                        label: "Plug Count"
                    }
                }
            ]
        },
        {
            series: [
                {
                    stroke: "#ff0000",
                    slotPath: [],
                    dataItem: {
                        dataItemId: 160,
                        label: "Torque",
                    }
                },
                {
                    stroke: "#ff0000",
                    slotPath: [],
                    dataItem: {
                        dataItemId: 39,
                        label: "Plug Count"
                    }
                }
            ]
        },
    ]
}

export function DrillChart() {
    let fontColor = getTheme().palette.black;
    let [data, setData] = useState([[]])
    const dataSet = new Set()


    config.plots.forEach(p => {
        p.series.forEach((s) => {
            dataSet.add(s.dataItem.dataItemId)
        })
    })
    var extraData = Array.from(dataSet)
    extraData.unshift(19)
    useEffect(() => {
        fetchFromApi(extraData, new Date(new Date() - 6*  3600 * 1000), new Date(), 1000)
            .then(setData)
    }, [...extraData])







    return <DrillChartVisual data={data} plots={config.plots}></DrillChartVisual>
}

function DrillChartVisual({ plots, data }) {
    let fontColor = getTheme().palette.black;
    return (
        <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
                {/* <UPlotComponent opts={{}} data= {[[]]}></UPlotComponent> */}
            </div>
            {plots.map(p => {
                let axes = [
                    {
                        font: "12px Proxima Nova",
                        labelFont: "12px Proxima Nova",
                        stroke: fontColor,
                        side: 3,
                    },
                ].concat(
                    p.series.map((ser, i) => {
                        return {
                            side: true
									? i % 2 == 0
										? 2
										: 0
									: i % 2 == 0
									? 3
									: 1,
                            stroke: fontColor,
                            scale: ser.label,
                            font: "12px Proxima Nova",
                            labelFont: "12px Proxima Nova",
                            label: ser.label,
                        };
                    })
                )
                let newseries = [
                    {
                    }]
                    .concat(p.series.map((ser, i) => {
                        return {
                            stroke: cleanColor(ser.stroke),
                            fill: cleanColor(ser.fill),
                            scale: `ax${i}`,
                            spanGaps: true,
                            smooth: ser.smooth,
                            label: ser.dataItem.label,
                            fillTo: -999999,
                        }
                    }));

                let scales = {
                    x: {
                        dir: -1,
                        ori: 1,
                    }
                }
                for (var i = 0; i < p.series.length; i++) {
                    scales[`ax${i}`] = {
                        dir: 1,
                        ori: 0, auto: true
                    }
                }
                var theseopts = { ...opts, axes, series: newseries }

                return <UPlotComponent data={data} opts={theseopts}></UPlotComponent>
            })}
        </div>
    )
}

function VerticalDynamicChart({ plot, data }) {

    let fontColor = getTheme().palette.black;
    let [series, setSeries] = useState(plot.series)

    let theOpts = useMemo(() => {
        let axes = [
            {
                font: "12px Proxima Nova",
                labelFont: "12px Proxima Nova",
                stroke: fontColor,
                side: 3,
            },
        ].concat(
            series.map((ser, i) => {
                return {
                    stroke: fontColor,
                    scale: ser.label,
                    font: "12px Proxima Nova",
                    labelFont: "12px Proxima Nova",
                    label: ser.label,
                };
            })
        )
        let newseries = [
            {
            }]
            .concat(series.map((ser, i) => {
                return {
                    stroke: cleanColor(ser.stroke),
                    fill: cleanColor(ser.fill),
                    scale: `ax${i}`,
                    spanGaps: true,
                    smooth: ser.smooth,
                    label: ser.dataItem.label,
                    fillTo: -999999,
                }
            }));

        let scales = {
            x: {
                dir: -1,
                ori: 1,
            }
        }
        for (var i = 0; i < series.length; i++) {
            scales[`ax${i}`] = {
                dir: 1,
                ori: 0, auto: true
            }
        }
        return { ...opts, axes, series: newseries }
    }, [opts, series])


    return (
        <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <div style={{ flexGrow: 1 }}>
                <UPlotComponent opts={theOpts} data={data}></UPlotComponent>
            </div>
            {plot.series.map((s, i) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>

                        <SystemDataItemSelector
                            style={{ flexGrow: 1 }}
                            slotPath={s.slotPath ?? []}
                            dataItem={s.dataItem}
                            onDataItemSelected={(val) => {
                                if (i >= 0 && val) {
                                    let newSeries = [...series]
                                    Object.assign(newSeries[i], val)
                                    setSeries(newSeries)
                                }
                            }}></SystemDataItemSelector>
                        <IconButton iconProps={{ iconName: "Settings" }}></IconButton>
                    </div>
                )
            })}
        </div>
    )
}

function fetchFromApi(dataItems, startTime, endTime, thresh) {
    const assetId = getAssetIdFromSystem()
    return fetch(`/api/historicaldata?columnar=true&dataItems=${dataItems.map(di => `${assetId}-${di}`)}&threshold=${thresh}&startTime=${startTime.toJSON()}&endTime=${endTime.toJSON()}`,
    )
        .then((res) => res.json())
        .catch(() => { return [[[]]] });
}