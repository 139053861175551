import { getTheme } from "@fluentui/react";

var lime = {
	defaultFontStyle: {
		fontFamily: "Proxima Nova Thin",
		fontSize: 12,
	},
	palette: {
		themePrimary: "#00d604",
		themeLighterAlt: "#000900",
		themeLighter: "#002201",
		themeLight: "#004001",
		themeTertiary: "#008102",
		themeSecondary: "#00bc03",
		themeDarkAlt: "#16da19",
		themeDark: "#36e039",
		themeDarker: "#66e868",
		neutralLighterAlt: "#2b2b2b",
		neutralLighter: "#333333",
		neutralLight: "#414141",
		neutralQuaternaryAlt: "#4a4a4a",
		neutralQuaternary: "#515151",
		neutralTertiaryAlt: "#6f6f6f",
		neutralTertiary: "#c8c8c8",
		neutralSecondary: "#d0d0d0",
		neutralPrimaryAlt: "#dadada",
		neutralPrimary: "#ffffff",
		neutralDark: "#f4f4f4",
		black: "#f8f8f8",
		white: "#212121",
	},
};
var deepwell = {
	palette: {
		themePrimary: "#ce0e2d",
		themeLighterAlt: "#fdf4f5",
		themeLighter: "#f7d2d9",
		themeLight: "#f0adb9",
		themeTertiary: "#e26479",
		themeSecondary: "#d42744",
		themeDarkAlt: "#ba0d2a",
		themeDark: "#9d0b23",
		themeDarker: "#74081a",
		neutralLighterAlt: "#101820",
		neutralLighter: "#101820",
		neutralLight: "#0f171e",
		neutralQuaternaryAlt: "#0e151c",
		neutralQuaternary: "#0e141b",
		neutralTertiaryAlt: "#0d131a",
		neutralTertiary: "#c8c8c8",
		neutralSecondary: "#d0d0d0",
		neutralPrimaryAlt: "#dadada",
		neutralPrimary: "#ffffff",
		neutralDark: "#f4f4f4",
		black: "#f8f8f8",
		white: "#101820",
	},
};
var libertyTheme = {
	palette: {
		themePrimary: "#e13126",
		themeLighterAlt: "#090202",
		themeLighter: "#240806",
		themeLight: "#430f0b",
		themeTertiary: "#871e17",
		themeSecondary: "#c52c22",
		themeDarkAlt: "#e3453a",
		themeDark: "#e85f56",
		themeDarker: "#ee877f",
		neutralLighterAlt: "#303030",
		neutralLighter: "#383838",
		neutralLight: "#464646",
		neutralQuaternaryAlt: "#4e4e4e",
		neutralQuaternary: "#555555",
		neutralTertiaryAlt: "#727272",
		neutralTertiary: "#c8c8c8",
		neutralSecondary: "#d0d0d0",
		neutralPrimaryAlt: "#dadada",
		neutralPrimary: "#ffffff",
		neutralDark: "#f4f4f4",
		black: "#f8f8f8",
		white: "#262626",
	},
};

var rangerTheme = {
	palette: {
		themePrimary: "#fdcc09",
		themeLighterAlt: "#0a0800",
		themeLighter: "#282102",
		themeLight: "#4c3d03",
		themeTertiary: "#977a06",
		themeSecondary: "#deb409",
		themeDarkAlt: "#fdd122",
		themeDark: "#fdd844",
		themeDarker: "#fee275",
		neutralLighterAlt: "#101010",
		neutralLighter: "#1a1a1a",
		neutralLight: "#292929",
		neutralQuaternaryAlt: "#333333",
		neutralQuaternary: "#3b3b3b",
		neutralTertiaryAlt: "#5c5c5c",
		neutralTertiary: "#c8c8c8",
		neutralSecondary: "#d0d0d0",
		neutralPrimaryAlt: "#dadada",
		neutralPrimary: "#ffffff",
		neutralDark: "#f4f4f4",
		black: "#f8f8f8",
		white: "#040404",
	},
};
export const spmTheme = {
	palette: {
		themePrimary: "#ffa711",
		themeLighterAlt: "#fffcf6",
		themeLighter: "#fff1d9",
		themeLight: "#ffe5b8",
		themeTertiary: "#ffcb71",
		themeSecondary: "#ffb22e",
		themeDarkAlt: "#e69710",
		themeDark: "#c2800e",
		themeDarker: "#8f5e0a",
		neutralLighterAlt: "#1e1e1e",
		neutralLighter: "#282828",
		neutralLight: "#363636",
		neutralQuaternaryAlt: "#404040",
		neutralQuaternary: "#474747",
		neutralTertiaryAlt: "#666666",
		neutralTertiary: "#c8c8c8",
		neutralSecondary: "#d0d0d0",
		neutralPrimaryAlt: "#dadada",
		neutralPrimary: "#ffffff",
		neutralDark: "#f4f4f4",
		black: "#f8f8f8",
		white: "#141414",
	},
};
var terra = {
	palette: {
		themePrimary: "#0078d4",
		themeLighterAlt: "#eff6fc",
		themeLighter: "#deecf9",
		themeLight: "#c7e0f4",
		themeTertiary: "#71afe5",
		themeSecondary: "#2b88d8",
		themeDarkAlt: "#106ebe",
		themeDark: "#005a9e",
		themeDarker: "#004578",
		neutralLighterAlt: "#282828",
		neutralLighter: "#313131",
		neutralLight: "#3f3f3f",
		neutralQuaternaryAlt: "#484848",
		neutralQuaternary: "#4f4f4f",
		neutralTertiaryAlt: "#6d6d6d",
		neutralTertiary: "#c8c8c8",
		neutralSecondary: "#d0d0d0",
		neutralPrimaryAlt: "#dadada",
		neutralPrimary: "#ffffff",
		neutralDark: "#f4f4f4",
		black: "#f8f8f8",
		white: "#1f1f1f",
	},
};
export const myTheme = getTheme;
export const myDarkTheme = spmTheme;
