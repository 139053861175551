export function defaultConfig(id) {
	return {
		name: "",
		min: 0,
		max: 100,
		unit: "%",
		band1: "#00ff00",
		band2: "#ffff00",
		band3: "#ff0000",
		band1End: 33,
		band2End: 66,
		style: "simple",
		fontNumbersSize: 18,
		fontTitleSize: 18,
		fontValueSize: 18,
		fontUnitsSize: 18,
		half: false,
	};
}
