import  {  useEffect, useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Stack, Dropdown } from "@fluentui/react";
import { executeFetch } from "../dashboard/components/FetchForm";
import { useHistory } from "react-router-dom";
import { _put } from "../../util";
import "mapbox-gl-controls/lib/controls.css";
import MapboxSources from "../../components/MapboxSources";
mapboxgl.accessToken =
	"pk.eyJ1Ijoia2JpZWJlciIsImEiOiJjazlrZ3c4OHkwNDY2M2RtdWIzZjczeW5zIn0.Js07hZZ-_djZb4RBF0zqnA";

export default function AssetMapbox(props) {
	let history = useHistory();
	const [layers, setLayers] = useState([]);
	const [sources, setSources] = useState({});
	const [mapStyle, setMapStyle] = useState(
		localStorage.mapStyle ?? "mapbox://styles/mapbox/streets-v11"
	);

	const assetSource = {
		type: "geojson",
		data: {
			type: "FeatureCollection",
			features: props.assets
				?.filter(
					(ass) =>
						ass.currentCoordinates?.latitude &&
						ass.currentCoordinates?.longitude
				)
				.map((ass) => {
					return {
						type: "Feature",
						geometry: {
							coordinates: [
								ass.currentCoordinates?.longitude ?? -95,
								ass.currentCoordinates?.latitude ?? 33,
							],
							type: "Point",
						},
						properties: {
							icon:
								ass.attributes.find(
									(att) =>
										att.attributeName.toLowerCase() ==
										"marker"
								)?.attributeValue ?? "/config/marker.png",
							asset: JSON.stringify(ass),
							severity: parseInt(
								ass.attributes.find(
									(att) =>
										att.attributeName.toLowerCase() ==
										"severity"
								)?.attributeValue
							),
							title: ass.assetAlias,
							url: "/assets/" + ass.assetId + "/dashboard",
						},
					};
				}),
		},
	};

	//Apply Styles

	//Load Extra configured layers. This really only needs to happen once ever.
	useEffect(() => {
		fetch("/config/geosources.json")
			.then((data) => {
				if (data.status == 200) {
					return data.json();
				} else {
					return { layers: [], sources: [] };
				}
			})
			.then(async (response) => {
				var newSources = {};
				for (var i in response.sources) {
					const src = response.sources[i];
					newSources[i] = {
						type: src.type,
						data: await executeFetch(src.fetch),
						tolerance: 0,
					};
				}
				setSources(newSources);
				setLayers(response.layers);
				setSelectedLayers([
					...response?.layers
						?.filter((l) => l.visibility != "none")
						.map((l) => l.id),
				]);
			})
			.catch(() => {});
	}, []);
	let [selectedLayers, setSelectedLayers] = useState();

	let mapBounds = [
		[-130, 25],
		[-65, 45],
	];
	var latitudes = props.assets
		.map((ass) => ass.currentCoordinates?.latitude)
		.filter((l) => l > -90 && l < 90);
	var longitudes = props.assets
		.map((ass) => ass.currentCoordinates?.longitude)
		.filter((l) => l > -180 && l < 180);
	if (latitudes.length > 0 && longitudes.length > 0) {
		mapBounds = [
			[Math.min(...longitudes) - 2, Math.min(...latitudes) - 2],
			[Math.max(...longitudes) + 2, Math.max(...latitudes) + 2],
		];
	}

	return (
		<div
			style={{ display: "flex", flexDirection: "column", height: "100%" }}
		>
			<Stack horizontal horizontalAlign="end">
				<Dropdown
					styles={{ dropdown: { width: 200 } }}
					selectedKey={mapStyle}
					options={[
						{
							text: "Street",
							key: "mapbox://styles/mapbox/streets-v11",
						},
						{
							text: "Outdoors",
							key: "mapbox://styles/mapbox/outdoors-v11",
						},
						{
							text: "Light",
							key: "mapbox://styles/mapbox/light-v10",
						},
						{
							text: "Dark",
							key: "mapbox://styles/mapbox/dark-v10",
						},
						{
							text: "Sat/Street",
							key: "mapbox://styles/mapbox/satellite-streets-v11",
						},
						{
							text: "Cali",
							key: "mapbox://styles/kbieber/ckuijpi1d8y5r17n3jwhplrle",
						},
					]}
					onChange={(e, val) => {
						setMapStyle(val.key);
						localStorage.mapStyle = val.key;
					}}
				></Dropdown>
				<Dropdown
					styles={{ dropdown: { width: 200 } }}
					multiSelect
					options={
						layers?.map((s) => {
							return { key: s.id, text: s.id };
						}) ?? []
					}
					selectedKeys={selectedLayers}
					onChange={(e, item) => {
						var newSelectedLayers = selectedLayers?.slice() ?? [];
						if (item) {
							if (item.selected) {
								newSelectedLayers.push(item.key);
							} else {
								newSelectedLayers = newSelectedLayers.filter(
									(key) => key !== item.key
								);
							}
							setSelectedLayers(newSelectedLayers);
						}
					}}
				></Dropdown>
			</Stack>
			<MapboxSources
				posFinder={
					props.assets.find((ass) => ass.currentCoordinates?.set) !=
					null
				}
				onMapMove={(l) => {
					if (props.onMapMove) {
						props.onMapMove(l);
					}
				}}
				onEditAsset={(asset) => {}}
				style={{ height: "100%", flexGrow: 1 }}
				layers={layers}
				history={history}
				selectedLayers={selectedLayers}
				mapStyle={mapStyle}
				sources={{ ...sources, Assets: assetSource }}
				defaultBounds={mapBounds}
			></MapboxSources>
		</div>
	);
}
