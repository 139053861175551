import { DetailsList, IconButton, Spinner, TextField } from "@fluentui/react"
import { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import {PaginatedTable} from "../../../../../components/PaginatedTable/PaginatedTable"
import PopoutColorPicker from "../../../../../components/PopoutColorPicker"
import DateTimePicker from "../../forms/DateTimePicker"


type Annotation = {
    assetId : number,
    cause : string,
    eventId : string,
    meta: string,
    metaExpanded:AnnotationMeta,
}

type AnnotationMeta = {
    start: Date,
    end:Date,
    highlight: string
}

export function useAnnotations (assetId : number) {
    return useQuery<Annotation[]>([`assets/events`,assetId], () =>
        fetch(`/api/events?assetId=${assetId}`).then(res =>
            res.json()
        ),
        {
            enabled: assetId > 0 ,
        }
    )
}
export default function Annotations(props: AnnotationsProps){
    const queryClient = useQueryClient()

    const {data: annotations,isLoading,error} = useAnnotations(props.assetId)
    const [changes,setChanges] = useState<Annotation[]>([])
    useEffect(()=>{
        if(annotations != undefined){
            setChanges(annotations);
        }
    },[annotations])
  

    if(isLoading || error || !annotations){return <Spinner></Spinner> } 
    // else if(error){
    //     return <div>{error.toString()}</div>
    // } 
    else{
        return <div title={JSON.stringify(changes)}>
        <PaginatedTable
        columns={[
            {
                key:"text",
                fieldName:"cause",
                name:"Note",
                onRenderComponent:(item ,column)=>{
                    item=item as Annotation
                    return <TextField underlined value = {item.cause} onChange={(e,val)=>{
                        let update = [...changes]
                        var match = update.find(e=>e.eventId == item.eventId);
                        if(match){
                            match.cause = val ?? ""
                        }
                        setChanges(update)
                    }}></TextField>
                }
            },
            {
                key:"start",
                fieldName:"metaExpanded",
                name:"Start time",
                onRenderComponent:(item ,column)=>{
                    return <DateTimePicker 
                    horizontal
                    minuteInterval={1}
                    datetime={item.metaExpanded.start} 
                    onDateChanged={
                        (date)=>{
                        let update = [...changes]
                        var match = update.find(e=>e.eventId == item.eventId);
                        if(match){
                            match.metaExpanded.start = date
                        }
                        setChanges(update)
                    } } ></DateTimePicker>
                }
              
                
            },
            {
                key:"end",
                fieldName:"metaExpanded",
                name:"End time",
                onRenderComponent:(item ,column)=>{
                    return <DateTimePicker 
                    horizontal
                    minuteInterval={1}
                     datetime={item.metaExpanded.end} 
                     onDateChanged={
                        (date)=>{
                        let update = [...changes]
                        var match = update.find(e=>e.eventId == item.eventId);
                        if(match){
                            match.metaExpanded.end = date
                        }
                        setChanges(update)
                    } } ></DateTimePicker>
                }
            },
            {
                key:"color",
                fieldName:"metaExpanded",
                onRenderComponent:(item ,column)=>{
                    item=item as Annotation
                    return (<PopoutColorPicker label = "" onChange={(e,color)=>{
                        let update = [...changes]
                        var match = update.find(e=>e.eventId == item.eventId);
                        if(match){
                            match.metaExpanded.highlight = color.str
                        }
                        setChanges(update)
                    }} color={item.metaExpanded.highlight}></PopoutColorPicker>)
                }
            },
            {
                key:"delete",
                fieldName:"eventId",
                onRenderComponent:(item ,column)=>{
                    item=item as Annotation
                    return (<IconButton onClick={()=>{
                        fetch("/api/events",{method:"PUT",body:JSON.stringify([{eventId:item.eventId,status:0}]),headers:{"Content-Type":"application/json"}})
                        .then(()=>{
                            queryClient.invalidateQueries(['assets/events'])
                        })
                    }} iconProps={{iconName:"ChromeClose"}}></IconButton>)
                }
            }
        ]}

        compact
        pageSize={10}
        selectionMode={0} items={changes}></PaginatedTable>
        </div> 
    }
}

type AnnotationsProps = {
    assetId: number,
    start:Date,
    end:Date
}