import "flot/lib/jquery.event.drag";
import "flot/lib/jquery.mousewheel";
import "flot/source/jquery.flot.axislabels";
import "flot/source/jquery.flot.crosshair";
import "flot/source/jquery.flot.navigate";
import "flot/source/jquery.flot.resize";
import "flot/source/jquery.flot.time";
import * as Papa from "papaparse";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { executeFetch } from "../../FetchForm";

const $ = window.$;
class XYChart extends React.Component {
	state = { data: [] };
	ref = createRef();

	componentDidMount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.drawFlot();
	}

	fetchData() {
		var settings = { ...this.props.properties.flot };
		var now = new Date();
		if (this.props.properties.flot) {
			executeFetch({ ...this.props.properties.flot.fetch }).then(
				(data) => {
					var papa = Papa.parse(data, {
						header: false,
						dynamicTyping: true,
					});
					papa.data.splice(0, 1);

					if (papa.data.length > 0) {
						this.setState({ lastLoad: now });
						this.transformData(papa.data);
					}
					if (settings.refresh) {
						if (this.timeout) {
							clearTimeout(this.timeout);
						}
						this.timeout = setTimeout(() => {
							this.fetchData();
						}, 1000 * (settings.refreshInterval ?? 5));
					}
				}
			);
		} else {
			this.transformData(this.props.df.data);
		}
	}

	transformData(data) {
		var newData = this.props.properties.flot.series.map((el) => {
			var x = { ...el };
			x["data"] = data
				.filter((dt) => {
					return [dt[el.xvalue] && dt[el.yvalue]];
				})
				.map((dt) => {
					return [dt[el.xvalue], dt[el.yvalue]];
				});
			return x;
		});
		var oldData = this.plot.getData();
		if (oldData.length > 0) {
			oldData.forEach((el, i) => {
				el.data = el.data.concat(newData[i].data);
			});
		} else {
			oldData = newData;
		}
		console.log(oldData);
		this.plot.setData(oldData);
		this.plot.draw();
		if (!this.first) {
			this.first = true;
			this.plot.zoom();
		}
		$(`#${this.props.properties.settingsGuid}-resizable text`).attr(
			"stroke",
			this.props?.properties?.flot?.fontColor ?? "#fff"
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return JSON.stringify(this.props) !== JSON.stringify(nextProps);
	}
	drawFlot() {
		if (this.props.properties.flot) {
			var placeholder = $(
				`#${this.props.properties.settingsGuid}-placeholder`
			);
			var settings = { ...this.props.properties.flot };
			var blankData = settings.series?.map((el) => {
				var x = { ...el };
				x["data"] = [];
				return x;
			});
			settings.yaxes.forEach((element) => {
				if (element.invert) {
					element.transform = function (v) {
						return -v;
					};
					element.inverseTransform = function (v) {
						return -v;
					};
				}
				if (element.mode == "time") {
				}
			});
			settings.xaxes.forEach((element) => {
				if (element.invert) {
					element.transform = function (v) {
						return -v;
					};
					element.inverseTransform = function (v) {
						return -v;
					};
				}
				if (element.mode == "time") {
				}
			});
			delete settings.series;
			settings.hooks = {
				draw: [
					() => {
						$(
							`#${this.props.properties.settingsGuid}-resizable text`
						).attr("stroke", settings.fontColor ?? "#fff");
					},
				],
			};
			this.plot = $.plot(placeholder, blankData, settings);
			this.plot.draw();
			placeholder.bind("plotpan", () => {
				$(`#${this.props.properties.settingsGuid}-resizable text`).attr(
					"stroke",
					settings.fontColor ?? "#fff"
				);
			});

			placeholder.bind("plotzoom", () => {
				$(`#${this.props.properties.settingsGuid}-resizable text`).attr(
					"stroke",
					settings.fontColor ?? "#fff"
				);
			});
			$(`#${this.props.properties.settingsGuid}-resizable text`).attr(
				"stroke",
				settings.fontColor ?? "#fff"
			);

			this.fetchData();
			placeholder.bind("plothover", (event, pos, item) => {
				if (!this.updateCrosshairIndicator && item) {
					this.updateCrosshairIndicator = setTimeout(() => {
						var crosshair = {
							label: item.series.label,
							x:
								item.series.xaxis.options.mode == "time"
									? new Date(
											item.datapoint[0]
									  ).toLocaleString()
									: item.datapoint[0].toFixed(2),
							y:
								item.series.yaxis.options.mode == "time"
									? new Date(
											item.datapoint[1]
									  ).toLocaleString()
									: item.datapoint[1].toFixed(2),
						};
						//console.log(this.setState)
						this.setState({ crosshair }, () => {
							this.forceUpdate();
							this.updateCrosshairIndicator = null;
						});
					}, 50);
				}
			});
		}
	}
	componentWillUnmount() {
		console.log("Flot unmounted");
		if (this.observer) {
			this.observer.disconnect();
		}
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		if (this.plot) {
			this.plot.shutdown();
		}
	}
	render() {
		var flot = { ...this.props.properties.flot };
		return (
			<>
				{flot && (
					<div
						id={`${this.props.properties.settingsGuid}-resizable`}
						style={{
							display: "flex",
							flexDirection: "column",
							height: "100%",
							width: "100%",
						}}
					>
						{flot.title && (
							<div
								style={{
									textAlign: "center",
									color: flot.fontColor,
								}}
							>
								{this.props.properties.flot.title}
							</div>
						)}
						<div
							style={{ flexGrow: 1 }}
							ref={this.ref}
							id={`${this.props.properties.settingsGuid}-placeholder`}
						></div>
						{flot.crosshair && (
							<div
								style={{ textAlign: "center" }}
							>{`${this.state.crosshair?.label} = ${this.state.crosshair?.x}, ${this.state.crosshair?.y}`}</div>
						)}
					</div>
				)}
			</>
		);
		// if(this.props.properties){
		//     return (
		//     <ReactFlot id="product-chart" options={this.props.properties} data={[0,1,2,3]} width="50%" height="100px" />
		//     );
		// }

		// else{
		//     return <div>No config</div>
		// }
	}
}
function mapStateToProps({ system }) {
	return { system };
}

export default connect(mapStateToProps)(XYChart);
