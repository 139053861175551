import {
	ActionButton,
	DefaultButton,
	DetailsList,
	DetailsRow,
	hiddenContentStyle,
	PrimaryButton,
	ProgressIndicator,
	Spinner,
	SpinnerSize,
	Stack,
	Dropdown,
	Toggle,
	SelectionMode,
} from "@fluentui/react";
import React, { Component } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../actions/index";
import {
	getAssetIdFromSystem,
	getBooleanAttribute,
	getJobEndTimeAttribute,
	getJobStartTimeAttribute,
	getNumericAttribute,
} from "../../../../../util";
import SnubWellKPIs from "./SnubWellKPIs";
import { ScheduleChart } from "../../../../jobs/jobs";
import { SchedulePieChartSimple } from "./SchedulePieChartSimple";



var colors = [
	"#3c0091",
	"#003891",
	"#660091",
	"#00ffff",
	"#ff0000",
	"#ff00fb",
	"#8000ff",
	"#00aaff",
	"#009155",
	"#916800",
	"#bb00ff",
	"#0f9100",
	"#ff8800",
	"#0051ff",
	"#99ff00",
	"#ffcc00",
	"#008c91",
	"#f2ff00",
	"#99ff00",
	"#00ffa6",
	"#660091",
	"#04ff00",
	"#ffcc00",
	"#00ffa6",

	"#912e00",
];
//["rgb(161,222,240)", "rgb(21,138,44)", "rgb(46,236,230)", "rgb(56,90,58)", "rgb(104,149,188)", "rgb(127,76,127)", "rgb(134,236,90)", "rgb(231,37,37)", "rgb(164,196,109)", "rgb(51,48,183)", "rgb(226,125,177)", "rgb(16,85,138)", "rgb(246,74,190)", "rgb(117,116,245)", "rgb(237,203,222)", "rgb(157,13,108)", "rgb(31,161,152)", "rgb(120,49,33)", "rgb(237,130,10)", "rgb(176,137,101)"]

let options = [];
for (var i = 0; i < 12; i++) {
	options.push({ key: i, text: `${i == 0 ? 12 : i}:00 AM` });
}
for (var i = 0; i < 12; i++) {
	options.push({ key: i + 12, text: `${i == 0 ? 12 : i}:00 PM` });
}

class StateReport extends Component {
	state = {
		schedule: [],
		filteredSchedule: [],
		message: "Loading...",
	};

	getToday() {
		let x = new Date();
		x.setHours(0, 0, 0, 0);
		return x;
	}

	componentDidMount() {
		this.retrieveSchedule();
	}
	componentDidUpdate(prevProps) {
		if (
			prevProps.system.attributes
				.map((att) => JSON.stringify(att))
				.join(",") !=
			this.props.system.attributes
				.map((att) => JSON.stringify(att))
				.join(",")
		) {
			this.filterSchedule();
		}
	}
	retrieveSchedule(refresh) {
		this.setState({
			message: "Getting schedule...",
			schedule: [],
			filteredSchedule: [],
		});
		var start = getJobStartTimeAttribute(this.props.system);
		var coeff = 1000 * 60 * 25; //round to closest five minutes
		var end = getJobEndTimeAttribute(this.props.system);
		var roundedEnd = new Date(Math.round(end.getTime() / coeff) * coeff);
		fetch(
			`/api/ef/assets/${
				this.props.system.assetId
			}/functions/schedule?startTime=${start.toJSON()}&endTime=${end.toJSON()}${
				refresh ? "&refresh=true" : ""
			}`
		)
			.then((req) => req.json())
			.then((schedule) => {
				this.setState({ message: "Sorting..." });
				var sched = schedule.map((el) => {
					el.startTime += "Z";
					el.endTime += "Z";
					return el;
				});
				var uniqueActivities = Array.from(
					new Set(sched.map((el) => el.activity))
				).sort();
				var colorMap = {};
				for (var i in uniqueActivities) {
					colorMap[uniqueActivities[i]] = colors[i]
				}
				this.setState(
					{
						message: "Filtering...",
						schedule: sched,
						colorMap: colorMap,
					},
					() => {
						this.filterSchedule();
					}
				);
			});
	}
	requestFilter() {
		clearTimeout(this.state.filterTimeout);
		this.setState({
			filterTimeout: setTimeout(this.filterSchedule.bind(this), 300),
		});
	}
	filterSchedule() {
		let schedule = this.state.schedule;
		let startDate = getJobStartTimeAttribute();
		let endDate = getJobEndTimeAttribute();

		let filtered = Array.from(schedule).filter((el) => {
			return el.assetId == this.props.system.assetId;
		});

		filtered = filtered.map((oj) => {
			let el = { startTime: "", endTime: "", activity: "" };
			Object.assign(el, oj);
			if (new Date(el.startTime) < startDate) {
				el.startTime = startDate.toJSON();
			}
			if (new Date(el.endTime) < startDate) {
				el.endTime = startDate.toJSON();
			}
			if (new Date(el.endTime) > endDate) {
				el.endTime = endDate.toJSON();
			}
			if (new Date(el.startTime) > endDate) {
				el.startTime = endDate.toJSON();
			}
			el.hours =
				(new Date(el.endTime).getTime() -
					new Date(el.startTime).getTime()) /
				(1000 * 3600);
			el.hourString =
				Math.floor(el.hours).toString().padStart(2, "0") +
				":" +
				parseInt((el.hours % 1) * 60)
					.toString()
					.padStart(2, "0");
			return el;
		});
		filtered = filtered.filter((el) => {
			return el.hours > 0;
		});
		filtered = filtered.sort((a, b) => {
			return new Date(a.startTime) - new Date(b.startTime);
		});
		var collapsedData = [];
		var previousActivity = "";
		var tihProgress = 0;
		var tohProgress = 0;
		var mwProgess = 0;
		var tihActive = 0;
		var tohActive = 0;
		var mwActive = 0;
		var td = getNumericAttribute("Total Depth (ft)", 0, this.props.system);
		var kd = getNumericAttribute("Kill Depth (ft)", 0, this.props.system);

		for (var i = 0; i < filtered.length; i++) {
			// var depth =
			// 	filtered[i].depth ??
			// 	this.props.currentData?.[this.props.system.assetId]?.[19]
			// 		.value ??
			// 	depth;
			var depth = filtered[i].depth ?? depth;
			if (previousActivity == filtered[i].activity) {
				collapsedData[collapsedData.length - 1].hours +=
					filtered[i].hours;
				collapsedData[collapsedData.length - 1].endTime =
					filtered[i].endTime;
			} else {
				collapsedData.push(Object.assign({}, filtered[i]));
			}
			previousActivity = filtered[i].activity;
			if (previousActivity == "Tripping In") {
				var newProgress = depth / kd;
				if (newProgress > tihProgress) {
					tihProgress = newProgress;
					tihActive = depth;
				}
			}
			if (previousActivity == "Tripping Out") {
				var newProgress = (td - depth) / td;
				if (newProgress > tohProgress) {
					tohProgress = newProgress;
					tohActive = td - depth;
				}
			}
			if (previousActivity == "Washdown" || previousActivity == "Drill") {
				var newProgress = (depth - kd) / (td - kd);
				if (newProgress > mwProgess) {
					mwProgess = newProgress;
					mwActive = depth - kd;
				}
			}
		}

		filtered = collapsedData;

		this.setState({
			message: "Building graph...",
			filteredSchedule: filtered,
		});
	}

	saveAttribute(att) {
		fetch(`/api/assets/${att.assetId}/attributes`, {
			method: "put",
			body: JSON.stringify(att),
			headers: { "content-type": "application/json" },
		}).then((res) => this.props.loadAsset());
	}
	getTimespanString(value) {
		if (value % 24 == 0) {
			return `${value / 24} Day${value == 24 ? "" : "s"}`;
		} else if (value < 24) {
			return `${value} Hours`;
		} else {
			return `${Math.floor(value / 24)} Day${
				Math.floor(value / 24) == 1 ? "" : "s"
			} ${value % 24} Hours`;
		}
	}

	shouldComponentUpdate(nextProps, nextstate) {
		return true;
	}
	render() {
		var isHighPressure = getBooleanAttribute(
			"High Pressure",
			0,
			this.props.system
		);
		var jobStart = getJobStartTimeAttribute();
		var rigUpEnd = new Date(
			jobStart.getTime() +
				getNumericAttribute("Rig Up Time", 0, this.props.system) *
					3600 *
					1000
		); //Straight hours
		var pressureTestEnd = new Date(
			rigUpEnd.getTime() +
				getNumericAttribute("BOP Count", 0, this.props.system) *
					0.5 *
					3600 *
					1000
		); //BOP count / (2 BOP/hr)
		var tihEnd = new Date(
			pressureTestEnd.getTime() +
				(getNumericAttribute("Kill Depth (ft)", 0, this.props.system) /
					(31 * (isHighPressure ? 45 : 55))) *
					3600 *
					1000
		); // (kill depth / 31 ft/join ) / 55 j/hr
		var millEnd = new Date(
			tihEnd.getTime() +
				((getNumericAttribute(
					"Total Depth (ft)",
					0,
					this.props.system
				) -
					getNumericAttribute(
						"Kill Depth (ft)",
						0,
						this.props.system
					)) /
					(31 * 10)) *
					3600 *
					1000 +
				+getNumericAttribute("Circulation Time", 0, this.props.system) *
					3600 *
					1000
		); // (total - kill depth) / 31 ft/join ) / 10 j/hr
		var circEnd = new Date(
			millEnd.getTime() +
				+getNumericAttribute("Bottom Hole Circulation Time", 0, this.props.system) *
					3600 *
					1000
		); // (total - kill depth) / 31 ft/join ) / 10 j/hr
		var tohEnd = new Date(
			millEnd.getTime() +
				(getNumericAttribute("Total Depth (ft)", 0, this.props.system) /
					(31 * (isHighPressure ? 45 : 55))) *
					3600 *
					1000
		); // (total depth / 31 ft/join ) / 55 j/hr

		var depthProjection = [
			{
				timestamp: jobStart,
				value: 0,
			},
			{
				timestamp: rigUpEnd,
				value: 0,
			},
			{
				timestamp: pressureTestEnd,
				value: 0,
			},
			{
				timestamp: tihEnd,
				value: getNumericAttribute(
					"Kill Depth (ft)",
					0,
					this.props.system
				),
			},
			{
				timestamp: millEnd,
				value: getNumericAttribute(
					"Total Depth (ft)",
					0,
					this.props.system
				),
			},
			{
				timestamp: circEnd,
				value: getNumericAttribute(
					"Total Depth (ft)",
					0,
					this.props.system
				),
			},
			{
				timestamp: tohEnd,
				value: 0,
			},
		];

		return (
			<Stack>
				<Stack>
					<Stack horizontal horizontalAlign="end">
						<ActionButton
							iconProps={{ iconName: "Refresh" }}
							text="Refresh"
							onClick={() => {
								this.setState({});
								this.retrieveSchedule(true);
							}}
						></ActionButton>
					</Stack>
					{this.state.filteredSchedule.length > 0 ? (
						<>
							{/* {this.props.properties.showPie && (
								<SchedulePieChart
									id="pieChart"
									colorMap={this.state.colorMap}
									data={this.state.filteredSchedule}
								></SchedulePieChart>
							)} */}
								{this.props.properties.showPie && (
								<SchedulePieChartSimple
									id="pieChart"
									colorMap={this.state.colorMap}
									data={this.state.filteredSchedule}
								></SchedulePieChartSimple>
							)}

							{/* {this.props.properties.showGraph && (
								<StateChart
									dataItems={[
										{
											assetId: this.props.system.assetId,
											dataItemId: 19,
											label: "Depth",
											unit: "ft",
											color: "rgb(103, 183, 220)",
											min: 0,
											max: 30000,
											inversed: true,
											projection: depthProjection,
										},
									]}
									colorMap={this.state.colorMap}
									endDate={getJobEndTimeAttribute()}
									data={this.state.filteredSchedule}
								></StateChart>
							)} */}
							{this.props.properties.showGraph && (
								<ScheduleChart
									projection= {depthProjection}
									colorMap={this.state.colorMap}
									endDate={getJobEndTimeAttribute()}
									schedule={this.state.filteredSchedule}
								></ScheduleChart>
							)}
							{this.props.properties.showKPI && (
								<SnubWellKPIs
									scale={1}
								    onlyProgress = {this.props.properties.onlyProgress}
									filteredSchedule={
										this.state.filteredSchedule
									}
									system={this.props.system}
									
									depth={this.props.currentData[this.props?.system?.assetId]?.[19]?.value}
									plugCount={this.props.currentData[this.props?.system?.assetId]?.[39]?.value}
								></SnubWellKPIs>
							)}
							{this.props.properties.showLog && (
								<>
									<DetailsList
										compact
										selectionMode={SelectionMode.none}
										columns={[
											{
												key: "Activity",
												name: "Activity",
												minWidth: 150,
												maxWidth: 150,
												fieldName: "activity",
											},
											{
												key: "Start",
												name: "Start time",
												fieldName: "startTime",
												minWidth: 150,
												maxWidth: 150,
												onRender: (item) => {
													return (
														new Date(
															item.startTime
														).toLocaleDateString() +
														" " +
														new Date(
															item.startTime
														).toLocaleTimeString()
													);
												},
											},
											{
												key: "End",
												name: "End time",
												minWidth: 150,
												maxWidth: 150,
												fieldName: "endTime",
												onRender: (item) => {
													return (
														new Date(
															item.endTime
														).toLocaleDateString() +
														" " +
														new Date(
															item.endTime
														).toLocaleTimeString()
													);
												},
											},
											{
												key: "depth",
												name: "Depth",
												minWidth: 150,
												maxWidth: 150,
												onRender: (item) =>
													item.depth?.toFixed(0) +
													" ft",
												fieldName: "depth",
											},
											{
												key: "newActvity",
												name: "Change activity",
												minWidth: 150,
												maxWidth: 150,
												fieldName: "activity",
												onRender: (item, i) => {
													return (
														<UpdateableActivity
															item={item}
															onUpdated={() =>
																this.retrieveSchedule(
																	true
																)
															}
														></UpdateableActivity>
													);
												},
											},
										]}
										items={[...this.state.filteredSchedule].reverse()}
										onSelection
										onRenderRow={(props) => {
											const customStyles = {};
											if (props) {
												// Every other row renders with a different background color
												customStyles.root = {
													backgroundColor:
														this.state.colorMap[
															props.item.activity
														] + "55",
												};
												return (
													<DetailsRow
														{...props}
														styles={customStyles}
													/>
												);
											}
											return null;
										}}
									></DetailsList>
								</>
							)}
						</>
					) : (
						<Stack horizontalAlign="center" verticalAlign="center">
							<Spinner
								label={this.state.message}
								size={SpinnerSize.large}
							></Spinner>{" "}
						</Stack>
					)}
				</Stack>
			</Stack>
		);
	}
}
function UpdateableActivity(props) {
	var item = props.item;
	let [editable, setEditable] = useState(false);
	let [changeApplying, setChangeApplying] = useState(false);
	let [readyToReload, setReadyToReload] = useState(false);
	let [newSelection, setNewSelection] = useState(
		activities.find((el) => el.text == item.activity)?.key ?? -1
	);
	return (
		<Stack horizontal verticalAlign="center" tokens={{ childrenGap: 20 }}>
			<Toggle
				offText="Toggle to edit"
				onText="Toggle to revert"
				chcecked={editable}
				onChange={(ev, check) => {
					setEditable(check);
					if (!check) {
						setNewSelection(
							activities.find((el) => el.text == item.activity)
								?.key ?? -1
						);
					}
				}}
			></Toggle>
			<Dropdown
				disabled={!editable}
				readOnly={!editable}
				selectedKey={newSelection}
				options={activities}
				onChange={(e, val) => {
					setNewSelection(val.key);
				}}
			></Dropdown>
			{editable &&
				newSelection !=
					activities.find((el) => el.text == item.activity)?.key && (
					<>
						{!changeApplying && !readyToReload && (
							<PrimaryButton
								text="Apply change"
								onClick={() => {
									setChangeApplying(true);
									fetch("/api/ef/historicaldataupdate", {
										method: "PUT",
										body: JSON.stringify([
											{
												assetId: getAssetIdFromSystem(),
												dataItemId: 148,
												startDate: item.startTime,
												endDate: item.endTime,
												newValue: newSelection,
											},
										]),
										headers: {
											"Content-Type": "application/json",
										},
									}).then((r) => {
										setChangeApplying(false);
										setReadyToReload(true);
									});
								}}
							></PrimaryButton>
						)}

						{changeApplying && <Spinner></Spinner>}
						{readyToReload && (
							<PrimaryButton
								text="Reload Schedule"
								onClick={() => {
									if (props.onUpdated) {
										props.onUpdated();
									}
								}}
							></PrimaryButton>
						)}
					</>
				)}
		</Stack>
	);
}
function mstp({ system, currentData }) {
	return {
		system: { assetId: system.assetId, attributes: system.attributes },
		currentData,
	};
}

export default connect(mstp, actions)(StateReport);
const activities = [
	{
		key: 0,
		text: "Tripping In",
	},
	{
		key: 1,
		text: "Circulating",
	},
	{
		key: 2,
		text: "Drill",
	},
	{
		key: 3,
		text: "Washdown",
	},
	{
		key: 4,
		text: "Other",
	},
	{
		key: 5,
		text: "Tripping Out",
	},
	{
		key: 6,
		text: "Tripping in production",
	},
	{
		key: 7,
		text: "BOP Press Test",
	},
	{
		key: 8,
		text: "Ram Change",
	},
	{
		key: 9,
		text: "Maintenance",
	},
	{
		key: 10,
		text: "Rig Up",
	},
	{
		key: 11,
		text: "Rig Over",
	},
	{
		key: 12,
		text: "Rig Down",
	},
];
