export const projection = `Depth (ft),Hookload - Slack Off (lbs),Hookload - Pick Up (lbs),Surface Torque (ft-lbs)
90,5605,5606,0
180,6210,6213,0
270,6813,6821,0
360,7415,7430,0
450,8016,8040,0
540,8617,8650,0
630,9217,9262,0
720,9815,9875,0
810,10406,10495,0
900,11002,11111,0
990,11596,11728,0
1080,12182,12353,0
1170,12772,12974,0
1260,13361,13597,0
1350,13949,14221,0
1440,14535,14847,0
1530,15119,15474,0
1620,15703,16102,0
1710,16287,16730,0
1800,16868,17361,0
1890,17441,18000,0
1980,18006,18644,0
2070,18565,19288,0
2160,19122,19926,0
2250,19659,20594,0
2340,20193,21258,0
2430,20727,21918,0
2520,21259,22587,0
2610,21781,23268,0
2700,22302,23948,0
2790,22823,24630,0
2880,23343,25313,0
2970,23863,25996,0
3060,24384,26680,0
3150,24904,27364,0
3240,25426,28049,0
3330,25946,28735,0
3420,26464,29421,0
3510,26984,30107,0
3600,27505,30792,0
3690,28026,31481,0
3780,28547,32169,0
3870,29067,32859,0
3960,29582,33551,0
4050,30095,34242,0
4140,30611,34929,0
4230,31128,35617,0
4320,31644,36307,0
4410,32160,36998,0
4500,32674,37692,0
4590,33187,38389,0
4680,33697,39089,0
4770,34205,39792,0
4860,34712,40496,0
4950,35217,41202,0
5040,35721,41909,0
5130,36225,42616,0
5220,36728,43324,0
5310,37231,44033,0
5400,37734,44742,0
5490,38239,45452,0
5580,38742,46163,0
5670,39241,46875,0
5760,39742,47586,0
5850,40247,48300,0
5940,40750,49012,0
6030,41247,49723,0
6120,41746,50435,0
6210,42249,51148,0
6300,42752,51865,0
6390,43252,52582,0
6480,43748,53295,0
6570,44249,54013,0
6660,44752,54737,0
6750,45249,55464,0
6840,45739,56193,0
6930,46230,56923,0
7020,46720,57659,0
7110,47208,58392,0
7200,47695,59127,0
7290,48181,59862,0
7380,48663,60602,0
7470,49143,61341,0
7560,49624,62083,0
7650,50102,62829,0
7740,50580,63579,0
7830,51056,64331,0
7920,51533,65086,0
8010,52008,65843,0
8100,52483,66600,0
8190,52959,67359,0
8280,53438,68121,0
8370,53915,68888,0
8460,54390,69660,0
8550,54832,70459,0
8640,55235,71259,0
8730,55620,72013,0
8820,55916,72816,0
8910,56187,73555,0
9000,56398,74215,0
9090,60003,74780,966
9180,60134,75242,985
9270,60167,75571,1002
9360,60119,75792,1019
9450,60040,75973,1035
9540,59961,76157,1051
9630,59889,76353,1067
9720,59815,76550,1084
9810,59740,76750,1100
9900,59669,76957,1117
9990,59598,77170,1133
10080,59518,77373,1150
10170,59440,77584,1166
10260,59364,77800,1183
10350,59284,78015,1200
10440,59214,78246,1216
10530,59139,78475,1233
10620,59057,78696,1250
10710,58978,78923,1268
10800,58898,79153,1285
10890,58809,79369,1302
10980,58724,79600,1319
11070,58652,79872,1336
11160,58572,80135,1354
11250,58484,80389,1371
11340,58398,80655,1388
11430,58307,80921,1406
11520,58215,81191,1423
11610,58123,81466,1442
11700,58032,81746,1460
11790,57942,82029,1478
11880,57852,82313,1497
11970,57762,82598,1515
12060,57671,82882,1533
12150,57580,83167,1552
12240,57491,83456,1570
12330,57398,83739,1589
12420,57299,84013,1607
12510,57199,84286,1626
12600,57102,84565,1645
12690,57003,84841,1664
12780,56906,85122,1683
12870,56811,85408,1702
12960,56714,85693,1721
13050,56617,85977,1741
13140,56532,86283,1759
13230,56429,86558,1779
13320,56336,86851,1798
13410,56255,87167,1817
13500,56168,87473,1836
13590,56076,87770,1855
13680,55985,88070,1874
13770,55897,88374,1894
13860,55792,88650,1913
13950,55697,88943,1933
14040,55610,89251,1952
14130,55521,89557,1971
14220,55418,89840,1991
14310,55313,90117,2010
14400,55211,90403,2030
14490,55130,90724,2049
14580,55033,91019,2069
14670,54933,91309,2088
14760,54840,91612,2108
14850,54747,91918,2127
14940,54655,92225,2147
15030,54562,92530,2166
15120,54468,92835,2186
15210,54371,93136,2206
15300,54265,93419,2226
15390,54167,93719,2245
15480,54067,94016,2265
15570,53969,94316,2285
15660,53878,94630,2305
15750,53780,94934,2324
15840,53698,95264,2344
15930,53611,95587,2363
16020,53495,95861,2383
16110,53396,96162,2403
16200,53296,96464,2423
16290,53199,96772,2443
16380,53099,97075,2463
16470,53003,97387,2483
16560,52909,97702,2503
16650,52813,98014,2523
16740,52711,98317,2543
16830,52609,98621,2563
16920,52505,98923,2584
17010,52401,99223,2604
17100,52303,99535,2624
17190,52216,99867,2644
17280,52106,100160,2664
17370,51999,100457,2685
17460,51908,100784,2705
17550,51797,101077,2726
17640,51678,101356,2747
17730,51569,101652,2767
17820,51462,101955,2788
17910,51356,102259,2809
18000,51247,102561,2830
18090,51146,102878,2851
18180,51057,103217,2871
18270,50939,103505,2893
18360,50840,103826,2913
18450,50782,104221,2932
18540,50698,104570,2952
18630,50582,104863,2974
18720,50478,105180,2995
18810,50379,105506,3016
18900,50265,105808,3037
18990,50159,106124,3059
19080,50046,106429,3081
19170,49951,106766,3102
19260,49855,107100,3123
19350,49755,107431,3144
19440,49658,107769,3165
19530,49548,108085,3187
19620,49431,108392,3209
19710,49318,108703,3232
19800,49215,109031,3253
19880,49126,109326,3272`;
