import React, { PureComponent } from "react";
import FastGraph from "./uPlot/FastGraph";

class AdvancedGraph extends PureComponent {
	render() {
		// if(this.props.properties.library =="amcharts"){
		// 	return <AdvancedAmCharts {...this.props}></AdvancedAmCharts>
		// }
		// else{
		// 	return <FastGraph {...this.props}></FastGraph>
		// }
		return <FastGraph {...this.props}></FastGraph>;
	}
}
export default AdvancedGraph;
