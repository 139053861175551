import { getTheme } from "@fluentui/react";
import * as flot from "flot";
import "flot/lib/jquery.event.drag";
import "flot/lib/jquery.mousewheel";
import "flot/source/jquery.flot.axislabels";
import "flot/source/jquery.flot.crosshair";
import "flot/source/jquery.flot.navigate";
import "flot/source/jquery.flot.resize";
import "flot/source/jquery.flot.time";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { getAssetIdFromSystem } from "../../../../../util/index";

const $ = window.$;
class BarChartFlot extends React.Component {
	state = { data: [] };
	ref = createRef();

	componentDidMount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		$("<div class='tooltip' id='tooltip'></div>")
			.css({
				position: "absolute",
				display: "none",
				border: "1px solid white",
				padding: "5px",
				color: "black",
				"background-color": "#fee",
				opacity: 1,
			})
			.appendTo("body");
		this.drawFlot();
	}
	cleanColor(color) {
		if (color?.length == 6 || color?.length == 3) {
			return "#" + color;
		} else {
			return color;
		}
	}
	drawFlot() {
		var theme = getTheme();
		var plots = [...(this.props.properties.plots ?? [])];
		if (!this.props.properties.plots && this.props.properties.dataItems) {
			plots = this.props.properties.dataItems.map((el) => {
				return {
					dataItem: el,
					slotPath: [],
					color:
						this.cleanColor(this.props.properties.color) ??
						"#ff0000",
				};
			});
		}
		var placeholder = $(
			`#${this.props.properties.settingsGuid}-placeholder`
		);

		var data = plots?.map((plot, i) => {
			var assetId = getAssetIdFromSystem(null, plot.slotPath);
			return {
				data: [
					[
						i,
						this.props.currentData[assetId]?.[
							plot.dataItem.dataItemId
						]?.value ?? 0,
					],
				],
				color: plot.color,
			};
		});
		var ticks = plots?.map((plot, i) => {
			return [i, plot.dataItem.label];
		});
		var options = {
			series: {
				bars: {
					show: true,
					align: "center",
					lineWidth: 0,
					fill: 0.75,
				},
			},
			bars: {
				align: "center",
				barWidth: 0.5,
			},
			xaxis: {
				// drop the categories plugin and label the ticks yourself
				// you'll thank me in the long run
				ticks: ticks,
			},
			yaxis: {
				min: this.props.properties.min,
				max: this.props.properties.max,
			},
			legend: {
				noColumns: 0,
				labelBoxBorderColor: "#000000",
				position: "nw",
			},
			grid: {
				hoverable: true,
				borderWidth: 2,
				//backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
			},
		};

		options.hooks = {
			draw: [
				() => {
					$(
						`#${this.props.properties.settingsGuid}-resizable text`
					).attr("stroke", options.fontColor ?? theme.palette.black);
				},
			],
		};

		placeholder.bind("plotpan", () => {
			$(`#${this.props.properties.settingsGuid}-resizable text`).attr(
				"stroke",
				options.fontColor ?? theme.palette.black
			);
		});

		placeholder.bind("plotzoom", () => {
			$(`#${this.props.properties.settingsGuid}-resizable text`).attr(
				"stroke",
				options.fontColor ?? theme.palette.black
			);
		});
		$(`#${this.props.properties.settingsGuid}-resizable text`).attr(
			"stroke",
			options.fontColor ?? theme.palette.black
		);

		this.plot = $.plot(placeholder, data ?? [], options);
		this.plot.draw();

		placeholder.bind("plothover", function (event, pos, item) {
			if (!pos.x || !pos.y) {
				return;
			}

			if (item) {
				var x = item.datapoint[0].toFixed(2),
					y = item.datapoint[1].toFixed(2);
				$("#tooltip")
					.html(
						item.series.xaxis.ticks[item.datapoint[0]].label +
							": " +
							y
					)
					.css({
						"font-size": "20px",
						top: item.pageY + 5,
						left: item.pageX + 5,
						"background-color": item.series.color,
					})
					.fadeIn(0);
			} else {
				$("#tooltip").hide();
			}
		});

		placeholder.bind("plothovercleanup", function (event, pos, item) {
			$("#tooltip").hide();
		});

		$("#placeholder").bind("plotclick", (event, pos, item) => {
			if (item) {
				$("#clickdata").text(
					" - click point " +
						item.dataIndex +
						" in " +
						item.series.label
				);
				this.plot.highlight(item.series, item.datapoint);
			}
		});
	}
	componentDidUpdate(prevProps, prevState) {
		this.drawFlot();
	}
	componentWillUnmount() {
		if (this.observer) {
			this.observer.disconnect();
		}
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		if (this.plot) {
			this.plot.shutdown();
		}
	}
	render() {
		return (
			<div
				id={`${this.props.properties.settingsGuid}-resizable`}
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					width: "100%",
				}}
			>
				{this.props.properties.title && (
					<div style={{ textAlign: "center", color: flot.fontColor }}>
						{this.props.properties.title}
					</div>
				)}
				<div
					style={{ flexGrow: 1 }}
					ref={this.ref}
					id={`${this.props.properties.settingsGuid}-placeholder`}
				></div>
				{flot.crosshair && (
					<div
						style={{ textAlign: "center" }}
					>{`${this.state.crosshair?.label} = ${this.state.crosshair?.x}, ${this.state.crosshair?.y}`}</div>
				)}
			</div>
		);
	}
}
function mapStateToProps({ currentData }) {
	return { currentData };
}

export default connect(mapStateToProps)(BarChartFlot);
