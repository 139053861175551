import React, { Component } from "react";
import { connect } from "react-redux";

import { Check, Checkbox, Stack } from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";

class GeneralForm extends Component {
	state={}
	componentDidMount() {
		
	}

	handleChange = (key, val) => {
		this.props.addData({ [key]: val });
	};

	render() {
		const { decimals, fontSize, overflow,fullscreen } = this.props.properties;

		return (
			<Stack vertical tokens={{ childrenGap: 5 }}>
				<TextField
					label="Decimals"
					value={isNaN(parseInt(decimals)) ? "" : decimals}
					type="number"
					onChange={(e, val) => {
						this.handleChange("decimals", parseInt(val));
					}}
					id="decimals"
					name="decimals"
				/>
				<TextField
					label="Font Size"
					value={isNaN(parseInt(fontSize)) ? "" : fontSize }
					type="number"
					onChange={(e, val) => {
						this.handleChange("fontSize", parseInt(val));
					}}
					id="fontSize"
					name="fontSize"
				/>
				<Dropdown
					label="Overflow"
					selectedKey={overflow || "hide"}
					options={[
						{ key: "visible", text: "Visible" },
						{ key: "hidden", text: "Cut Off Height" },
						{ key: "scroll", text: "Vertical Scroll" },
					]}
					onChange={(e, val) => {
						this.handleChange("overflow", val.key);
					}}
					id="overflow"
					name="overflow"
				/>
				<Checkbox label="Allow full screen" checked={fullscreen ?? false} onChange={(e,checked)=>this.handleChange("fullscreen", checked)}></Checkbox>
			</Stack>
		);
	}
}



export default GeneralForm;
