import React, { useEffect,useState,useRef,useMemo } from 'react'

import {RadialGauge} from 'canvas-gauges'
import { getTheme } from '@fluentui/react'


export default function CanvasGauge(props){
  let gauge = useRef()
  let el =useRef()
  let containerRef = useRef()
  let theme =getTheme()
  useEffect(()=>{
    const options = Object.assign({}, gaugeStyle(props), {
      renderTo: el.current
    })
    gauge.current=new RadialGauge(options)
    gauge.current.draw()
  
  },[])
  let resizeGauge = () => {
    window.devicePixelRatio = 1.4;
    if (el.current != null) {
        el.current.style.width = "100%";
        el.current.style.height = "100%";
        if (
            el.current.offsetHeight > 50 &&
            el.current.offsetWidth > 50
        ) {
            
            gauge.current.update({
                height: el.current.offsetHeight,
                width: el.current.offsetWidth,
            });
            
        }
    }
  }
  useEffect(()=>{

      if(el.current){
        let observer = new ResizeObserver(()=>resizeGauge())
        observer.observe(containerRef.current);
      }
  },[el.current])
  useEffect(()=>{
   
    if(gauge.current){
      gauge.current.value = props.value
      gauge.current.update(gaugeStyle(props))
      gauge.current.update({ value: props.value })
    }
  },[props.value,theme.palette.neutralDark,props.highlights])


 

  return (
    <div ref={containerRef} style={{...props.style,width:"100%",height:"100%",margin:0,padding:0}} >
      <canvas style={{width:"100%",height:"244px",margin:0,padding:0}} ref={el} />
    </div>
    
  )
}

function nFormatter(num, digits) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (
    (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
  );
}

let gaugeStyle = (options) => {
  let theme = getTheme()
  var ticks = [];
  var delta = (options.maxValue - options.minValue) / 10;
  for (var i = 0; i < 11; i++) {
    ticks.push(nFormatter(options.minValue + i * delta, 2));
  }
  return {colorBorderOuter: "#00000000",
  colorBorderOuterEnd: "#00000000",
  colorBorderMiddle: "#00000000",
  colorBorderMiddleEnd: "#00000000",
  colorBorderInner: "#00000000",
  colorBorderInnerEnd: "#00000000",
  animationDuration:500,
  colorMajorTicks: theme.palette.neutralDark,
  colorMinorTicks: theme.palette.neutralDark,
  colorTitle: theme.palette.neutralDark,
  ticksAngle: 240,
  startAngle: 60,
  valueBox: true,
  valueTextShadow: false,
  colorUnits: theme.palette.neutralDark,
  colorNumbers: theme.palette.neutralDark,
  colorPlate: theme.palette.neutralLight,
  colorPlateEnd: theme.palette.neutralLight + "00",
  majorTicks: ticks,
  ...options,
    
  }
};