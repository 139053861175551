import {
	Stack,
	TextField,
	Slider,
	DefaultButton,
	getTheme,
	DefaultEffects,
	IconButton,
} from "@fluentui/react";
import React, { Component, CSSProperties, useMemo, useRef } from "react";
import { useEffect } from "react";
import "./PaginatedTable.scss";
import { download } from "../../util";
import { filter } from "@amcharts/amcharts4/.internal/core/utils/Iterator";

type Column = {
	/** A Unique ID for the column */
	id?:string  ,
	/** A Unique key for the column */
	key?:string  ,
	/** a function to define how this column appears in the CSV export */
	export?: CellExporter  ,
	name?:string  ,
	fieldName:string  ,
	visible?:boolean  ,
	isSorted?:boolean  ,
	isSortedDescending?:boolean  ,
	onRenderComponent?:CellComponentRenderer ,
	onRender?:CellRenderer  ,
	onRenderContent?:CellContentRenderer  ,
	cellStyle?:CSSProperties ,
	sort?:ItemSortFunction | boolean
}

type CellComponentRenderer = (item: any, column:Column)=> JSX.Element
type CellRenderer = (item: any,itemIndex: number, column:Column)=> JSX.Element
type CellContentRenderer = (item: any, column:Column)=> string
type CellExporter = (item: any, column:Column)=> string

type OnActiveItemChanged = (item: any, index: number, event:React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void

type OnRowClick = (event:React.MouseEvent<HTMLTableRowElement, MouseEvent>, item: any, index: number, ) => void
type OnRenderSelected = ()=> React.Component
type RowBackgoundColor = (item:any) => string 
type ItemSortFunction = (a:any,b:any)=>number
export enum PageSize { 
	Auto=-1
}
export enum SelectionMode {
	None=0,
	Single=1
}



type TableFilterFunction = (item: Object,search:string) => boolean
type PaginatedTableProps = {
	columns?:Column[] ,
	items:any[],
	compact?:boolean ,
	search?: string,
	pageSize? : number | PageSize,
	style?:CSSProperties,
	tableStyle?:CSSProperties,
	filter?:TableFilterFunction,
	showSearch?:boolean,
	showPageSize?:boolean,
	showSlider?:boolean,
	showExport?:boolean,
	selectionMode?:SelectionMode,
	title?:string,
	rowBackgroundColor?:RowBackgoundColor | string,
	selectedKeys?:number[],
	onActiveItemChanged?:OnActiveItemChanged,
	onRenderSelected?:OnRenderSelected,
	onRowClick?:OnRowClick
}
export  function PaginatedTable(props : PaginatedTableProps) {
	var itemsIsArray =
		Object.prototype.toString.call(props.items) == "[object Array]";
	var itemsElementIsObject =
		Object.prototype.toString.call(props.items?.[0]) == "[object Object]";

	let ref = useRef<HTMLTableElement>(null);
	let theme = getTheme();
	let [search, setSearch] = React.useState(props.search ?? "");

	let [pageNum, setPageNum] = React.useState(0);
	let [pageSize, setPageSize] = React.useState(props.pageSize ?? 10);

	let [columns, setColumns] = React.useState(props.columns ?? []);
	let [items, setItems] = React.useState(props.items ?? []);
	
	useEffect(() => {
		if (props.columns) {
			setColumns(props.columns);
		} else if (items[0] && !props.columns) {
			var autoColumns = Object.keys(items[0]).map((c) => {
				return { key: c, name: c, fieldName: c };
			});
			setColumns(autoColumns);
		} else {
			setColumns([]);
		}
		
	}, [props.columns, items[0]]);
	useEffect(()=>{
		var sortedColumn = props.columns?.find(c=>c.isSorted)
		if(sortedColumn){
			_sortColumn(undefined,sortedColumn)
		}
	},[props.items,props.columns])
	useEffect(() => setItems(props.items), [props.items]);
	useEffect(() => {
		if(props.pageSize == PageSize.Auto && ref.current){
			let calcPageSize=Math.floor(ref.current.clientHeight/55)
			console.log(ref.current,calcPageSize)
			setPageSize(calcPageSize)
		}
		else{
			setPageSize(props.pageSize ?? 10)
		}
		
	}, [props.pageSize,ref.current?.clientHeight]);
	
	let filteredItems = useMemo(()=>{
		if (typeof props.filter == "function" && props.items.length > 0) {
			return props?.items?.filter((el) => {
				if (typeof props.filter == "function") {
					return props.filter(el, search);
				} else {
					return true;
				}
			})
		} 
		else {
			return props.items;
		}
		
	} ,[items,search]);
		
		
	let maxPageNum = Math.floor(filteredItems.length  / pageSize) ;

	let visibleItems = useMemo(()=>{
		return filteredItems.slice(
			pageNum * pageSize,
			(pageNum + 1) * pageSize
		);
	},[filteredItems,pageNum,pageSize]) 
	
	
	let pageNavStyle = { fontSize: "1.8rem", margin: 10 };
	let pageLinks = [];
	for (var i = 0; i <= maxPageNum; i++) {
		pageLinks.push(i);
	}
	let _sortColumn = (ev:Event | undefined, column:Column) => {
		if (
			typeof column.sort == "function" ||
			typeof column.sort == "boolean"
		) {
			const newColumns = columns.slice();
			const currColumn = newColumns.filter(
				(currCol) => column.key === currCol.key
			)[0];
			let isSortedDescending = false;
			newColumns.forEach((newCol) => {
				if (newCol === currColumn) {
					isSortedDescending = !currColumn.isSortedDescending;
					currColumn.isSortedDescending =
						!currColumn.isSortedDescending;
					currColumn.isSorted = true;
				} else {
					newCol.isSorted = false;
					newCol.isSortedDescending = true;
				}
			});
			let newItems = items;
			if (typeof column.sort == "function") {
				newItems = items.sort(column.sort);
			} else if (typeof column.sort == "boolean" && column.sort) {
				newItems = items.sort((i1, i2) => {
					var field = column.fieldName ?? "";
					var v1 = i1[field as keyof typeof i1]?.toString()
					var v2 = i2[field  as keyof typeof i2]?.toString()
					return v1.localeCompare(v2);
				});
			}

			if (isSortedDescending) {
				newItems.reverse();
			}
			setColumns(newColumns);
			setItems(newItems);
		}
	};

	if (!(itemsIsArray && itemsElementIsObject)) {
		return <Stack>No data...</Stack>;
	}
	return (
		<div  style={props.style}>
			<Stack 
				style={{ overflowX: "scroll", height: "100%" }}
				verticalAlign="space-between"
			>
				<Stack horizontal horizontalAlign="space-between">
					<Stack horizontal horizontalAlign="start">
						{(props.showSearch ?? true) &&
							typeof props.filter == "function" && (
								<TextField
									iconProps={{ iconName: "search" }}
									placeholder="Search..."
									value={search}
									onChange={(e, val) => setSearch(val ?? "")}
								></TextField>
							)}
					</Stack>

					{props.title && <h2>{props.title}</h2>}
					<Stack horizontal horizontalAlign="end">
						{(props.showPageSize ?? true) && (
							<TextField
								label="Page length: "
								underlined
								width={50}
								type="number"
								value={pageSize.toString()}
								onChange={(e, val) =>
									setPageSize(parseInt(val ?? "1"))
								}
							></TextField>
						)}
						{props.showExport != false && (
							<IconButton
								iconProps={{
									iconName: "Download",
								}}
								menuProps={{
									shouldFocusOnMount: true,
									items: [
										{
											key: "csv",
											text: "CSV",
											onClick: () => {
												var exportableColumns =
													columns.filter(
														(c) =>
															typeof c.export ==
																"function" ||
															c.fieldName
													);
												let csvContent =
													exportableColumns
														.map(
															(c, i) =>
																c.name ??
																c.fieldName ??
																c.key ??
																"Col" + i
														)
														.join(",");
												for (
													var i = 0;
													i < filteredItems.length;
													i++
												) {
													csvContent += "\n";
													csvContent +=
														exportableColumns.map(
															(c) => {
																if (
																	typeof c.export ==
																	"function"
																) {
																	return c.export(
																		filteredItems[
																			i
																		],
																		c
																	);
																} else {
																	let item = filteredItems[i]
																	let exportValue = item[c.fieldName]?.toString() ?? "--";
																	return exportValue;
																}
															}
														);
												}
												download(
													csvContent,
													(props.title ??
														"table_export") + ".csv"
												);
											},
										},
										{
											key: "json",
											text: "JSON",
											onClick: () => {
												var exportableColumns =
													columns.filter(
														(c) =>
															typeof c.export ==
																"function" ||
															c.fieldName
													);

												var exportObj =
													filteredItems.map((f) => {
														var row = {} as any;
														exportableColumns.forEach(
															(c, i) => {
																var key =
																	c.name ??
																	c.fieldName ??
																	c.key ??
																	"Col" + i;
																var value
																if (
																	typeof c.export ==
																	"function"
																) {
																	value = c.export(
																		f,
																		c
																	) ??
																	null;
																} else {
																	value = f[c.fieldName as keyof typeof f].toString() ?? "--";
																}
																row[key] = value
															}
														);
														return row;
													});
												download(
													JSON.stringify(
														exportObj,
														undefined,
														4
													),
													(props.title ??
														"table_export") +
														".json"
												);
											},
										},
									],
								}}
							></IconButton>
						)}
					</Stack>
				</Stack>
				<Stack.Item style={{ overflow: "auto", height:"100%",}} grow={1} >
					<table
						className="lm-pg-table"
						ref={ref}
						style={
							props.tableStyle ?? {
								maxHeight: "90vh",
								height:"100%",
								overflow: "scroll",
							}
						}
					>
						<thead>
							<tr>
								{(props.selectionMode ?? 0 ) > 0 && <th></th>}
								{columns.map((c) => {
									if (c.visible === false) {
										return <></>;
									}
									return (
										<th
											key={c.key ?? c.id ?? c.fieldName}
											onClick={(e) => {
												_sortColumn(undefined, c);
											}}
										>
											<Stack
												verticalAlign="center"
												horizontal
												horizontalAlign="start"
											>
												<div>{c.name}</div>
												{c.sort &&
													(c.isSorted ? (
														c.isSortedDescending ? (
															<IconButton
																iconProps={{
																	iconName:
																		"SortUp",
																}}
															></IconButton>
														) : (
															<IconButton
																iconProps={{
																	iconName:
																		"SortDown",
																}}
															></IconButton>
														)
													) : (
														<IconButton
															style={{
																color: "#aaa",
															}}
															iconProps={{
																iconName:
																	"Sort",
															}}
														></IconButton>
													))}
											</Stack>
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{visibleItems ? (
								visibleItems.map((i, visIndex) => {
									let index = filteredItems.indexOf(i);
									var bgColor;
									if (
										typeof props.rowBackgroundColor ==
										"function"
									) {
										bgColor = props.rowBackgroundColor(i);
									}
									return (
										<tr
											key={index}
											onClick={(e) => {
												if (
													typeof props.onActiveItemChanged ==
													"function"
												) {
													props.onActiveItemChanged(
														i,
														index,
														e  
													);
												}
												if (
													typeof props.onRowClick ==
													"function"
												) {
													props.onRowClick(
														e,
														i,
														index
													);
												}
											}}
											style={{
												background: bgColor,
												boxShadow:
													DefaultEffects.elevation8,
												borderTop: `1px solid ${theme.palette.neutralLight}`,
											}}
										>
											{props.selectionMode == SelectionMode.Single && (
												<td style={{ width: 25 }}>
													{(props.selectedKeys ?? []).indexOf(
														index
													) >= 0 ? (
														<span>
															{typeof props.onRenderSelected ==
															"function" ? (
																props.onRenderSelected()
															) : (
																<IconButton
																	iconProps={{
																		iconName:
																			"Accept",
																	}}
																></IconButton>
															)}
														</span>
													) : (
														<IconButton
															iconProps={{
																iconName:
																	"LocationCircle",
															}}
														></IconButton>
													)}
												</td>
											)}

											{columns.map((c, indexx) => {
												if (c.visible === false) {
													return <></>;
												}
												if (
													typeof c.onRenderComponent ==
													"function"
												) {
													return (
														<td
															key={indexx}
															style={c.cellStyle}
														>
															{c.onRenderComponent(
																i,
																c
															)}
														</td>
													);
												} else if (
													typeof c.onRender ==
													"function"
												) {
													return (
														<td
															key={indexx}
															style={c.cellStyle}
														>
															{c.onRender(
																i,
																index,
																c
															)}
														</td>
													);
												} else if (
													typeof c.onRenderContent ==
													"function"
												) {
													return (
														<td
															key={indexx}
															style={c.cellStyle}
															dangerouslySetInnerHTML={{
																__html: c.onRenderContent(
																	i,
																	c
																),
															}}
														></td>
													);
												} else {
													return (
														<td
															key={indexx}
															style={c.cellStyle}
														>
															{i?.[
																c.fieldName as keyof typeof i
															]?.toString()}
														</td>
													);
												}
											})}
										</tr>
									);
								})
							) : (
								<p>Oops</p>
							)}
						</tbody>
					</table>
				</Stack.Item>
				{maxPageNum > 0 && (
					<Stack>
						{(props.showSlider ?? true) && (
							<Slider
								max={maxPageNum}
								min={1}
								value={pageNum}
								valueFormat={(v) => {
									return (v + 1).toString();
								}}
								showValue
								// eslint-disable-next-line react/jsx-no-bind
								onChange={(v) => setPageNum(v)}
							/>
						)}

						<Stack
							horizontal
							verticalAlign={"center"}
							horizontalAlign={"space-between"}
						>
							<DefaultButton
								style={{
									margin: 2,
									color: theme.palette.themePrimary,
								}}
								onClick={() => {
									setPageNum(Math.max(pageNum - 1, 0));
								}}
							>
								PREV
							</DefaultButton>
							{pageLinks.map((el, i, arr) => {
								if (
									arr.length < 8 ||
									i == 0 ||
									i == arr.length - 1 ||
									(i > pageNum - 4 && i < pageNum + 4)
								) {
									return (
										<a
											key={el}
											style={{
												margin: 2,
												fontSize:
													el == pageNum
														? "2rem"
														: "1rem",
												color:
													el == pageNum
														? theme.palette.black
														: theme.palette
																.themePrimary,
												fontWeight:
													el == pageNum
														? "Bold"
														: "Regular",
											}}
											onClick={() => {
												setPageNum(el);
											}}
										>
											{el + 1}
										</a>
									);
								} else if (
									i == pageNum - 4 ||
									i == pageNum + 4
								) {
									return (
										<a
											key={i}
											style={{
												margin: 2,
												color: theme.palette
													.themePrimary,
											}}
										>
											...
										</a>
									);
								}
							})}
							<DefaultButton
								style={{
									margin: 5,
									color: theme.palette.themePrimary,
								}}
								onClick={() => {
									setPageNum(
										Math.min(pageNum + 1, maxPageNum)
									);
								}}
							>
								NEXT
							</DefaultButton>
						</Stack>
					</Stack>
				)}
			</Stack>
		</div>
	);
}
